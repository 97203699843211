import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import './App.css';
import { UserRole } from './app.enum';
import './assets/styles/styles.scss';
import AccountComponent from './components/account/account';
import AppInstallation from './components/app-installation/app-installation';
import AppInstallationHandler from './components/app-installation/app-installation-handler.component';
import ResetPassword from './components/auth/ResetPassword/ResetPassword';
import KeywordComponent from './components/keyword/keyword';
import DialogFlowSettings from './components/settings/dialog-flow-settings/DialogFlowSettings';
import WorkplaceSettings from './components/settings/workplace-settings/WorkplaceSettings';
import GroupPostComponent from './components/settings/group-post-triggers/group-post';
import Profile from './components/user/profile/profile';
import SignUp from './components/user/sign-up/sign-up';
import UserComponent from './components/user/user';
import GuardedRoute from './core/GuardedRoute';
import DashboardComponent from './dashboard/Dashboard';
import { IAppReduxState, IAppState } from './models';
import * as models from './models/index';
import Header from './shared/header/Header';
import Sidebar from './shared/sidebar/Sidebar';
import Login from './user/Login';

interface IUserListOwnProps {}

interface IUserListStateToProps {
    reduxState: models.IAppReduxState;
}

class AppComponent extends React.Component<any, IAppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            token: '',
            showMainHeader: true,
            isSidebarOpen: true,
        };
    }

    public render() {
        if (this.props.history.location.pathname === '/install-app') {
            return (
                <div className='app-content'>
                    <AppInstallationHandler />
                </div>
            );
        }

        let components: React.ReactNode;
        if (this.props.reduxStates.authentication?.isAuthenticated) {
            components = (
                <React.Fragment>
                    {this.state.isSidebarOpen ? (
                        <div className='sidebar-content'>
                            <Sidebar
                                open={this.state.isSidebarOpen}
                                window={window}
                                onClose={(res: boolean) => {
                                    this.setState({
                                        isSidebarOpen: res,
                                    });
                                }}
                            />
                        </div>
                    ) : null}

                    <div className='app-content light-bg-color'>
                        <div className='header-content'>
                            <Header
                                isSidebarOpen={this.state.isSidebarOpen!}
                                openSideBar={(res: boolean) => {
                                    this.setState({
                                        isSidebarOpen: true,
                                    });
                                }}
                            />
                        </div>
                        <div className='main-content'>
                            <Switch>
                                <Route path='/' exact component={DashboardComponent} />
                                <Route path='/dashboard'>
                                    <DashboardComponent />
                                </Route>
                                <Route path='/keywords'>
                                    <KeywordComponent />
                                </Route>
                                <GuardedRoute
                                    path='/accounts'
                                    allowedRoles={[UserRole.Super]}
                                    component={AccountComponent}></GuardedRoute>
                                <GuardedRoute
                                    path='/super-administrators'
                                    allowedRoles={[UserRole.Super]}
                                    component={UserComponent}></GuardedRoute>
                                <GuardedRoute
                                    path='/app-integration'
                                    allowedRoles={[UserRole.Admin]}
                                    component={AppInstallation}></GuardedRoute>

                                <GuardedRoute
                                    path='/workplace-settings'
                                    allowedRoles={[UserRole.Super]}
                                    component={WorkplaceSettings}
                                />
                                <GuardedRoute
                                    path='/dialogflow-settings'
                                    allowedRoles={[UserRole.Super]}
                                    component={DialogFlowSettings}
                                />
                                <Route path='/group-post-triggers'>
                                    <GroupPostComponent />
                                </Route>

                                <Route path='/users' component={UserComponent}></Route>
                                <Route path='/profile' component={Profile} />

                                <Route render={() => <Redirect to='/' />} />
                            </Switch>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            components = (
                <React.Fragment>
                    <div className='app-content'>
                        <Switch>
                            <Route path='/login'>
                                <Login
                                    setToken={(token: any) => {
                                        this.setState({
                                            token: token,
                                        });
                                    }}
                                    onUserSignIn={() => {}}
                                />
                            </Route>
                            <Route path='/sign-up'>
                                <SignUp />
                            </Route>
                            <Route path='/reset-password' component={ResetPassword} />
                            <Route render={() => <Redirect to='/login' />} />
                        </Switch>
                    </div>
                </React.Fragment>
            );
        }

        return <>{components}</>;
    }
}

const mapStateToProps: MapStateToProps<IUserListStateToProps, IUserListOwnProps, IAppReduxState> = (
    state: IAppReduxState,
    ownProps: IUserListOwnProps
): any => {
    return { reduxStates: state };
};

export default connect(mapStateToProps, null)(withRouter(AppComponent));
