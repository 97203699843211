import { Location } from 'history';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IModuleProviderContext {
    moduleName: string;
}

const ModuleProviderContext = createContext<IModuleProviderContext>({
    moduleName: '',
});

/**
 * Return current module name after parsing from path.
 * @returns default is 'dashboard'
 */
const getModuleName = (location: Location) => {
    const URLPathSegments = location.pathname.split('/'); // removes empty string values from array
    let moduleName = '';
    if (URLPathSegments && URLPathSegments.length > 1) {
        moduleName = URLPathSegments[1] === '' ? 'dashboard' : URLPathSegments[1];
    }
    return moduleName;
};

const ModuleProvider: React.FC<any> = (props) => {
    const location = useLocation();

    const [moduleName, setModuleName] = useState(() => getModuleName(location));

    useEffect(() => {
        const _moduleName = getModuleName(location);
        if (_moduleName !== moduleName) {
            setModuleName(_moduleName);
        }
    }, [location]);

    return <ModuleProviderContext.Provider value={{ moduleName }}>{props.children}</ModuleProviderContext.Provider>;
};

const useModule = () => useContext<IModuleProviderContext>(ModuleProviderContext);

export { ModuleProvider, ModuleProviderContext, useModule };
