import { ReflectiveInjector } from 'injection-js';
import { AccountService } from './account.service';
import { AppInstallationService } from './app-installation.service';
import { DashboardService } from './dashboard.service';
import { DialogFlowSettingsService } from './dialogflow-settings.service';
import { GroupPostService } from './group-post-filter.service';
import { IntlService } from './intl.service';
import { KeywordService } from './keyword.service';
import { MasterService } from './master.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { WorkplaceSettingsService } from './workplace-settings.service';

const injector = ReflectiveInjector.resolveAndCreate([
    IntlService,
    KeywordService,
    UserService,
    AccountService,
    MasterService,
    SharedService,
    DashboardService,
    AppInstallationService,
    DialogFlowSettingsService,
    WorkplaceSettingsService,
    GroupPostService,
]);

export default injector;
