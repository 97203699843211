import { Button, Chip, createStyles, Grid, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { MessageSource, SnackBarVariants } from '../../../../app.enum';
import { useSnackbar } from '../../../../providers/snackbar.provider';
import injector from '../../../../services';
import { IntlService } from '../../../../services/intl.service';
import { SharedService } from '../../../../services/shared.service';
import { WorkplaceSettingsService } from '../../../../services/workplace-settings.service';
import CustomButton from '../../../../shared/custom-button.component';

const intlService: IntlService = injector.get(IntlService);
const sharedService: SharedService = injector.get(SharedService);
const workplaceSettingsService: WorkplaceSettingsService = injector.get(WorkplaceSettingsService);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperRoot: {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: theme.spacing(1, 0),
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        expandField: {
            flexBasis: 'auto !important',
        },
    })
);

const validationSchema = yup.object({
    domain: yup
        .string()
        .matches(/((https):\/\/)(www.)?[^-][a-z0-9-]+[^-](\.[a-z]{2,}){1,3}(\/)?$/i, () =>
            intlService.get('app.validation.httpsDomains')
        ),
});

const maxAllowedDomains = 50;

const WorkplaceWhiteListedDomains: React.FC = () => {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [domains, setDomains] = useState<string[]>([]);

    const [initialDomains, setInitialDomains] = useState<string[]>([]);

    const formik = useFormik({
        initialValues: {
            domain: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values: { domain: string }) => {
            if (!!values.domain && handleChipAdd(values.domain)) {
                formik.setFieldValue('domain', '');
                await updateWhitelistedDomains([...domains, values.domain]);
            } else {
                await updateWhitelistedDomains(domains);
            }
        },
    });

    useEffect(() => {
        // active boolean is used to disallow state change in chage component is not mounted.
        let active = true;
        fetchWhitelistedDomains().then((data) => {
            if (active && data) {
                setDomains(data);
                setInitialDomains(data);
            }
        });
        return () => {
            active = false;
        };
    }, []);

    const fetchWhitelistedDomains = useCallback(async () => {
        try {
            const result: AxiosResponse<string[]> = await workplaceSettingsService.getWhitelistedDomains(
                MessageSource.Workplace
            );
            return result.data;
        } catch (err) {
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    }, []);

    const updateWhitelistedDomains = useCallback(
        async (updatedDomains: string[]) => {
            try {
                if (updatedDomains === initialDomains) return;
                const allAccountsSuccess = await workplaceSettingsService.updateWhitelistedDomains(updatedDomains, MessageSource.Workplace);
                setInitialDomains(updatedDomains);
                allAccountsSuccess.data ? snackbar.showSnackbar(
                    intlService.get('app.message.updatedWhitelistedDomainsSuccess'),
                    SnackBarVariants.Success
                ) : snackbar.showSnackbar(
                    intlService.get('app.message.updatedWhitelistedDomainsPartialSuccess'),
                    SnackBarVariants.Error
                );
            } catch (err) {
                const errorMessage = sharedService.parseError(err);
                snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
            }
        },
        [domains, initialDomains, setInitialDomains]
    );

    const handleChipAdd = (chip: any) => {
        const errorMessage = validateChip(chip);
        if (errorMessage) {
            formik.setFieldError('domain', errorMessage);
            return false;
        } else if (errorMessage === '') {
            setDomains((prev) => [...prev, chip]);
            formik.setFieldValue('domain', '');
        }

        return true;
    };

    const validateChip = (chip: any) => {
        if (domains.length === maxAllowedDomains) {
            return intlService.formatMessage({ id: 'app.validation.maxAllowedDomains' }, { maxAllowedDomains });
        } else if (
            domains.map((x) => SharedService.extractDomainName(x)).includes(SharedService.extractDomainName(chip))
        ) {
            return intlService.get('app.validation.duplicateDomainsNotAllowed');
        } else if (!((formik.values.domain.length || formik.submitCount) && !!formik.errors.domain)) {
            return '';
        }
    };

    const handleChipDelete = (index: number) => {
        const updatedDomains = domains.filter((v, i) => i !== index);
        if (domains.length === maxAllowedDomains) {
            formik.setFieldError('domain', undefined);
        }
        setDomains(updatedDomains);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !!formik.values.domain) {
            e.preventDefault();
            handleChipAdd(formik.values.domain);
            return false;
        }
    };

    const handleOnReset = () => {
        formik.setFieldValue('domain', '');
        setDomains(initialDomains);
    };

    return (
        <form
            autoComplete='off'
            style={{ width: '100%' }}
            onSubmit={formik.handleSubmit}
            data-testid='form'
            noValidate
            aria-label='form'>
            <Grid container spacing={3} direction='column' justify='center' alignItems='center'>
                <Grid container item xs={12} direction='row' spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            placeholder={intlService.get('app.label.addNewDomain')}
                            label={intlService.get('app.label.addNewDomain')}
                            value={formik.values.domain}
                            id='domain'
                            name='domain'
                            onChange={formik.handleChange}
                            variant='outlined'
                            FormHelperTextProps={{
                                id: `domain-text`,
                                error: true,
                            }}
                            onKeyPress={(e) => handleKeyDown(e)}
                            error={!!((formik.values.domain.length || formik.submitCount) && !!formik.errors.domain)}
                            helperText={formik.values.domain.length || formik.submitCount ? formik.errors.domain : null}
                        />
                        {domains.length ? (
                            <Paper component='ul' className={classes.paperRoot} elevation={2}>
                                {domains.map((data, i) => {
                                    return (
                                        <li key={data}>
                                            <Chip
                                                label={data}
                                                onDelete={() => handleChipDelete(i)}
                                                variant='outlined'
                                                color='primary'
                                                className={classes.chip}
                                            />
                                        </li>
                                    );
                                })}
                            </Paper>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container item direction='row' justify='flex-end' spacing={2}>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            type='button'
                            onClick={handleOnReset}
                            className='light-red-color'
                            disabled={formik.isSubmitting}>
                            {intlService.get('app.button.cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            disabled={formik.isSubmitting}
                            loading={formik.isSubmitting}
                            className='primary-color'>
                            {intlService.get('app.button.update')}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default WorkplaceWhiteListedDomains;
