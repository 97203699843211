import axios, { AxiosResponse, CancelToken } from 'axios';
import environment from '../environments/environment';
import { IGroupPostList, ListResult } from '../models';
import { UserService } from './user.service';

export class GroupPostService {
    chatbotUrl = environment.appSetting.chatbotUrl + 'groupPostTrigger/';

    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    async createFilterKeyword(keywordData: IGroupPostList, cancelToken?: CancelToken): Promise<AxiosResponse<IGroupPostList>> {
        return axios.post(this.chatbotUrl, JSON.stringify(keywordData), {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async updateFilterKeyword(id: string,keywordData: IGroupPostList, cancelToken?: CancelToken): Promise<AxiosResponse<IGroupPostList>> {
        const url = this.chatbotUrl + id;
        return axios.patch(url, JSON.stringify(keywordData), {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async deletefilterKeyword(id: string, cancelToken?: CancelToken): Promise<AxiosResponse<boolean>> {
        const keywordUrl = this.chatbotUrl + id;
        return axios.delete<boolean>(keywordUrl, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async getFilterKeywordList(
        queryParam: any,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<ListResult<IGroupPostList[]>>> {
        return axios.get<ListResult<IGroupPostList[]>>(this.chatbotUrl, {
            params: queryParam as any,
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    /**
     *  Update keyword Keyword( enabled/disabled)
     */
    public async updateTriggerKeywordStatus(
        id: string,
        bodyData: IGroupPostList,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<IGroupPostList>> {
        const url = this.chatbotUrl + 'changeStatus/' + id;
        return await axios.patch<IGroupPostList>(url, bodyData, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }
}
