import { UserService } from '../services/user.service';

export interface IAppReduxState {
    authentication?: IAppReduxAuthenticationState;
}

export interface IAppReduxAuthenticationState {
    isAuthenticated?: boolean;
    user?: any;
}

export const initialAppReduxState = (): IAppReduxState => {
    const userData = UserService.getUserData();
    return {
        authentication: {
            ...userData,
        },
    };
};

export interface IAppState {
    token?: string;
    isAuthenticated?: boolean;
    showMainHeader?: boolean;
    isSidebarOpen?: boolean;
}
