import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

interface IAccordions {
    summaryTitle: string;
    detailChildren: JSX.Element;
}

interface IAccordionsProps {
    accordians: IAccordions[];
}

export const Accordions: React.FC<IAccordionsProps> = (props) => {
    return (
        <Grid container direction='column' spacing={2}>
            {props.accordians.map((accordion, index) => (
                <Grid item key={accordion.summaryTitle} data-testid='accordion'>
                    <Accordion elevation={5} defaultExpanded={index === 0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h6'>
                                <span data-testid='accordionTitle'>{accordion.summaryTitle}</span>
                            </Typography>
                        </AccordionSummary>
                        <div data-testid='accordionDetailChildren'>
                            <AccordionDetails>{accordion.detailChildren}</AccordionDetails>
                        </div>
                    </Accordion>
                </Grid>
            ))}
        </Grid>
    );
};

export default Accordions;
