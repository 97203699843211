import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import environment from '../../../environments/environment';
import { useSnackbar } from '../../../providers/snackbar.provider';
import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import CustomButton from '../../../shared/custom-button.component';

const intlService: IntlService = injector.get(IntlService);
const userService: UserService = injector.get(UserService);
const sharedService: SharedService = injector.get(SharedService);

interface IForgotPasswordFormFields {
    email: string;
}

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .label(intlService.get('app.label.email'))
        .email()
        .required(intlService.get('app.validation.emailRequired')),
});

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold',
    },
}));

const ForgotPassword: React.FC<{ open?: boolean }> = (props) => {
    const axiosCancelTokenSource = axios.CancelToken.source();
    const snackbar = useSnackbar();
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values: IForgotPasswordFormFields) => {
            if (isRecaptchaTicked) {
                await sendResetPasswordLink(values);
            }
            setIsFormSubmitted(true);
        },
    });
    const [showComponent, setShowComponent] = useState(false);
    const recaptchaSiteKey = environment.recaptchaSiteKey;
    const [isRecaptchaTicked, setIsRecaptchaTicked] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const classes = useStyles();
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        if (params.get('forgotPassword') || props.open) {
            setShowComponent(true);
        } else {
            setShowComponent(false);
        }
    }, [history.location.search]);

    const handleCancel = () => {
        formik.resetForm();
        setIsRecaptchaTicked(false);
        setIsFormSubmitted(false);
        history.push('/login');
    };

    const sendResetPasswordLink = useCallback(async (values: IForgotPasswordFormFields) => {
        try {
            await userService.sendForgotPasswordEmail(values.email, axiosCancelTokenSource.token);
            history.push('/');
            snackbar.showSnackbar(intlService.get('app.message.forgotPasswordLinkSentStatus'));
        } catch (err) {
            if (!axios.isCancel(err)) {
                const errorMessage = sharedService.parseError(err);
                snackbar.showSnackbar(errorMessage, 'error');
            }
        }
    }, []);

    return (
        <Dialog fullWidth open={showComponent}>
            <DialogTitle>{intlService.get('app.component.forgotPassword')}</DialogTitle>
            <DialogContent>
                <form
                    id='forgotPassword'
                    onSubmit={formik.handleSubmit}
                    data-testid='form'
                    aria-label='form'
                    noValidate>
                    <Grid container direction='column' spacing={2}>
                        <Grid item xs={12}>
                            <Grid container direction='row' spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='email'
                                        label={intlService.get('app.label.email')}
                                        placeholder={intlService.get('app.label.email')}
                                        variant='outlined'
                                        type='email'
                                        fullWidth={true}
                                        required={true}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={
                                            !!(
                                                (formik.values.email.length || formik.submitCount) &&
                                                !!formik.errors.email
                                            )
                                        }
                                        helperText={
                                            formik.values.email.length || formik.submitCount
                                                ? formik.errors.email
                                                : null
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ReCAPTCHA
                                sitekey={recaptchaSiteKey}
                                onChange={(token) => token ? setIsRecaptchaTicked(true) : setIsRecaptchaTicked(false)}
                            />
                            {isFormSubmitted && !isRecaptchaTicked && (
                                <div className={classes.error}>{intlService.get('app.error.verifyRecaptcha')}</div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row' justify='flex-end' spacing={2}>
                                <Grid item>
                                    <Button onClick={handleCancel}>{intlService.get('app.button.cancel')}</Button>
                                </Grid>
                                <Grid item>
                                    <CustomButton loading={formik.isSubmitting} disabled={formik.isSubmitting}>
                                        {intlService.get('app.button.confirm')}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ForgotPassword;
