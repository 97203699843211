import axios, { AxiosResponse } from 'axios';
import environment from '../environments/environment';
import { IAccountList, IAddEditAccount, ListResult } from '../models';
import { UserService } from './user.service';

export class AccountService {
    baseUrl = environment.appSetting.baseUrl + 'account/';

    /**
     *  common function to set headers of request
     */
    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    /**
     *  Create account
     */
    async createAccount(accountData: any): Promise<AxiosResponse<IAccountList>> {
        return await axios.post(this.baseUrl, JSON.stringify(accountData), {
            headers: this.getHeaders(),
        });
    }

    /**
     *  Update account
     */
    public async updateAccount(id: string, account: IAddEditAccount): Promise<AxiosResponse<IAddEditAccount>> {
        const url = this.baseUrl + id;
        return await axios.patch<IAddEditAccount>(url, account, {
            headers: this.getHeaders(),
        });
    }

    /**
     *  Update account
     */
    async deleteAccount(id: string): Promise<AxiosResponse<boolean>> {
        const accountUrl = this.baseUrl + id;
        return await axios.delete<boolean>(accountUrl, {
            headers: this.getHeaders(),
        });
    }

    /**
     *  get list of  account based on filters
     */
    async getAccountList(queryParam: any): Promise<AxiosResponse<ListResult<IAccountList[]>>> {
        const accountUrl = this.baseUrl;
        return await axios.get<ListResult<IAccountList[]>>(accountUrl, {
            params: queryParam as any,
            headers: this.getHeaders(),
        });
    }

    /**
     *  get account by id
     */
    async getAccountById(id: string): Promise<AxiosResponse<IAccountList>> {
        const accountUrl = this.baseUrl + id;
        return await axios.get<IAccountList>(accountUrl, {
            params: {},
            headers: this.getHeaders(),
        });
    }

    /**
     *  Update account status( enabled/disabled)
     */
    public async updateAccountStatus(
        id: string,
        bodyData: Partial<IAccountList>
    ): Promise<AxiosResponse<IAccountList>> {
        const url = this.baseUrl + 'changeStatus/' + id;
        return await axios.patch<IAccountList>(url, bodyData, {
            headers: this.getHeaders(),
        });
    }

    /**
     *  Update group post status( enabled/disabled)
     */
    public async updateGroupPostStatus(
        id: string,
        bodyData: Partial<IAccountList>
    ): Promise<AxiosResponse<IAccountList>> {
        const url = this.baseUrl + 'changeGroupPostStatus/' + id;
        return await axios.patch<IAccountList>(url, bodyData, {
            headers: this.getHeaders(),
        });
    }
}
