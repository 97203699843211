import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import Accordions from '../../../shared/accordions/Accordions';
import DialogFlowChangeAgents from './change-agents/DialogFlowChangeAgents';

const intlService: IntlService = injector.get(IntlService);

const DialogFlowSettings: React.FC = () => {
    return (
        <Accordions
            accordians={[
                {
                    summaryTitle: intlService.get('app.component.change-agents'),
                    detailChildren: <DialogFlowChangeAgents />,
                },
            ]}
        />
    );
};

export default DialogFlowSettings;
