import {
    Collapse,
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { ReactElement, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useModule } from '../../providers/module.provider';
import { UserService } from '../../services/user.service';

interface INestedListItemProps {
    item: INestedListItem;
}

interface INestedListItem {
    icon: ReactElement;
    name: string;
    moduleName?: string;
    childItems?: INestedListItem[];
    visibleFor?: number;
    onClick?: (event: any) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4),
        },
    })
);

const NestedListItem: React.FC<INestedListItemProps> = (props) => {
    const { item } = props;
    const classes = useStyles();
    const history = useHistory();
    const module = useModule();
    const [open, setOpen] = useState<boolean>(false);

    const toggle = () => {
        setOpen((prev) => !prev);
    };

    const authData = UserService.getUserData();

    const isOpenOrSelected = open || item.childItems?.map((x) => x.moduleName).includes(module.moduleName);

    return (
        <>
            <ListItem button onClick={toggle}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {isOpenOrSelected ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {item.childItems && (
                <Collapse in={isOpenOrSelected} timeout='auto' unmountOnExit>
                    {item.childItems.map((childItem) => (
                        <>
                            {authData.role === childItem.visibleFor || childItem.visibleFor === undefined ? (
                                <List key={childItem.name} component='div' disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        component={childItem.moduleName ? RouterLink : 'div'}
                                        to={childItem.moduleName ?? `/${childItem.moduleName}`}
                                        selected={childItem.moduleName ? module.moduleName === childItem.moduleName : false}
                                        onClick={(e: any) => {
                                            childItem.onClick && childItem.onClick(e);
                                            childItem.moduleName && history.push(childItem.moduleName);
                                        }}>
                                        <ListItemIcon>{childItem.icon}</ListItemIcon>
                                        <ListItemText primary={childItem.name} />
                                    </ListItem>
                                </List>
                            ) : null}
                        </>
                    ))}
                </Collapse>
            )}
        </>
    );
};

export default NestedListItem;
