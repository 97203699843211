import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Injector from '../services/index';
import { UserService } from '../services/user.service';

function GuardedRoute(props: any): React.ReactElement {
    const { component: Component, allowedRoles, ...rest } = props;
    const userService: UserService = Injector.get(UserService);
    const roleId = UserService.getUserData().role;

    const render = (props: any) => {
        if (roleId && allowedRoles && allowedRoles.indexOf(roleId) !== -1) {
            return <Component {...props} {...rest} />;
        } else {
            return <Redirect to='dashboard' />;
        }
    };

    return <Route {...rest} render={render} />;
}

export default GuardedRoute;
