import axios, { AxiosResponse, CancelToken } from 'axios';
import { UserLoginRequestDto, UserLoginResponseDto } from '../app.interface';
import { IResetPasswordFormFields } from '../components/auth/ResetPassword/ResetPassword';
import environment from '../environments/environment';
import * as models from '../models/index';
import { ListResult } from '../models/index';
import { ChangePasswordRequest, IUser, IUserSignUpRequest, UserQueryParam } from './../models/user.models';
const baseUrl = environment.appSetting.baseUrl;

export class UserService {
    public loginUser(credentials: UserLoginRequestDto): Promise<AxiosResponse<UserLoginResponseDto>> {
        return axios.post(baseUrl + 'user/login', JSON.stringify(credentials), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public signUp(credentials: IUserSignUpRequest): Promise<AxiosResponse<UserLoginResponseDto>> {
        return axios.post(baseUrl + 'user/signUp', JSON.stringify(credentials), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public static logoutUser() {
        localStorage.removeItem('token');
        localStorage.removeItem('UserData');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('UserData');
    }

    /**
     * To change user password
     * @param changePasswordRequest contains current and new password
     * @returns status 200 with true in response.
     */
    public changeUserPassword(changePasswordRequest: ChangePasswordRequest): Promise<AxiosResponse<boolean>> {
        return axios.patch(baseUrl + 'user/changePassword', changePasswordRequest, {
            headers: this.getHeaders(),
        });
    }

    public sendForgotPasswordEmail(email: string, cancelToken?: CancelToken) {
        return axios.post(baseUrl + 'user/forgotPassword', { email }, { cancelToken });
    }

    public resetPassword(resetPasswordRequest: IResetPasswordFormFields, cancelToken?: CancelToken) {
        return axios.post(baseUrl + 'user/resetPassword', resetPasswordRequest, { cancelToken });
    }

    /**
     * Set user data in localStorage/sessionStorage based on isRememberMe variable
     * @param userData Userdata to be stored in local storage/session storage
     * @param isRememberMe if enabled data will be stored in localStorage else in sessionStorage
     */
    public static setUserData(userData: models.IUserSignInResponse, isRememberMe: boolean = false) {
        if (isRememberMe) {
            localStorage.setItem('UserData', JSON.stringify(userData));
        } else {
            sessionStorage.setItem('UserData', JSON.stringify(userData));
        }
    }

    /**
     * Get user data from storage
     */
    public static getUserData(): models.IUserSignInResponse {
        const data = localStorage.getItem('UserData') || sessionStorage.getItem('UserData') || '{}';
        return JSON.parse(data);
    }

    /**
     *  common function to set headers of request
     */
    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    /**
     * used to fetch user list based on filters passed
     * @param queryParam Query param/filters
     * @returns User List records and count
     */
    async getUserList(
        queryParam: UserQueryParam,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<ListResult<models.IUser[]>>> {
        const accountUrl = baseUrl + 'user';
        return axios.get<ListResult<models.IUser[]>>(accountUrl, {
            params: queryParam as any,
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    /**
     *  Update user status( muted/unmuted)
     */
    public async updateUserStatus(
        id: string,
        bodyData: IUser,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<IUser>> {
        const url = baseUrl + 'user/changeStatus/' + id;
        return await axios.patch<IUser>(url, bodyData, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async sendInvitaion(formValues: models.IUserFormFields, cancelToken?: CancelToken) {
        const url = `${baseUrl}user/inviteUser`;
        return await axios.post<boolean>(url, formValues, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }
}
