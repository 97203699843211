import { Chip, ChipProps, Grid, ListItemText } from '@material-ui/core';
import React from 'react';

interface SelectTextWithChipProps {
    value: string;
    chips: ChipProps[];
}

const SelectOptionWithChip: React.FC<SelectTextWithChipProps> = (props) => {
    const { value, chips } = props;
    return (
        <Grid container justify='space-between'>
            <Grid item>
                <ListItemText primary={value} />
            </Grid>
            <Grid item>
                {chips.map((chip, i) => (
                    <Chip key={i} style={{ marginLeft: 2 }} {...chip} />
                ))}
            </Grid>
        </Grid>
    );
};

export default SelectOptionWithChip;
