import {
    AppBar,
    createStyles,
    Dialog,
    DialogContent,
    IconButton,
    makeStyles,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import React from 'react';
import injector from '../../services';
import { IntlService } from '../../services/intl.service';

interface IFullScreenDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
}

const intlService: IntlService = injector.get(IntlService);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        content: {
            padding: '2vh',
        },
    })
);

const FullScreenDialog: React.FC<IFullScreenDialogProps> = (props) => {
    const classes = useStyles();

    return (
        <Dialog fullScreen open={props.open} onClose={(e) => props.onClose()} aria-labelledby='fullscreen-dialog-title'>
            <AppBar position='relative' className='primary-color'>
                <Toolbar>
                    <Tooltip title={intlService.get('app.button.back')}>
                        <IconButton
                            onClick={async () => {
                                props.onClose();
                            }}
                            edge='start'
                            color='inherit'
                            aria-label={intlService.get('app.button.back')}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography className={classes.title} variant='h6' noWrap>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.content}>{props.children}</DialogContent>
        </Dialog>
    );
};

export default FullScreenDialog;
