import React, { useState } from 'react';
import { SnackBarVariants } from '../app.enum';
import CustomSnackBar from './snackbar.component';

const withSnackBar = (
    WrappedComponent: React.ComponentType | React.FunctionComponent | React.ForwardRefExoticComponent<any>
) => {
    return React.forwardRef((props: any, ref: any) => {
        const [open, setOpen] = useState<boolean>(false);
        const [messages, setMessages] = useState<string>('');
        const [variant, setVariant] = useState<SnackBarVariants>(SnackBarVariants.Success);

        // Close Snackbar
        const closeSnackBar = () => {
            setOpen(false);
            setMessages('');
        };

        // Open snackbar
        const openSnackbar = (message: string, snackBarVariant: SnackBarVariants = SnackBarVariants.Success) => {
            setOpen(true);
            setMessages(message);
            setVariant(snackBarVariant);
        };

        return (
            <>
                <CustomSnackBar
                    open={open}
                    snackBarMessage={messages}
                    autoHideDuration={8000}
                    variant={variant}
                    onClose={closeSnackBar}
                    onIconClick={closeSnackBar}
                />
                <WrappedComponent {...props} ref={ref} openSnackBar={openSnackbar} />
            </>
        );
    });
};

export default withSnackBar;
