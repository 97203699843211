import { Avatar, Container, CssBaseline, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { SnackBarVariants } from '../../../app.enum';
import { useSnackbar } from '../../../providers/snackbar.provider';
import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import CustomButton from '../../../shared/custom-button.component';
import PasswordFormField from '../../../shared/PasswordFormField/PasswordFormField';
const queryString = require('query-string');

const intlService: IntlService = injector.get(IntlService);

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold',
    },
}));

interface ISignUpPasswordProps {}

interface ISignUpFields {
    firstName: string;
    middleName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

const initialFormValues: ISignUpFields = {
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
};

const validationSchema = Yup.object({
    firstName: Yup.string().required(intlService.get('app.validation.firstNameRequired')),
    middleName: Yup.string(),
    lastName: Yup.string().required(intlService.get('app.validation.lastNameRequired')),
    password: Yup.string().required(intlService.get('app.validation.passwordRequired')),
    confirmPassword: Yup.string()
        .required(intlService.get('app.validation.confirmPasswordRequired'))
        .oneOf([Yup.ref('password'), null], intlService.get('app.validation.confirmPasswordShouldMatchPassword')),
});

const userService: UserService = injector.get(UserService);
const sharedService: SharedService = injector.get(SharedService);

const SignUp: React.FC<ISignUpPasswordProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();

    const formik = useFormik<ISignUpFields>({
        initialValues: initialFormValues,
        onSubmit: async (values) => {
            await handleOnSubmit(values);
        },
        validationSchema,
    });

    const handleOnSubmit = async (values: ISignUpFields) => {
        await signUp(values);
    };

    const hasError = (fieldName: string) => {
        const { value, initialValue, touched, error } = formik.getFieldMeta(fieldName);
        return (error && value !== initialValue) || touched || formik.isSubmitting ? true : false;
    };

    const queryParamsFromUrl = history?.location?.search ? queryString.parse(history.location.search) : {};
    const signUp = useCallback(async (values: ISignUpFields) => {
        try {
            await userService.signUp({
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                password: values.password,
                email: queryParamsFromUrl.email,
                emailSecurityCode: queryParamsFromUrl.uuid,
            });

            formik.resetForm();
            snackbar.showSnackbar(intlService.get('app.message.signupSuccess'), SnackBarVariants.Success);
            history.push('/');
        } catch (err) {
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    }, []);

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component='h1' variant='h5' className='pb-10'>
                    {intlService.get('app.label.signUp')}
                </Typography>
                <form
                    autoComplete='off'
                    style={{ width: '100%' }}
                    onSubmit={formik.handleSubmit}
                    data-testid='form'
                    noValidate>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='firstName'
                                        label={intlService.get('app.label.firstName')}
                                        placeholder={intlService.get('app.label.firstName')}
                                        variant='outlined'
                                        type='text'
                                        fullWidth={true}
                                        required={true}
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        error={
                                            !!(
                                                (formik.values.firstName.length || formik.submitCount) &&
                                                !!formik.errors.firstName
                                            )
                                        }
                                        helperText={
                                            formik.values.firstName.length || formik.submitCount
                                                ? formik.errors.firstName
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='middleName'
                                        label={intlService.get('app.label.middleName')}
                                        placeholder={intlService.get('app.label.middleName')}
                                        variant='outlined'
                                        type='text'
                                        fullWidth={true}
                                        required={false}
                                        value={formik.values.middleName}
                                        onChange={formik.handleChange}
                                        error={
                                            !!(
                                                (formik.values.middleName.length || formik.submitCount) &&
                                                !!formik.errors.middleName
                                            )
                                        }
                                        helperText={
                                            formik.values.middleName.length || formik.submitCount
                                                ? formik.errors.middleName
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='lastName'
                                        label={intlService.get('app.label.lastName')}
                                        placeholder={intlService.get('app.label.lastName')}
                                        variant='outlined'
                                        type='text'
                                        fullWidth={true}
                                        required={true}
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        error={
                                            !!(
                                                (formik.values.lastName.length || formik.submitCount) &&
                                                !!formik.errors.lastName
                                            )
                                        }
                                        helperText={
                                            formik.values.lastName.length || formik.submitCount
                                                ? formik.errors.lastName
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        id='password'
                                        label={intlService.get('app.label.password')}
                                        placeholder={intlService.get('app.label.password')}
                                        value={formik.values.password}
                                        handleChange={formik.handleChange}
                                        hasError={hasError('password')}
                                        errorMessage={formik.errors?.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        id='confirmPassword'
                                        label={intlService.get('app.label.confirmPassword')}
                                        placeholder={intlService.get('app.label.confirmPassword')}
                                        value={formik.values.confirmPassword}
                                        handleChange={formik.handleChange}
                                        hasError={hasError('confirmPassword')}
                                        errorMessage={formik.errors?.confirmPassword}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomButton
                                        fullWidth={true}
                                        data-testid='signUpBtn'
                                        disabled={formik.isSubmitting}
                                        loading={formik.isSubmitting}
                                        className='primary-color'>
                                        {intlService.get('app.button.signUp')}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default SignUp;
