const local = {
    appSetting: {
        baseUrl: 'http://localhost:3100/',
        chatbotUrl: 'http://localhost:3200/',
    },
    installApp: {
        appId: '1576209382576842',
    },
    isPortalInitiatedAppInstall: true,
    accountSettings: {
        groupPostWaitWindow: 5, // value -number - It is the wait time in minutes, which paven have to wait to send next post to dialogflow
    },
    workplace: {
        workAPIBaseUrl: 'https://work.workplace.com/',
        retryAppInstallAfterEveryXSeconds: 5,
        maximumRetriesInAppInstall: 24,
    },
    //recaptchaSiteKey: '6LfRpvscAAAAAJqo29vve5QIfc7FailMNmVu1NOt', // Use if you want to use genuine captcha
    recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // test site key for testing purpose/mock captcha,
    tableauReportUrl: 'https://10ay.online.tableau.com/#/site/paven30dashboard/workbooks/969574/views'
};

const dev = {
    appSetting: {
        baseUrl: 'https://wpi-auth-api-dev.paven.io/',
        chatbotUrl: 'https://wpi-chat-api-dev.paven.io/',
    },
    installApp: {
        appId: '1576209382576842',
    },
    isPortalInitiatedAppInstall: true,
    accountSettings: {
        groupPostWaitWindow: 5, // value -number - It is the wait time in minutes, which paven have to wait to send next post to dialogflow
    },
    workplace: {
        workAPIBaseUrl: 'https://work.workplace.com/',
        retryAppInstallAfterEveryXSeconds: 5,
        maximumRetriesInAppInstall: 24,
    },
    recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // test site key for testing purpose/mock captcha,
    tableauReportUrl: 'https://10ay.online.tableau.com/#/site/paven30dashboard/workbooks/969574/views'
};

const staging = {
    appSetting: {
        baseUrl: 'https://wpi-auth-api-staging.paven.io/',
        chatbotUrl: 'https://wpi-chat-api-staging.paven.io/',
    },
    installApp: {
        appId: '1576209382576842',
    },
    isPortalInitiatedAppInstall: true,
    accountSettings: {
        groupPostWaitWindow: 5, // value -number - It is the wait time in minutes, which paven have to wait to send next post to dialogflow
    },
    workplace: {
        workAPIBaseUrl: 'https://work.workplace.com/',
        retryAppInstallAfterEveryXSeconds: 5,
        maximumRetriesInAppInstall: 24,
    },
    recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // test site key for testing purpose/mock captcha,
    tableauReportUrl: 'https://10ay.online.tableau.com/#/site/paven30dashboard/workbooks/974716/views'
};

const preprod = {
    appSetting: {
        baseUrl: 'https://wpi-auth-api-pp.paven.io/',
        chatbotUrl: 'https://wpi-chat-api-pp.paven.io/',
    },
    installApp: {
        appId: '1576209382576842',
    },
    isPortalInitiatedAppInstall: true,
    accountSettings: {
        groupPostWaitWindow: 5, // value -number - It is the wait time in minutes, which paven have to wait to send next post to dialogflow
    },
    workplace: {
        workAPIBaseUrl: 'https://work.workplace.com/',
        retryAppInstallAfterEveryXSeconds: 5,
        maximumRetriesInAppInstall: 24,
    },
    recaptchaSiteKey: '6LcmuvscAAAAAOi2oEM4uL3aKFQqCCXY8zRsLCGy',
    tableauReportUrl: 'https://10ay.online.tableau.com/#/site/paven30dashboard/workbooks/1013877/views'
};

const prod = {
    appSetting: {
        baseUrl: 'https://wpi-auth-api.paven.io/',
        chatbotUrl: 'https://wpi-chat-api.paven.io/',
    },
    installApp: {
        appId: '1576209382576842',
    },
    isPortalInitiatedAppInstall: true,
    accountSettings: {
        groupPostWaitWindow: 5, // value -number - It is the wait time in minutes, which paven have to wait to send next post to dialogflow
    },
    workplace: {
        workAPIBaseUrl: 'https://work.workplace.com/',
        retryAppInstallAfterEveryXSeconds: 5,
        maximumRetriesInAppInstall: 24,
    },
    recaptchaSiteKey: '6Lf9uvscAAAAAKuWzSClmBF55dlYHPPSqCQ0wLml',
    tableauReportUrl: 'https://10ay.online.tableau.com/#/site/paven30dashboard/workbooks/1024882/views'
};

// Define common settings here
const common = {
    // config values specific to UI
    uiSettings: {
        // Default value to show on UI when actual value is not available
        defaultValue: 'NA',
        // Pagination settings
        pagination: {
            rowsPerPageArray: [5, 10, 20, 50],
            defaultIndexOfRowsPerPageArray: 2,
        },
        // Long date format
        dateFormat: 'MMMM d, y',
        // Duration for which to show snackbar
        snackBarDurationInSec: 3500,
    },
};

let config = local;

switch (process.env.REACT_APP_ENV) {
    case 'dev':
        config = dev;
        break;
    case 'staging':
        config = staging;
        break;
    case 'preprod':
        config = preprod;
        break;
    case 'prod':
        config = prod;
        break;
}

export default {
    ...common,
    ...config,
};
