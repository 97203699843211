import axios, { AxiosResponse, CancelToken } from 'axios';
import environment from '../environments/environment';
import { IKeywordList, ListResult } from '../models';
import { UserService } from './user.service';

export class KeywordService {
    chatbotUrl = environment.appSetting.chatbotUrl + 'keyword/';

    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    async createKeyword(keywordData: IKeywordList, cancelToken?: CancelToken): Promise<AxiosResponse<IKeywordList>> {
        return axios.post(this.chatbotUrl, JSON.stringify(keywordData), {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async deleteKeyword(id: string, cancelToken?: CancelToken): Promise<AxiosResponse<boolean>> {
        const keywordUrl = this.chatbotUrl + id;
        return axios.delete<boolean>(keywordUrl, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    async getKeywordList(
        queryParam: any,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<ListResult<IKeywordList[]>>> {
        return axios.get<ListResult<IKeywordList[]>>(this.chatbotUrl, {
            params: queryParam as any,
            headers: this.getHeaders(),
            cancelToken,
        });
    }

    /**
     *  Update keyword Keyword( enabled/disabled)
     */
    public async updateKeywordStatus(
        id: string,
        bodyData: IKeywordList,
        cancelToken?: CancelToken
    ): Promise<AxiosResponse<IKeywordList>> {
        const url = this.chatbotUrl + 'changeStatus/' + id;
        return await axios.patch<IKeywordList>(url, bodyData, {
            headers: this.getHeaders(),
            cancelToken,
        });
    }
}
