import { createStyles, fade, InputBase, makeStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useModule } from '../../providers/module.provider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '23ch',
            },
        },
    })
);

export const searchQueryParamName = 'searchQuery';

const getPlaceholder = (moduleName: string) => {
    if(moduleName==='group-post-triggers'){
        moduleName='groupPost'
    }
    const placeholders: { [key: string]: string[] } = {
        accounts: ['name'],
        'super-administrators': ['name', 'email'],
        users: ['name', 'email'],
        keywords: ['keyword'],
        groupPost: ['Trigger Keywords'],
    };

    return placeholders[moduleName] ? placeholders[moduleName].join(', ') : '';
};

const SearchInput: React.FC = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const module = useModule();
    const [searchString, setSearchString] = React.useState<string>('');

    const debounceQuery = useCallback(
        _.debounce((value: string) => {
            if (searchString.toLowerCase() !== value.toLowerCase() || searchString === '' || value === '') {
                addToQueryParams(value.toLowerCase());
            }
        }, 500),
        []
    );

    const addToQueryParams = useCallback(
        (value: string) => {
            const params = new URLSearchParams(history.location.search);

            if (value) {
                params.set(searchQueryParamName, value);
            } else {
                params.delete(searchQueryParamName);
            }

            history.push({ search: params.toString() });
        },
        [history]
    );

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSearchString(event.target.value.toLowerCase());
        debounceQuery(event.target.value);
    };

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const result = params.get(searchQueryParamName);
        if (!result) {
            setSearchString('');
        }
    }, [location]);

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={'Search by ' + getPlaceholder(module.moduleName)}
                value={searchString}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={(e) => handleSearchInputChange(e)}
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    );
};
// removeSearchQuery function will remove the SearchQuery Param from Url
export const removeSearchQuery=(props:RouteComponentProps)=>{
    const History=props.history;
    const Location=props.location.search;
    const queryParam=new URLSearchParams(Location);
    queryParam.delete(searchQueryParamName);
    History.replace({search:queryParam.toString()})
}

export const getSearchQuery = (
    prevProps: RouteComponentProps,
    props: RouteComponentProps
): {
    searchQuery: string;
    prevSearchQuery: string;
    isSearchQueryChanged: boolean;
} => {
    const { search: prevSearch } = prevProps.location;
    const { search } = props.location;

    const prevQueryParams = new URLSearchParams(prevSearch);
    const queryParams = new URLSearchParams(search);

    const prevSearchQuery = prevQueryParams.get(searchQueryParamName) || '';
    const searchQuery = queryParams.get(searchQueryParamName) || '';
    const isSearchQueryChanged = searchQuery !== prevSearchQuery;
    return { searchQuery, prevSearchQuery, isSearchQueryChanged };
};

export default SearchInput;
