import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './app.store';
import theme from './app.theme';
import './index.css';
import { ModuleProvider } from './providers/module.provider';
import { SnackbarProvider } from './providers/snackbar.provider';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename='/'>
            <Provider store={store}>
                <div className='root'>
                    <ThemeProvider theme={theme}>
                        <ModuleProvider>
                            <SnackbarProvider>
                                <App />
                            </SnackbarProvider>
                        </ModuleProvider>
                    </ThemeProvider>
                </div>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
