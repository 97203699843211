import React from 'react';
import { useHistory } from 'react-router';
import { UserRole } from '../../../app.enum';
import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import { UserService } from '../../../services/user.service';
import Accordions from '../../../shared/accordions/Accordions';
import FullScreenDialog from '../../../shared/full-screen-dialog/full-screen-dialog';
import ChangePassword from './change-password/change-password';
import InfoComponent from './info/info';

const intlService: IntlService = injector.get(IntlService);

const Profile: React.FC = () => {
    const history = useHistory();

    const handleClose = () => {
        history.goBack();
    };

    const accordians = [
        {
            summaryTitle: intlService.get('app.message.changePassword'),
            detailChildren: <ChangePassword />,
        },
    ];

    if (UserService.getUserData()?.role !== UserRole.Super) {
        accordians.push({
            summaryTitle: intlService.get('app.accordion.title.info'),
            detailChildren: <InfoComponent />,
        });
    }

    return (
        <FullScreenDialog open={true} title={intlService.get('app.component.profile')} onClose={handleClose}>
            <div data-testid='profileAccordion'>
                <Accordions accordians={accordians} />
            </div>
        </FullScreenDialog>
    );
};

export default Profile;
