import React from 'react';
import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import Accordions from '../../../shared/accordions/Accordions';
import WorkplaceWhiteListedDomains from './whitelisted-domains/WorkplaceWhiteListedDomains';

const intlService: IntlService = injector.get(IntlService);

const WorkplaceSettings: React.FC = () => {
    return (
        <Accordions
            accordians={[
                {
                    summaryTitle: intlService.get('app.component.whitelisted-domains'),
                    detailChildren: <WorkplaceWhiteListedDomains />,
                },
            ]}
        />
    );
};

export default WorkplaceSettings;
