import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import injector from '../../services';
import { IntlService } from '../../services/intl.service';

const intlService: IntlService = injector.get(IntlService);

const PasswordFormField: React.FC<{
    id: string;
    label: string;
    placeholder: string;
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    hasError: boolean;
    errorMessage: string | undefined;
}> = (props) => {
    const { id, placeholder, value, hasError, handleChange, errorMessage, label } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <TextField
                id={id}
                placeholder={placeholder}
                label={label}
                variant='outlined'
                value={value}
                // autoComplete="new-password" is used to prevent auto-filling
                autoComplete="new-password"
                onChange={handleChange}
                aria-describedby={`${id}-text`}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    classes: {
                        notchedOutline: 'input-notched-outline',
                    },
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                tabIndex={-1}
                                aria-label={intlService.get('app.ariaLabel.togglePasswordVisibility')}
                                onClick={() => setShowPassword((prevState) => !prevState)}
                                onMouseDown={(e) => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                FormHelperTextProps={{
                    id: `${id}-text`,
                    error: true,
                }}
                error={hasError && !!errorMessage}
                helperText={hasError && errorMessage}
                required
                fullWidth
            />
        </>
    );
};

export default PasswordFormField;
