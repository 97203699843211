import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import React from 'react';

export interface IAsyncAutoCompleteFieldItem {
    name: string;
    value: string;
}

interface IAsyncAutoCompleteFieldProps {
    id: string;
    label: string;
    name: string;
    value: IAsyncAutoCompleteFieldItem | null;
    disabled?: boolean;
    initialData?: IAsyncAutoCompleteFieldItem[];
    errorMessage?: string;
    showError?: boolean;
    onValueChange: (value: string) => void;
    fetchMethod: (request: { input: string }) => Promise<IAsyncAutoCompleteFieldItem[]>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItemRoot: {
            padding: theme.spacing(1),
        },
    })
);

const AsyncAutoCompleteField: React.FC<IAsyncAutoCompleteFieldProps> = (props) => {
    const { id, label, name, disabled, initialData, onValueChange, fetchMethod } = props;

    const classes = useStyles();
    const [value, setValue] = React.useState<IAsyncAutoCompleteFieldItem | null>(props.value);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<IAsyncAutoCompleteFieldItem[]>(initialData ?? []);

    const fetch = React.useMemo(
        () =>
            throttle(
                async (request: { input: string }, callback: (results?: IAsyncAutoCompleteFieldItem[]) => void) => {
                    const results = await fetchMethod(request);
                    callback(results);
                },
                200
            ),
        []
    );

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(initialData ?? []);
            return undefined;
        }

        if (value?.name === inputValue) {
            return undefined;
        }

        fetch({ input: inputValue }, (results?: IAsyncAutoCompleteFieldItem[]) => {
            if (active) {
                let newOptions = [] as IAsyncAutoCompleteFieldItem[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handleValueChange = (event: React.ChangeEvent<{}>, value: IAsyncAutoCompleteFieldItem | null) => {
        const item = value as IAsyncAutoCompleteFieldItem;
        setOptions(item ? [item, ...options] : initialData ?? []);
        setValue(item);
        onValueChange(item && item.value ? item.value : '');
    };

    return (
        <Autocomplete
            id={id}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            disabled={disabled}
            getOptionSelected={(o, v) => o.value == v.value}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={handleValueChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    disabled={disabled}
                    label={label}
                    placeholder={label}
                    variant='outlined'
                    fullWidth
                    required
                    error={props.showError}
                    helperText={props.showError && props.errorMessage}
                />
            )}
            renderOption={(option) => (
                <Grid container alignItems='center' className={classes.menuItemRoot}>
                    <Typography variant='body2'>{option.name}</Typography>
                </Grid>
            )}
        />
    );
};

export default AsyncAutoCompleteField;
