import * as actions from '../actions/index';
import * as constants from '../constants/index';
import * as models from '../models/index';

export function authReducer(
    state: models.IAppReduxAuthenticationState = {},
    action: actions.AuthActions
): models.IAppReduxAuthenticationState {
    switch (action.type) {
        case constants.USER_SIGNED_IN:
            return {
                user: action.payload.user,
                isAuthenticated: true,
            };
        case constants.USER_SIGNED_OUT:
            return {
                isAuthenticated: false,
            };

        default:
            return state;
    }
}
