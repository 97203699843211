import { applyMiddleware, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import * as models from './models';
import rootReducer from './reducers';

const initialState = models.initialAppReduxState();

const middleware = [thunk];

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

export default store as Store<models.IAppReduxState>;
