import { Button, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { UserRole } from '../../app.enum';
import { IKeywordFormField } from '../../models';
import injector from '../../services';
import { AccountService } from '../../services/account.service';
import { IntlService } from '../../services/intl.service';
import { UserService } from '../../services/user.service';
import AsyncAutoCompleteField, {
    IAsyncAutoCompleteFieldItem,
} from '../../shared/async-auto-complete-field/AsyncAutoCompleteField';
import CustomButton from '../../shared/custom-button.component';
import FullScreenDialog from '../../shared/full-screen-dialog/full-screen-dialog';

const intlService: IntlService = injector.get(IntlService);
const accountService: AccountService = injector.get(AccountService);
interface IKeywordsFormProps {
    open: boolean;
    onSubmit: (formValues: IKeywordFormField) => void;
    onClose: () => void;
    prefilledFormValues?: IKeywordFormField;
}

export const initialFormValues: IKeywordFormField = {
    name: '',
    accountId: '',
};

export const formValidationSchema = Yup.object().shape<any>({
    name: Yup.string().required(intlService.get('app.validation.keywordRequired')),
});

const KeywordForm: React.FC<IKeywordsFormProps> = (props) => {
    const currentUserData = UserService.getUserData();
    //TODO: Remove undefined by making role required field.
    const [isCurrentUserAdmin] = useState(![UserRole.Super, UserRole.User, undefined].includes(currentUserData?.role));
    const [selectedAccount, setSelectedAccount] = useState<IAsyncAutoCompleteFieldItem | null>(null);
    const [accounts, setAccounts] = useState<IAsyncAutoCompleteFieldItem[]>();

    const formik = useFormik<IKeywordFormField>({
        initialValues: props.prefilledFormValues ?? initialFormValues,
        validationSchema: formValidationSchema.concat(
            Yup.object({
                accountId: isCurrentUserAdmin
                    ? Yup.string()
                    : Yup.string().required(intlService.get('app.validation.accountIsRequired')),
            })
        ),
        onSubmit: async (formValues) => {
            await props.onSubmit(formValues);
        },
    });

    useEffect(() => {
        // active boolean is used to disallow state change in chage component is not mounted.
        let active = true;
        fetchAccounts({ input: '' }).then((data) => {
            if (active) {
                if (accounts === undefined) {
                    setAccounts(data);
                }
            }
        });
        return () => {
            active = false;
        };
    }, []);

    const fetchAccounts = useCallback(
        async (request: { input: string }) => {
            if (isCurrentUserAdmin) {
                formik.values.accountId = currentUserData.accountId ?? '';
                return [];
            }

            const accountsResult = await accountService.getAccountList({
                size: 20,
                page: 0,
                searchQuery: request.input,
            });

            const autoCompleteFieldItems = accountsResult.data.records.map((account) => {
                const item: IAsyncAutoCompleteFieldItem = {
                    name: account.name ?? '',
                    value: account.id ?? '',
                };

                return item;
            });

            return autoCompleteFieldItems;
        },
        [accounts]
    );
    const handleClose = () => {
        formik.resetForm();
        setSelectedAccount(null);
        props.onClose();
    };

    return (
        <FullScreenDialog open={props.open} onClose={handleClose} title={intlService.get('app.component.keywords')}>
            <form onSubmit={formik.handleSubmit} className='add-edit-form h-100' noValidate>
                <Grid container={true} direction='row' className='h-100 pt-15'>
                    <Grid item xs={6}>
                        <Grid container={true} justify='flex-start' spacing={2}>
                            {UserService.getUserData()?.role === UserRole.Super && accounts !== undefined && (
                                <Grid item xs={12}>
                                    <AsyncAutoCompleteField
                                        id='accountId'
                                        name='accountId'
                                        label={`${intlService.get('app.message.select')} ${intlService.get(
                                            'app.label.account'
                                        )}`}
                                        value={selectedAccount}
                                        initialData={accounts}
                                        errorMessage={formik.errors.accountId}
                                        showError={Boolean(
                                            (formik.values.accountId?.length || formik.submitCount) &&
                                                !!formik.errors.accountId
                                        )}
                                        disabled={UserService.getUserData()?.role !== UserRole.Super}
                                        onValueChange={(value: string) => {
                                            formik.setFieldValue('accountId', value);
                                            setSelectedAccount(() => {
                                                const account = accounts?.find((a) => a.value === value);
                                                if (account) {
                                                    return {
                                                        name: account.name,
                                                        value: value,
                                                    };
                                                }
                                                return null;
                                            });
                                        }}
                                        fetchMethod={fetchAccounts}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    id='name'
                                    placeholder={intlService.get('app.label.name')}
                                    label={intlService.get('app.label.name')}
                                    variant='outlined'
                                    value={formik.values.name}
                                    margin='normal'
                                    onChange={formik.handleChange}
                                    aria-describedby={`$name-text`}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'input-notched-outline',
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        id: `name-text`,
                                        error: true,
                                    }}
                                    error={
                                        !!((formik.values.name.length || formik.submitCount) && !!formik.errors.name)
                                    }
                                    helperText={
                                        formik.values.name.length || formik.submitCount ? formik.errors.name : null
                                    }
                                    required
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid alignItems='flex-end' container>
                        <Grid item xs={6}>
                            <Grid container justify='flex-start'>
                                <Grid item>
                                    <Button
                                        type='button'
                                        data-testid='back-button'
                                        disabled={formik.isSubmitting}
                                        onClick={async () => {
                                            handleClose();
                                        }}>
                                        {intlService.get('app.button.back')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justify='flex-end' spacing={2}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        data-testid='delete-button'
                                        color='secondary'
                                        className='light-red-color'
                                        type='reset'
                                        disabled={formik.isSubmitting}
                                        onClick={() => {
                                            formik.resetForm();
                                            setSelectedAccount(null);
                                        }}>
                                        {intlService.get('app.button.clear')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <CustomButton
                                        disabled={formik.isSubmitting}
                                        loading={formik.isSubmitting}
                                        className='primary-color'
                                        data-testid='save-button'>
                                        {intlService.get('app.button.save')}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FullScreenDialog>
    );
};

export default KeywordForm;
