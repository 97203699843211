import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { ICustomButtonProps } from '../models/shared.models';

const defaultProps: ICustomButtonProps = {
    type: 'submit',
    color: 'primary',
    fullWidth: false,
    disabled: false,
    loading: false,
    variant: 'contained',
    size: 'medium',
    disableFocusRipple: false,
    children: null,
    href: undefined,
    onClick: () => {},
    autoFocus: false,
};

const CustomButton = (props: ICustomButtonProps) => {
    const { loading, toolTipProps, ...buttonProps } = props;
    const button = (
        <Button {...buttonProps} onClick={(e) => (props.onClick ? props.onClick(e) : '')}>
            {props.children}
            {loading && (
                <Box ml={1}>
                    <CircularProgress size={16} style={{ display: 'flex' }} />
                </Box>
            )}
        </Button>
    );

    if (!toolTipProps?.title) {
        return button;
    }

    return (
        <Tooltip title={toolTipProps?.title ?? ''} placement={toolTipProps?.placement}>
            {button}
        </Tooltip>
    );
};

CustomButton.defaultProps = defaultProps;
export default CustomButton;
