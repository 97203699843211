import { Button, createStyles, Grid, Switch, Theme, Tooltip, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DataGrid, GridColumns, GridPageChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AxiosError } from 'axios';
import Moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router';
import { AppStatusEnum, OperationEnum, SnackBarVariants, StatusEnum } from '../../app.enum';
import environment from '../../environments/environment';
import * as models from '../../models';
import { IAccountList } from '../../models';
import { AccountService } from '../../services/account.service';
import { default as Injector } from '../../services/index';
import { IntlService } from '../../services/intl.service';
import { MasterService } from '../../services/master.service';
import { SharedService } from '../../services/shared.service';
import CustomDialogBox from '../../shared/dialog.component';
import { getSearchQuery, removeSearchQuery } from '../../shared/header/SearchInput';
import withSnackBar from '../../shared/message.component';
import AccountForm, { initialFormValues } from './account-form';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    });

const intlService: IntlService = Injector.get(IntlService);
const accountService: AccountService = Injector.get(AccountService);
const masterService: MasterService = Injector.get(MasterService);
const sharedService: SharedService = Injector.get(SharedService);

class AccountComponent extends React.Component<models.IKeywordListProps, models.IAccountComponentState> {
    private countryList: any = [];
    rowData: any;
    accountIdForAction = '';
    anchorEl: HTMLButtonElement | undefined;
    isPortalInitiatedAppInstall = environment.isPortalInitiatedAppInstall;
    constructor(props: models.IKeywordListProps) {
        super(props);

        this.state = {
            records: [],
            nextPage: '',
            previousPage: '',
            pageSize:
                environment.uiSettings.pagination.rowsPerPageArray[
                    environment.uiSettings.pagination.defaultIndexOfRowsPerPageArray
                ],
            totalPages: 0,
            totalRecords: 0,
            currentPage: 0,
            isDisplayForm: false,
            isActionOpen: false,
            openConfirmationDialog: false,
            openGroupPostConfirmationDialog: false,
            stateList: [],
            operation: OperationEnum.Add,
            initialFormValues: initialFormValues,
            columns: [],
            sortModel: [{ field: 'createdDate', sort: 'desc' }],
        };
    }

    /**
     * we are not initializing the columns while initializing the state, as in the columns, it is also using the state.So, it gives warning
     * so, to resolve that, we are just assigning a blank array at the time of state initialization, and after that setting the columns in state
     * @returns it return grid columns
     */
    getGridColumns(): GridColumns {
        return [
            {
                field: 'name',
                headerName: intlService.get('app.label.name'),
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <Tooltip title={params.row.name} className="text-overflow"><span>{params.row.name}</span></Tooltip>;
                },
            },
            {
                field: 'country',
                headerName: intlService.get('app.label.country'),
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    const countryName = this.countryList.find((item: any) => item.code === params.row.address?.country);
                    return <React.Fragment>{countryName?.name || ''}</React.Fragment>;
                },
            },
            {
                field: 'address',
                headerName: intlService.get('app.label.address'),
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    if (!params.row?.address) {
                        return <></>;
                    }

                    const { address1, address2, address3, city, state, postalCode } = params.row.address;

                    const addressLines = [address1, address2, address3]
                        .filter((address) => address)
                        .map((address: string) => {
                            const alreadyHaveComma = address.substring(address.length - 1) === ',';
                            return alreadyHaveComma ? address : address + ',';
                        });

                    const completeAddress = [...addressLines, city, state, postalCode].join(' ');

                    return (
                        <Tooltip title={completeAddress} className='text-overflow'>
                            <span>{completeAddress}</span>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'groupPostWaitWindow',
                headerName: intlService.get('app.label.groupPostWaitWindowInMinutes'),
                headerClassName: 'datagrid-header-word-break',
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <React.Fragment>{params.row.groupPostWaitWindow || 0}</React.Fragment>;
                },
            },
            {
                field: 'groupPostEnabled',
                headerName: intlService.get('app.label.groupPostEnabled'),
                headerClassName: 'datagrid-header-word-break',
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>
                            <Switch
                                checked={params.row.isGroupPostEnabled}
                                onClick={async (e) => {
                                    this.rowData = params.row;
                                    this.accountIdForAction = params.row.id;
                                    await this.setState({
                                        openGroupPostConfirmationDialog: true,
                                        isActionOpen: false,
                                        columns: [...this.state.columns],
                                    });
                                }}
                                color='primary'
                                name='checkedB'
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        </React.Fragment>
                    );
                },
            },
            {
                field: 'appStatus',
                headerName: intlService.get('app.label.appStatus'),
                flex: 1,
                disableColumnMenu: true,
                hide: !this.isPortalInitiatedAppInstall,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>
                            {intlService.get('app.label.' + AppStatusEnum[params.row.appStatus]?.toLowerCase())}
                        </React.Fragment>
                    );
                },
            },
            {
                field: 'status',
                headerName: intlService.get('app.label.active'),
                flex: 0.7,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>
                            <Switch
                                checked={params.row.statusID === StatusEnum.Enabled}
                                onClick={async (e) => {
                                    this.rowData = params.row;
                                    this.accountIdForAction = params.row.id;
                                    await this.setState({
                                        openConfirmationDialog: true,
                                        isActionOpen: false,
                                        columns: [...this.state.columns],
                                    });
                                }}
                                color='primary'
                                name='checkedB'
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        </React.Fragment>
                    );
                },
            },
            {
                field: 'createdDate',
                headerName: intlService.get('app.label.createdDate'),
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => (
                    <React.Fragment>
                        {params.row?.createdDate && Moment(params.row.createdDate).format('ll')}
                    </React.Fragment>
                ),
            },
            {
                flex: 0.5,
                headerClassName: 'action-header-column',
                cellClassName: 'action-column',
                field: 'action',
                sortable: false,
                headerName: intlService.get('app.label.action'),
                disableColumnMenu: true,
                disableClickEventBubbling: true,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>
                            <IconButton
                                onClick={async (e) => {
                                    this.rowData = params.row;
                                    this.accountIdForAction = params.row.id;
                                    this.anchorEl = e.currentTarget;
                                    await this.setState({
                                        isActionOpen: true,
                                        columns: [...this.state.columns],
                                    });
                                }}
                                edge='start'
                                color='inherit'
                                aria-label='Action'>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id='fade-menu'
                                // elevation is used for box Shadow issue bug WPI-295
                                elevation={1}
                                anchorEl={this.anchorEl}
                                keepMounted
                                open={this.state.isActionOpen}
                                onClick={async () => {
                                    await this.setState({
                                        isActionOpen: false,
                                        columns: [...this.state.columns],
                                    });
                                }}>
                                <MenuItem
                                    onClick={async () => {
                                        await this.editDetails();
                                    }}>
                                    {intlService.get('app.button.edit')}
                                </MenuItem>

                                {/* TODO - Delete functionality will be added in future */}
                                {/* <MenuItem
                            onClick={async () => {
                                await this.setState({
                                    openConfirmationDialog: true,
                                    isActionOpen: false,
                                    columns: [...this.state.columns],
                                });
                            }}
                        >
                            {intlService.get('app.button.delete')}
                        </MenuItem> */}
                            </Menu>
                        </React.Fragment>
                    );
                },
            },
        ];
    }

    public async componentDidMount() {
        await this.setState({
            columns: this.getGridColumns(),
        });
        await this.getCountryList();
        await this.loadData();
    }

    public async componentDidUpdate(prevProps: models.IKeywordListProps) {
        const { searchQuery, isSearchQueryChanged } = getSearchQuery(prevProps, this.props);

        if (isSearchQueryChanged) {
            await this.loadData(searchQuery);
        }
    }

    async getCountryList() {
        await masterService
            .getCountryList()
            .then(async (res) => {
                this.countryList = res.data;
            })
            .catch((err: AxiosError) => {
                this.countryList = [];
            });
    }

    public componentWillUnmount() {}

    editDetails = async () => {
        await this.onSelectCountry(this.rowData.address?.country);

        this.setState({
            isDisplayForm: true,
            operation: OperationEnum.Edit,
            initialFormValues: {
                name: this.rowData.name,
                address1: this.rowData.address?.address1,
                address2: this.rowData.address?.address2,
                address3: this.rowData.address?.address3,
                city: this.rowData.address?.city,
                state: this.rowData.address?.state,
                postalCode: this.rowData.address?.postalCode,
                country: this.rowData.address?.country,
                groupPostWaitWindow: this.rowData.groupPostWaitWindow,
            },
        });
    };

    deleteAccount = async (id: string) => {
        await accountService
            .deleteAccount(id)
            .then(async (res) => {
                await this.loadData();
                this.openSnackbar(intlService.get('app.label.accountDeletedSuccessfully'), SnackBarVariants.Success);
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    changeStatus = async (id: string, accountData: Partial<IAccountList>) => {
        await accountService
            .updateAccountStatus(id, accountData)
            .then(async (res) => {
                removeSearchQuery(this.props);
                await this.loadData();
                if (accountData.statusID === StatusEnum.Enabled) {
                    this.openSnackbar(
                        intlService.get('app.message.accountActivatedSuccessfully'),
                        SnackBarVariants.Success
                    );
                } else {
                    this.openSnackbar(
                        intlService.get('app.message.accountDeactivatedSuccessfully'),
                        SnackBarVariants.Success
                    );
                }
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    changeGroupPostStatus = async (id: string, accountData: Partial<IAccountList>) => {
        await accountService
            .updateGroupPostStatus(id, accountData)
            .then(async (res) => {
                removeSearchQuery(this.props);
                await this.loadData();
                if (accountData.isGroupPostEnabled) {
                    this.openSnackbar(
                        intlService.get('app.message.groupPostEnabledSuccessfully'),
                        SnackBarVariants.Success
                    );
                } else {
                    this.openSnackbar(
                        intlService.get('app.message.groupPostDisabledSuccessfully'),
                        SnackBarVariants.Success
                    );
                }
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };
    private loadData = async (searchQuery?: string) => {
        const queryParam = {
            size: this.state.pageSize,
            page: this.state.currentPage,
            sortBy: this.state.sortModel[0]?.field,
            sortingOrder: this.state.sortModel[0]?.sort,
            searchQuery,
        };
        await accountService
            .getAccountList(queryParam)
            .then(async (res) => {
                await this.setState({
                    records: res?.data?.records,
                    totalRecords: res?.data?.totalRecords,
                });
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    saveAccount = async (e: models.IAccountFormFields) => {
        let obj: models.IAddEditAccount = {
            name: e.name,
            address: {
                address1: e.address1,
                address2: e.address2,
                address3: e.address3,
                city: e.city,
                state: e.state,
                postalCode: e.postalCode,
                country: e.country,
            },
            groupPostWaitWindow: e.groupPostWaitWindow,
        };
        if (this.state.operation === OperationEnum.Edit) {
            await accountService
                .updateAccount(this.accountIdForAction, obj)
                .then(async (res) => {
                    this.setState({isDisplayForm:false});
                    await this.loadData();
                    this.openSnackbar(
                        intlService.get('app.message.accountUpdatedSuccessfully'),
                        SnackBarVariants.Success
                    );
                })
                .catch((err: AxiosError) => {
                    const errorMessage = sharedService.parseError(err);
                    this.openSnackbar(errorMessage, SnackBarVariants.Error);
                });
        } else {
            obj = {
                ...obj,
                adminEmail: e.adminEmail?.trim(),
                isGroupPostEnabled: true, // on creation of account, enabling group post to true
            };
            await accountService
                .createAccount(obj)
                .then(async (res) => {
                    this.setState({isDisplayForm:false});
                    await this.loadData();
                    this.openSnackbar(
                        intlService.get('app.message.accountAddedSuccessfully'),
                        SnackBarVariants.Success
                    );
                })
                .catch((err: AxiosError) => {
                    const errorMessage = sharedService.parseError(err);
                    this.openSnackbar(errorMessage, SnackBarVariants.Error);
                });
        }
        this.loadData();
    };

    handlePageChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: params.page,
        });
        await this.loadData();
    };
    handlePageSizeChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: 0,
        });
        await this.loadData();
    };
    handleSortModelChange = async (params: GridSortModelParams) => {
        await this.setState({
            sortModel: params.sortModel,
        });
        const {searchQuery}=getSearchQuery(this.props, this.props);
        if(searchQuery){
            await this.loadData(searchQuery);
        }else{
            await this.loadData();
        }
        };

    displayList = () => {
        return (
            <div className='table-view'>
                <DataGrid
                    rows={this.state.records}
                    columns={this.state.columns}
                    pagination
                    rowsPerPageOptions={environment.uiSettings.pagination.rowsPerPageArray}
                    onPageSizeChange={this.handlePageSizeChange}
                    pageSize={this.state.pageSize}
                    rowCount={this.state.totalRecords}
                    paginationMode='server'
                    onPageChange={this.handlePageChange}
                    sortingMode='server'
                    sortModel={this.state.sortModel}
                    onSortModelChange={this.handleSortModelChange}
                    hideFooterSelectedRowCount={true}
                    disableSelectionOnClick={true}
                    page={this.state.currentPage}
                />
            </div>
        );
    };

    async onSelectCountry(countryCode: string) {
        if (countryCode) {
            await masterService.getStateListByCountryCode(countryCode).then(async (res) => {
                this.setState({
                    stateList: res.data,
                });
            });
        }
    }

    public render() {
        const { classes } = this.props;
        return (
            <>
                {' '}
                <Grid item={true}>
                    <Button
                        data-testid='add-btn'
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.setState({
                                isDisplayForm: true,
                                operation: OperationEnum.Add,
                                initialFormValues: initialFormValues,
                            });
                        }}
                        className='add-button-table primary-color '
                        startIcon={<AddIcon />}>
                        {intlService.get('app.button.add')}
                    </Button>
                </Grid>
                {this.displayList()}
                {this.state.isDisplayForm && (
                    <AccountForm
                        open={this.state.isDisplayForm}
                        onSubmit={(formValues) => this.saveAccount(formValues)}
                        onClose={() => this.setState({ isDisplayForm: false, operation: OperationEnum.Add })}
                        countryList={this.countryList}
                        stateList={this.state.stateList}
                        onCountryChange={(countryCode) => this.onSelectCountry(countryCode)}
                        prefilledFormValues={this.state.initialFormValues}
                        operation={this.state.operation}
                    />
                )}
                <CustomDialogBox
                    open={this.state.openConfirmationDialog && !(this.rowData.statusID === StatusEnum.Enabled)}
                    dialogTitle={intlService.get('app.dialog.title.activateAccount')}
                    dialogDescription={intlService.get('app.message.activateAccountConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
                <CustomDialogBox
                    open={this.state.openConfirmationDialog && this.rowData.statusID === StatusEnum.Enabled}
                    dialogTitle={intlService.get('app.dialog.title.deactivateAccount')}
                    dialogDescription={intlService.get('app.message.deactivateAccountConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
                <CustomDialogBox
                    open={this.state.openGroupPostConfirmationDialog && !this.rowData.isGroupPostEnabled}
                    dialogTitle={intlService.get('app.dialog.title.enableGroupPost')}
                    dialogDescription={intlService.get('app.message.enableGroupPostConfirmation')}
                    closeCustomDialog={this.closeGroupPostDialog}
                />
                <CustomDialogBox
                    open={this.state.openGroupPostConfirmationDialog && this.rowData.isGroupPostEnabled}
                    dialogTitle={intlService.get('app.dialog.title.disableGroupPost')}
                    dialogDescription={intlService.get('app.message.disableGroupPostConfirmation')}
                    closeCustomDialog={this.closeGroupPostDialog}
                />
            </>
        );
    }

    private closeDialog = async (dialogTitle: string, isCancel: boolean) => {
        if (!isCancel) {
            this.changeStatus(this.accountIdForAction, {
                statusID: this.rowData.statusID === StatusEnum.Enabled ? StatusEnum.Disabled : StatusEnum.Enabled,
            });
            isCancel = true;
        }
        this.accountIdForAction = '';
        await this.setState({
            openConfirmationDialog: false,
        });
    };
    /**On Closing the dialogflow popup of group status, this function will be called
     * In this function
     *     - if user has cancel the dialogflow popup, then we are resetting the state, and closing the popup
     *     - If user has confired with yes/no, then we will make a api hit based on the user input
     */
    private closeGroupPostDialog = async (dialogTitle: string, isCancel: boolean) => {
        if (!isCancel) {
            this.changeGroupPostStatus(this.accountIdForAction, {
                isGroupPostEnabled: !this.rowData.isGroupPostEnabled,
            });
            isCancel = true;
        }
        this.accountIdForAction = '';
        await this.setState({
            openGroupPostConfirmationDialog: false,
        });
    };

    private openSnackbar = (message: string | string[], variantName: SnackBarVariants = SnackBarVariants.Success) => {
        this.props.openSnackBar!(message, variantName);
    };
}

export default withSnackBar(withStyles(styles)(withRouter(AccountComponent)));
