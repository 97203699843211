import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SnackBarVariants } from '../../../../app.enum';
import { IAccountList } from '../../../../models';
import { useSnackbar } from '../../../../providers/snackbar.provider';
import injector from '../../../../services';
import { AccountService } from '../../../../services/account.service';
import { IntlService } from '../../../../services/intl.service';
import { SharedService } from '../../../../services/shared.service';
import { UserService } from '../../../../services/user.service';

const intlService: IntlService = injector.get(IntlService);
const accountService: AccountService = injector.get(AccountService);
const sharedService: SharedService = injector.get(SharedService);

const InfoComponent: React.FC = () => {
    const snackbar = useSnackbar();
    const [accountData, setAccountData] = useState<Partial<IAccountList>>({});
    const fetchAccountData = async (accountId: string) => {
        try {
            const accountResponse = await accountService.getAccountById(accountId);
            return accountResponse.data;
        } catch (err) {
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    };

    useEffect(() => {
        // using isMounted to remove memory leak issue.
        let isMounted = true;
        const fetchData = async () => {
            const userData = UserService.getUserData();
            if (userData?.accountId) {
                const data = await fetchAccountData(userData.accountId);
                if (isMounted && data) {
                    setAccountData(data);
                }
            }
        };

        fetchData();
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <Grid container spacing={3} direction='row' justify='center' alignItems='center'>
            <Grid container item xs={12} direction='row' spacing={2}>
                <Grid item xs={4} data-testid='accountNameLabel'>
                    {intlService.get('app.label.accountName')} :
                </Grid>
                <Grid item xs={4} data-testid='accountName'>
                    {accountData.name}
                </Grid>
            </Grid>
            <Grid container item xs={12} direction='row' spacing={2}>
                <Grid item xs={4} data-testid='groupPostWaitWindowInMinutesLabel'>
                    {intlService.get('app.label.groupPostWaitWindow')} :
                </Grid>
                <Grid item xs={4} data-testid='groupPostWaitWindowInMinutes'>
                    {accountData.isGroupPostEnabled
                        ? accountData.groupPostWaitWindow + ' ' + intlService.get('app.label.minutes')
                        : intlService.get('app.label.notEnabled')}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InfoComponent;
