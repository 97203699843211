export enum ButtonVariantEnum {
    Text = 'text',
    Outlined = 'outlined',
    Contained = 'contained',
}

export enum SnackBarVariants {
    Error = 'error',
    Info = 'info',
    Success = 'success',
}

export enum OperationEnum {
    Add = 'Add',
    Edit = 'Edit',
    ChangeStatus = 'ChangeStatus',
    ChangeMuteStatus = 'ChangeMuteStatus',
    Default = 'Default', // using default, when we don't want any operation
}

export enum UserRole {
    Super = 1,
    Admin = 2,
    User = 3,
}

export enum StatusEnum {
    Disabled,
    Enabled,
    Invited,
}

export enum DashboardButtons {
    Total = 'total',
    Incoming = 'incoming',
    Outgoing = 'outgoing',
}

export enum AppStatusEnum {
    NotInstalled,
    Installed,
    Uninstalled,
}

export enum LoginPurposeEnum {
    PortalLogin = 'Portal',
    AppIntegrationLogin = 'AppIntegration',
}

// As we are having multiple dialogflow agents, so this enum is used to determine which agent we want to use
export enum AgentTypeEnum {
    Group,
    Chat,
}

export enum MessageSource {
    Workplace = 1,
    Whatsapp = 2, // to be used in future
    SMSShortCode = 3, // to be used in future
}

export enum InstallProgressStatusEnum {
    InProgress,
    Success,
    Failed,
}

/**
 * Axios Status Codes
 */
 export enum StatusCodes {
    Status200OK = 200,
    Status201Created = 201,
    Status400BadRequest = 400,
    Status401Unauthorized = 401,
    Status403Forbidden = 403,
    Status404NotFound = 404,
    Status422UnprocessableEntity = 422,
    Status500InternalServerError = 500,
}
