import {
    Card,
    CircularProgress,
    Container,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as actions from '../../actions/index';
import { InstallProgressStatusEnum, UserRole } from '../../app.enum';
import PavenLogo from '../../assets/images/logo_paven.svg';
import { queryParams } from '../../constants/core.constants';
import environment from '../../environments/environment';
import { IAccountList } from '../../models/account.models';
import { AccountService } from '../../services/account.service';
import { AppInstallationService } from '../../services/app-installation.service';
import { default as injector, default as Injector } from '../../services/index';
import { IntlService } from '../../services/intl.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import CustomButton from '../../shared/custom-button.component';
import Login from '../../user/Login';

interface IAppDispatchToProps {
    logOut: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        logo: {
            display: 'block',
            margin: '0 auto',
            marginTop: 30,
            marginBottom: 25,
            maxWidth: '100%',
            maxHeight: '100%',
        },
        greetingText: {
            margin: '0',
            fontSize: '16px',
            lineHeight: '1.2',
            wordBreak: 'break-word',
            textAlign: 'center',
            fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
            msoLineHeightAlt: '19px',
            marginTop: '0',
            marginBottom: '0',
        },
        installStatusItem: {
            minHeight: '30px',
        },
        installStatusCard: {
            paddingBottom: '16px !important',
        },
        message: {
            wordBreak: 'break-word',
            textAlign: 'center',
            paddingBottom: '20px',
            paddingTop: '20px',
            color: '#4e6ef3',
        },
        thankyou: {
            margin: '0',
            fontSize: '14px',
            lineHeight: '1.2',
            wordBreak: 'break-word',
            textAlign: 'center',
            msoLineHeightAlt: '17px',
            marginTop: '0',
            marginBottom: '0',
        },
        pavenSupport: {
            margin: '0',
            fontSize: '12px',
            lineHeight: '1.2',
            wordBreak: 'break-word',
            textAlign: 'center',
            msoLineHeightAlt: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
    })
);

const intlService: IntlService = injector.get(IntlService);
const appInstallationService: AppInstallationService = Injector.get(AppInstallationService);
const sharedService: SharedService = Injector.get(SharedService);
const accountService: AccountService = Injector.get(AccountService);
let message: string | undefined;

const appIntegrationURL =
    environment.workplace.workAPIBaseUrl + 'work/admin/apps?app_id=' + environment.installApp.appId;
const maximumRetries = environment.workplace.maximumRetriesInAppInstall; // 3
const retryAppInstallAfterEveryXSeconds = environment.workplace.retryAppInstallAfterEveryXSeconds; // 5
const defaultAccountData = {
    isActive: false,
    isGroupPostEnabled: false,
    groupPostWaitWindow: 0,
    source: {
        installStatus: {
            codeReceiveStatus: InstallProgressStatusEnum.InProgress,
            accessTokenReceiveStatus: InstallProgressStatusEnum.InProgress,
            domainWhitelistStatus: InstallProgressStatusEnum.InProgress,
        },
        chatbotId: '',
    },
};
const AppInstallationHandler: React.FC<IAppDispatchToProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
    const [numberOfRetriesInAppInstall, setNumberOfRetriesInAppInstall] = useState<number>(1);
    const [accountData, setAccountData] = useState<Partial<IAccountList>>(defaultAccountData);
    const [showAppInstallationSuccessUI, setShowAppInstallationSuccessUI] = useState<boolean>(false);
    const [appInstallationStatusMessage, setAppInstallationStatusMessage] = useState<any>(
        intlService.getHTML('app.message.appInstallationInProgress')
    );

    useEffect(() => {
        const userData = UserService.getUserData();
        setIsUserAuthenticated(userData.isAuthenticated);
        if (userData?.isAuthenticated) {
            setShowAppInstallationSuccessUI(true);
            handleAppInstallation();
        }
    }, []);

    useEffect(() => {
        const userData = UserService.getUserData();
        //exit early when we reach 0
        if (isUserAuthenticated) {
            return;
        }

        let interval = setInterval(() => {
            if (userData.isAuthenticated) {
                // this code will run only one time
                setIsUserAuthenticated(userData.isAuthenticated);
                setNumberOfRetriesInAppInstall(0);
                setNumberOfRetriesInAppInstall(1);
            }
        }, 1000);

        return () => clearInterval(interval);
    });

    const handleAppInstallation = async () => {
        const params = new URLSearchParams(history.location.search);
        const appIntegrationCode: string | null = params.get(queryParams.appInstallCode);
        const userData = UserService.getUserData();
        setShowAppInstallationSuccessUI(true);
        if (appIntegrationCode) {
            if (userData?.role === UserRole.Admin) {
                try {
                    // when loading is false, then only we will hit the api
                    if (!isLoading) {
                        setIsLoading(() => true);
                        const result = await appInstallationService.authorize(appIntegrationCode);
                        message = result.data.message;
                    }
                } catch (err: any) {
                    if (err.response?.data?.message === 'errorWhileInstallingApp') {
                        parseAndSetInstallationErrorMsg();
                    } else {
                        const errorMessage = sharedService.parseError(err);
                        setAppInstallationStatusMessage(errorMessage);
                    }
                    setIsLoading(false);
                }
            } else {
                setAppInstallationStatusMessage(intlService.getHTML('app.error.unauthorizedToInstallApp'));
                setIsLoading(false);
            }
        } else {
            parseAndSetInstallationErrorMsg();
        }
    };

    const parseAndSetInstallationErrorMsg = () => {
        const formattedHtmlMsg = intlService.formatHTMLMessage(
            { id: 'app.error.errorWhileInstallingApp' },
            {
                breakTag: `<br/>`,
                appLink: `<a href=${appIntegrationURL} target="_blank">Paven App</a>`,
            }
        ) as any;
        const msg = formattedHtmlMsg.props.dangerouslySetInnerHTML.__html;
        setAppInstallationStatusMessage(parse(parse(msg.toString()).toString()));
        setIsLoading(false);
    };

    const parseAndSetInstallationSuccessfulMsg = (chatbotId: string, message: string = 'appInstalledSuccessfully') => {
        const chatbotRedirectUrl = environment.workplace.workAPIBaseUrl + 'chat/t/' + chatbotId;
        const formattedHtmlMsg = intlService.formatHTMLMessage(
            { id: `app.message.${message}` },
            {
                breakTag: `<br/>`,
                chatbotLink: `<a href=${chatbotRedirectUrl} target="_blank">Paven</a>`,
            }
        ) as any;
        const msg = formattedHtmlMsg.props.dangerouslySetInnerHTML.__html;
        setAppInstallationStatusMessage(parse(parse(msg.toString()).toString()));
        setIsLoading(false);
    };

    useEffect(() => {
        const userData = UserService.getUserData();

        if (userData?.role !== UserRole.Admin || numberOfRetriesInAppInstall > maximumRetries) {
            if (userData.isAuthenticated) {
                parseAndSetInstallationErrorMsg();
            }
            return;
        }

        let interval = setInterval(() => {
            // On Refreshing app installation url in portal popup, don't hit the same api again, until the previous one is completed

            if (userData?.isAuthenticated) {
                if (isLoading) {
                    fetchAccountData();
                }
                return;
            }
        }, retryAppInstallAfterEveryXSeconds * 1000);

        return () => clearInterval(interval);
    }, [isLoading]);

    const fetchAccountData = async () => {
        setNumberOfRetriesInAppInstall((x) => x + 1);
        const userData = UserService.getUserData();
        const res = await accountService.getAccountById(userData.accountId);
        const accountDataRes = res.data;
        if (appInstallationService.isAppSuccessfullyInstalled(accountDataRes)) {
            setIsLoading(false);
            //If successfully installed, then do not again call
            setNumberOfRetriesInAppInstall((x) => maximumRetries);

            parseAndSetInstallationSuccessfulMsg(accountDataRes?.source?.chatbotId || '', message);
        }
        setAccountData(accountDataRes);
    };

    const renderSwitch = (statusEnum: InstallProgressStatusEnum) => {
        switch (statusEnum) {
            case InstallProgressStatusEnum.Success:
                return <CheckCircleIcon className='success' />;

            case InstallProgressStatusEnum.Failed:
                return <ErrorOutlineIcon className='error' />;

            default:
                return 'In Progress...';
        }
    };
    const completeInstallation = (): JSX.Element => {
        return (
            <Grid className='install-status  mt-10' item={true} xs={12} sm={12} md={12} lg={12}>
                <Card square={true}>
                    <CardContent className={classes.installStatusCard}>
                        <Grid container={true} spacing={1}>
                            <Grid className={classes.installStatusItem} item={true} xs={12} md={12}>
                                <Grid container={true}>
                                    <Grid item={true} xs={8} md={8}>
                                        <Typography variant='subtitle2' color='primary'>
                                            <strong>Authorization Code Received</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} xs={4} md={4} className='mb-n5'>
                                        {accountData.source
                                            ? renderSwitch(accountData.source.installStatus?.codeReceiveStatus)
                                            : ''}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={classes.installStatusItem} item={true} xs={12} md={12}>
                                <Grid container={true}>
                                    <Grid item={true} xs={8} md={8}>
                                        <Typography variant='subtitle2' color='primary'>
                                            <strong>Access token Received</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} xs={4} md={4} className='mb-n5'>
                                        {accountData.source
                                            ? renderSwitch(accountData.source.installStatus?.accessTokenReceiveStatus)
                                            : ''}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid className={classes.installStatusItem} item={true} xs={12} md={12}>
                                <Grid container={true}>
                                    <Grid item={true} xs={8} md={8}>
                                        <Typography variant='subtitle2' color='primary'>
                                            <strong>IP whitelisting</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item={true} xs={4} md={4} className='mb-n5'>
                                        {accountData.source
                                            ? renderSwitch(accountData.source.installStatus?.domainWhitelistStatus)
                                            : ''}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    };
    return showAppInstallationSuccessUI ? (
        <Container className={classes.root}>
            <img src={PavenLogo} alt='Paven Logo' width='400' height='150' className={classes.logo} />
            <Typography className={classes.greetingText}>
                <strong>Greetings from Paven</strong>
                <div></div>
            </Typography>
            {completeInstallation()}
            <Grid
                className={[classes.installStatusItem, 'mb-10'].join(' ')}
                container
                direction='column'
                alignItems='center'>
                <Grid item>
                    <Typography className={classes.message}>{appInstallationStatusMessage}</Typography>
                </Grid>
                <Grid item>{isLoading && <CircularProgress />}</Grid>
                <Grid item>
                    <CustomButton
                        onClick={() => {
                            window.close();
                        }}
                        className='paven-icon-color'>
                        Close Window
                    </CustomButton>
                </Grid>
            </Grid>

            <Typography className={classes.thankyou}>
                <strong>Thank You!</strong>
            </Typography>
            <Typography className={classes.pavenSupport}>
                <strong>Paven Support</strong>
            </Typography>
        </Container>
    ) : (
        <Login
            setToken={() => {}}
            onUserSignIn={() => {}}
            onSuccess={handleAppInstallation}
            redirectTo={`/install-app${history.location.search}`}
        />
    );
};

const mapDispatchToProps: MapDispatchToProps<IAppDispatchToProps, any> = (dispatch: Dispatch): IAppDispatchToProps => ({
    logOut: () => {
        dispatch(actions.userSignedOutAction());
    },
});

export default connect(null, mapDispatchToProps)(AppInstallationHandler);
