import {
    Button,
    ChipProps,
    createStyles,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { MessageParams } from 'yup/lib/types';
import { AgentTypeEnum, SnackBarVariants } from '../../../../app.enum';
import { DialogflowAgentResponseDto, UpdateDialogflowAgentRequestDto } from '../../../../models';
import { useSnackbar } from '../../../../providers/snackbar.provider';
import injector from '../../../../services';
import { DialogFlowSettingsService } from '../../../../services/dialogflow-settings.service';
import { IntlService } from '../../../../services/intl.service';
import { SharedService } from '../../../../services/shared.service';
import CustomButton from '../../../../shared/custom-button.component';
import SelectOptionWithChip from '../../../../shared/select-value-with-chip/SelectValueWithChip';

const intlService: IntlService = injector.get(IntlService);
const sharedService: SharedService = injector.get(SharedService);
const dialogFlowSettingsService: DialogFlowSettingsService = injector.get(DialogFlowSettingsService);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: { backgroundColor: 'white', padding: '0px 4px' },
    })
);

const validationSchema = yup.object({
    chatAgentId: yup
        .string()
        .length(36)
        .uuid()
        .notOneOf([yup.ref('groupAgentId')], (data: MessageParams) =>
            data.value
                ? intlService.get('app.validation.agentsShouldBeDifferent')
                : intlService.formatMessage({ id: 'app.validation.agentIsRequired' }, { agentName: 'Chat' })
        )
        .required(() => intlService.formatMessage({ id: 'app.validation.agentIsRequired' }, { agentName: 'Chat' })),
    groupAgentId: yup
        .string()
        .length(36)
        .uuid()
        .notOneOf([yup.ref('chatAgentId')], (data: MessageParams) =>
            data.value
                ? intlService.get('app.validation.agentsShouldBeDifferent')
                : intlService.formatMessage({ id: 'app.validation.agentIsRequired' }, { agentName: 'Group' })
        )
        .required(() => intlService.formatMessage({ id: 'app.validation.agentIsRequired' }, { agentName: 'Group' })),
});

const DialogFlowChangeAgents: React.FC = () => {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const formik = useFormik({
        initialValues: {
            chatAgentId: '',
            groupAgentId: '',
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            setIsLoadingButton((prev) => ({ ...prev, updateButton: true }));
        },
    });

    const [agents, setAgents] = useState<DialogflowAgentResponseDto[]>([]);
    const [fetchAgents, setFetchAgents] = useState<boolean>(true);
    const [activeAgents, setActiveAgents] = useState<{ chatAgentId: string; groupAgentId: string }>({
        chatAgentId: '',
        groupAgentId: '',
    });

    const [isLoadingButton, setIsLoadingButton] = useState({
        setDefaultButton: false,
        updateButton: false,
    });

    useEffect(() => {
        if (Object.values(isLoadingButton).some((x) => x)) {
            updateAgents();
        }
    }, [isLoadingButton]);

    useEffect(() => {
        // active boolean is used to disallow state change in chage component is not mounted.
        let active = true;
        if (fetchAgents) {
            fetchAllAgents().then((data) => {
                if (active && data) {
                    setAgents(data);
                    setFetchAgents(false);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [fetchAgents]);

    useEffect(() => {
        const activeChatAgentId =
            agents.find((x) => x.agentTypeID.includes(AgentTypeEnum.Chat) && x.isActive)?.agentId ?? '';
        const activeGroupAgentId =
            agents.find((x) => x.agentTypeID.includes(AgentTypeEnum.Group) && x.isActive)?.agentId ?? '';
        formik.setFieldValue('chatAgentId', activeChatAgentId);
        formik.setFieldValue('groupAgentId', activeGroupAgentId);
        setActiveAgents({
            chatAgentId: activeChatAgentId,
            groupAgentId: activeGroupAgentId,
        });
    }, [agents]);

    const fetchAllAgents = useCallback(async () => {
        try {
            const allAgentsResponse = await dialogFlowSettingsService.getAllAgents();
            return allAgentsResponse.data;
        } catch (err) {
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    }, []);

    const getSelectedAgentById = (agentId: string, agentTypeID: AgentTypeEnum) => {
        return agents
            .filter((x) => x.agentId === agentId)
            .map<UpdateDialogflowAgentRequestDto>((x) => ({
                agentId: x.agentId,
                agentLanguageCode: x.agentLanguageCode,
                agentLocation: x.agentLocation,
                agentTypeID: agentTypeID,
            }))[0];
    };

    const updateAgents = useCallback(async () => {
        try {
            const selectedAgents: { [key: string]: UpdateDialogflowAgentRequestDto } = {
                chatAgent: {
                    ...getSelectedAgentById(formik.values.chatAgentId, AgentTypeEnum.Chat),
                    agentTypeID: AgentTypeEnum.Chat,
                },
                groupAgent: {
                    ...getSelectedAgentById(formik.values.groupAgentId, AgentTypeEnum.Group),
                    agentTypeID: AgentTypeEnum.Group,
                },
            };

            // add agents to update only agents which are changed.
            const changedAgents = Object.values({
                chatAgent:
                    activeAgents.chatAgentId === selectedAgents.chatAgent.agentId ? null : selectedAgents.chatAgent,
                groupAgent:
                    activeAgents.groupAgentId === selectedAgents.groupAgent.agentId ? null : selectedAgents.groupAgent,
            }).filter((value) => !!value);

            if (changedAgents.length === 0) {
                setIsLoadingButton({ updateButton: false, setDefaultButton: false });
                return;
            }

            const promises = changedAgents.map((value) =>
                Promise.resolve().then(async () => {
                    if (value) {
                        await dialogFlowSettingsService.updateAgent(value);
                    }
                })
            );

            await Promise.all(promises);
            setFetchAgents(true);
            snackbar.showSnackbar(intlService.get('app.message.agentsChangedSuccessfully'), SnackBarVariants.Success);
            setIsLoadingButton({ updateButton: false, setDefaultButton: false });
        } catch (err) {
            setIsLoadingButton({ updateButton: false, setDefaultButton: false });
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    }, [formik.values, agents, activeAgents, setIsLoadingButton]);

    const onSetActiveAgents = () => {
        formik.setFieldValue('chatAgentId', activeAgents?.chatAgentId ?? '');
        formik.setFieldValue('groupAgentId', activeAgents?.groupAgentId ?? '');
    };

    const getSelectText = (agent: DialogflowAgentResponseDto, agentType: AgentTypeEnum) => {
        const chips: ChipProps[] = [];
        if (agent.agentTypeID.includes(agentType)) {
            if (agent.isActive) {
                chips.push({
                    label: 'Active',
                    color: 'primary',
                });
            }

            if (agent.isDefault) {
                chips.push({
                    label: 'Default',
                    color: 'default',
                });
            }
        }
        return <SelectOptionWithChip value={agent.agentName ?? ''} chips={chips} />;
    };

    const onSetDefaultAgents = () => {
        const defaultChatAgent = agents.find((x) => x.agentTypeID.includes(AgentTypeEnum.Chat) && x.isDefault);
        const defaultGroupAgent = agents.find((x) => x.agentTypeID.includes(AgentTypeEnum.Group) && x.isDefault);
        if (defaultChatAgent && defaultGroupAgent) {
            formik.setValues({
                chatAgentId: defaultChatAgent?.agentId,
                groupAgentId: defaultGroupAgent?.agentId,
            });
        }
    };

    return (
        <form
            autoComplete='off'
            style={{ width: '100%' }}
            onSubmit={formik.handleSubmit}
            data-testid='form'
            noValidate
            aria-label='form'>
            <Grid container spacing={3} direction='column' justify='center' alignItems='center'>
                <Grid container item xs={12} direction='row' spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabel shrink id='chatAgentId-select-label' className={classes.inputLabel}>
                                Chat Agent
                            </InputLabel>
                            <Select
                                labelId='chatAgentId-select-label'
                                name='chatAgentId'
                                label={intlService.get('app.label.chatAgent')}
                                placeholder={`${intlService.get('app.message.select')} ${intlService.get(
                                    'app.label.chatAgent'
                                )}`}
                                required={true}
                                value={formik.values.chatAgentId}
                                displayEmpty
                                onChange={formik.handleChange}
                                renderValue={(selected) => {
                                    if (selected) {
                                        const selectedAgent = agents.find(
                                            (x: DialogflowAgentResponseDto) => x.agentId === selected
                                        );
                                        if (selectedAgent) {
                                            return getSelectText(selectedAgent, AgentTypeEnum.Chat);
                                        }
                                    }
                                    return `${intlService.get('app.message.select')} ${intlService.get(
                                        'app.label.chatAgent'
                                    )}`;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '550px',
                                        },
                                    },
                                }}
                                error={!!(formik.submitCount && !!formik.errors.chatAgentId)}>
                                <MenuItem value=''>{`${intlService.get('app.message.select')} ${intlService.get(
                                    'app.label.chatAgent'
                                )}`}</MenuItem>
                                {agents
                                    .filter(
                                        (x) =>
                                            !(
                                                (x.isDefault || x.isActive) &&
                                                x.agentTypeID.includes(AgentTypeEnum.Group)
                                            )
                                    )
                                    .map((e: DialogflowAgentResponseDto) => {
                                        return (
                                            <MenuItem key={e.agentId} value={e.agentId}>
                                                {getSelectText(e, AgentTypeEnum.Chat)}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {!!(formik.submitCount && !!formik.errors.chatAgentId) && (
                                <FormHelperText error={true}>{formik.errors.chatAgentId}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabel shrink id='groupAgentId-select-label' className={classes.inputLabel}>
                                Group Agent
                            </InputLabel>
                            <Select
                                labelId='groupAgentId-select-label'
                                name='groupAgentId'
                                label={intlService.get('app.label.groupAgent')}
                                placeholder={`${intlService.get('app.message.select')} ${intlService.get(
                                    'app.label.groupAgent'
                                )}`}
                                required={true}
                                value={formik.values.groupAgentId}
                                displayEmpty
                                onChange={formik.handleChange}
                                renderValue={(selected) => {
                                    if (selected) {
                                        const selectedAgent = agents.find(
                                            (x: DialogflowAgentResponseDto) => x.agentId === selected
                                        );
                                        if (selectedAgent) {
                                            return getSelectText(selectedAgent, AgentTypeEnum.Group);
                                        }
                                    }
                                    return `${intlService.get('app.message.select')} ${intlService.get(
                                        'app.label.groupAgent'
                                    )}`;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '550px',
                                        },
                                    },
                                }}
                                error={!!(formik.submitCount && !!formik.errors.groupAgentId)}>
                                <MenuItem value=''>{`${intlService.get('app.message.select')} ${intlService.get(
                                    'app.label.groupAgent'
                                )}`}</MenuItem>
                                {agents
                                    .filter(
                                        (x) =>
                                            !((x.isDefault || x.isActive) && x.agentTypeID.includes(AgentTypeEnum.Chat))
                                    )
                                    .map((e: DialogflowAgentResponseDto) => {
                                        return (
                                            <MenuItem key={e.agentId} value={e.agentId}>
                                                {getSelectText(e, AgentTypeEnum.Group)}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {!!(formik.submitCount && !!formik.errors.groupAgentId) && (
                                <FormHelperText error={true}>{formik.errors.groupAgentId}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item direction='row' justify='space-between' spacing={2}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant='text'
                                    color='default'
                                    type='reset'
                                    onClick={onSetActiveAgents}
                                    disabled={Object.values(isLoadingButton).some((x) => x)}>
                                    {intlService.get('app.button.setActiveAgents')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <CustomButton
                                    variant='text'
                                    color='default'
                                    type='button'
                                    onClick={onSetDefaultAgents}
                                    disabled={Object.values(isLoadingButton).some((x) => x)}
                                    loading={isLoadingButton.setDefaultButton}>
                                    {intlService.get('app.button.setDefaultAgents')}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            disabled={Object.values(isLoadingButton).some((x) => x)}
                            loading={isLoadingButton.updateButton}
                            className='primary-color'>
                            {intlService.get('app.button.update')}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default DialogFlowChangeAgents;
