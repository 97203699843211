import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps, Color as SeverityColor } from '@material-ui/lab/Alert';
import React, { createContext, useContext, useState } from 'react';

interface ISnackbarState {
    message: string;
    showSnackbar: boolean;
    severity: SeverityColor;
}

interface ISnackbarContext {
    showSnackbar: (message: string, severity?: SeverityColor) => void;
}

const initialSnackbarState: ISnackbarState = { message: '', showSnackbar: false, severity: 'success' };

const SnackbarContext = createContext<ISnackbarContext>({
    showSnackbar: () => {},
});

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const SnackbarProvider: React.FC<any> = (props) => {
    const [snackbarState, setSnackbarState] = useState<ISnackbarState>(initialSnackbarState);
    const showSnackbar = (message: string, severity: SeverityColor = 'success') => {
        setSnackbarState({ message, showSnackbar: true, severity });
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {props.children}

            {snackbarState.showSnackbar && (
                <Snackbar
                    open={snackbarState.showSnackbar}
                    autoHideDuration={8000}
                    onClose={() => setSnackbarState(initialSnackbarState)}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                    }}>
                    {snackbarState.showSnackbar && (
                        <Alert severity={snackbarState.severity} onClose={() => setSnackbarState(initialSnackbarState)}>
                            {snackbarState.message}
                        </Alert>
                    )}
                </Snackbar>
            )}
        </SnackbarContext.Provider>
    );
};

const useSnackbar = () => useContext<ISnackbarContext>(SnackbarContext);

export { SnackbarProvider, SnackbarContext, useSnackbar };
