import axios, { AxiosResponse } from 'axios';
import environment from '../environments/environment';
import { IKeywordList } from '../models';
import { UserService } from './user.service';

export class MasterService {
    masterUrl = environment.appSetting.baseUrl + 'master/';

    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    async getCountryList(): Promise<AxiosResponse<IKeywordList[]>> {
        const accountUrl = this.masterUrl + 'getCountryList';
        return axios.get<IKeywordList[]>(accountUrl, {
            params: {},
            headers: this.getHeaders(),
        });
    }

    async getStateListByCountryCode(countryCode: string): Promise<AxiosResponse<any[]>> {
        const accountUrl = this.masterUrl + 'getStateList/' + countryCode;
        return axios.get<IKeywordList[]>(accountUrl, {
            params: {},
            headers: this.getHeaders(),
        });
    }
}
