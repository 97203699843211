import axios from 'axios';
import { AppStatusEnum, InstallProgressStatusEnum } from '../app.enum';
import environment from '../environments/environment';
import { IAccountList, ServiceResult } from '../models';
import { UserService } from './user.service';

export class AppInstallationService {
    readonly appId = environment.installApp.appId;
    readonly appIntegrationURL =
        environment.workplace.workAPIBaseUrl + 'work/admin/apps?app_id=' + this.appId + '&state=' + 1;
    readonly appAuthorizationURL = environment.appSetting.chatbotUrl + 'install';
    readonly chatbotRedirectUrl = environment.workplace.workAPIBaseUrl + 'chat/t/';

    /**
     *  common function to set headers of request
     */
    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    /**
     * Used to check authorization of user for installing app in workplace account.
     * @param code authorization code provided by workplace api
     * @param accessToken user jwt access token
     * @returns application installation status with message.
     */
    async authorize(code: string) {
        return axios.post<ServiceResult<void>>(
            `${this.appAuthorizationURL}/${code}`,
            {},
            { headers: this.getHeaders() }
        );
    }

    /**
     * Used to open workplace app integration url in new tab.
     */
    installAppLink() {
        window.open(this.appIntegrationURL, '_blank')?.focus();
    }

    /**
     * Used to open workplace apps url in new tab.
     */
    goToAppLink() {
        window.open(this.appIntegrationURL, '_blank')?.focus();
    }

    /**
     * Used to open workplace paven chat page url in new tab.
     */
    chatWithPavenLink(chatbotId: string) {
        window.open(this.chatbotRedirectUrl + chatbotId, '_blank')?.focus();
    }

    isAppSuccessfullyInstalled(accountDataRes: IAccountList) {
        return (
            accountDataRes.appStatus === AppStatusEnum.Installed &&
            accountDataRes.source?.installStatus?.codeReceiveStatus === InstallProgressStatusEnum.Success &&
            accountDataRes.source?.installStatus?.accessTokenReceiveStatus === InstallProgressStatusEnum.Success &&
            accountDataRes.source?.installStatus?.domainWhitelistStatus === InstallProgressStatusEnum.Success
        );
    }
}
