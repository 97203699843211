import { Button, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { SnackBarVariants } from '../../../../app.enum';
import { ISnackBarFeature } from '../../../../models';
import { useSnackbar } from '../../../../providers/snackbar.provider';
import injector from '../../../../services';
import { IntlService } from '../../../../services/intl.service';
import { SharedService } from '../../../../services/shared.service';
import { UserService } from '../../../../services/user.service';
import CustomButton from '../../../../shared/custom-button.component';
import PasswordFormField from '../../../../shared/PasswordFormField/PasswordFormField';

interface IChangeAccountPasswordProps extends ISnackBarFeature {}

interface IChangeAccountPasswordFields {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const initialFormValues: IChangeAccountPasswordFields = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const intlService: IntlService = injector.get(IntlService);
const userService: UserService = injector.get(UserService);
const sharedService: SharedService = injector.get(SharedService);

const validationSchema = Yup.object({
    oldPassword: Yup.string().required(intlService.get('app.validation.oldPasswordRequired')),
    newPassword: Yup.string().required(intlService.get('app.validation.newPasswordRequired')),
    confirmPassword: Yup.string()
        .required(intlService.get('app.validation.confirmPasswordRequired'))
        .oneOf([Yup.ref('newPassword'), null], intlService.get('app.validation.confirmPasswordShouldMatchNewPassword')),
});

const ChangePassword: React.FC<IChangeAccountPasswordProps> = (props) => {
    const snackbar = useSnackbar();
    const formik = useFormik<IChangeAccountPasswordFields>({
        initialValues: initialFormValues,
        onSubmit: async (values) => {
            await handleOnSubmit(values);
        },
        validationSchema,
    });

    const handleOnSubmit = async (values: IChangeAccountPasswordFields) => {
        await resetPassword(values);
        return;
    };

    const hasError = (fieldName: string) => {
        const { value, initialValue, touched, error } = formik.getFieldMeta(fieldName);
        return (error && value !== initialValue) || touched || formik.isSubmitting ? true : false;
    };

    const resetPassword = useCallback(async (values: IChangeAccountPasswordFields) => {
        try {
            await userService.changeUserPassword({
                currentPassword: values.oldPassword,
                newPassword: values.newPassword,
            });

            formik.resetForm();
            snackbar.showSnackbar(intlService.get('app.message.passwordChangedSuccessfully'), SnackBarVariants.Success);
        } catch (err) {
            const errorMessage = sharedService.parseError(err);
            snackbar.showSnackbar(errorMessage, SnackBarVariants.Error);
        }
    }, []);

    return (
        <form autoComplete='off' style={{ width: '100%' }} onSubmit={formik.handleSubmit} data-testid='form' noValidate>
            <Grid container spacing={3} direction='column' justify='center' alignItems='center'>
                <Grid container item xs={12} direction='row' spacing={2}>
                    <Grid item xs={4}>
                        <PasswordFormField
                            id='oldPassword'
                            label={intlService.get('app.label.oldPassword')}
                            placeholder={intlService.get('app.label.oldPassword')}
                            value={formik.values.oldPassword}
                            handleChange={formik.handleChange}
                            hasError={hasError('oldPassword')}
                            errorMessage={formik.errors?.oldPassword}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction='row' spacing={2}>
                    <Grid item xs={4}>
                        <PasswordFormField
                            id='newPassword'
                            label={intlService.get('app.label.newPassword')}
                            placeholder={intlService.get('app.label.newPassword')}
                            value={formik.values.newPassword}
                            handleChange={formik.handleChange}
                            hasError={hasError('newPassword')}
                            errorMessage={formik.errors?.newPassword}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <PasswordFormField
                            id='confirmPassword'
                            label={intlService.get('app.label.confirmPassword')}
                            placeholder={intlService.get('app.label.confirmPassword')}
                            value={formik.values.confirmPassword}
                            handleChange={formik.handleChange}
                            hasError={hasError('confirmPassword')}
                            errorMessage={formik.errors?.confirmPassword}
                        />
                    </Grid>
                </Grid>
                <Grid container item direction='row' justify='flex-end' spacing={2}>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            className='light-red-color'
                            type='reset'
                            disabled={formik.isSubmitting}
                            onClick={() => {
                                formik.resetForm();
                            }}
                            data-testid='deleteButton'>
                            {intlService.get('app.button.clear')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            disabled={formik.isSubmitting}
                            loading={formik.isSubmitting}
                            className='primary-color'
                            data-testid='changePasswordButton'>
                            {intlService.get('app.button.update')}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ChangePassword;
