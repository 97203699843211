import {
    Avatar,
    Box,
    Checkbox,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { Dispatch, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import * as actions from '../actions/index';
import ForgotPassword from '../components/auth/ForgotPassword/ForgotPassword';
import environment from '../environments/environment';
import * as models from '../models/index';
import Injector from '../services/index';
import { IntlService } from '../services/intl.service';
import { UserService } from '../services/user.service';
import { Copyright } from '../shared/copyright/Copyright';
import CustomButton from '../shared/custom-button.component';

const intlService: IntlService = Injector.get(IntlService);
const userService: UserService = Injector.get(UserService);

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    recaptcha: {
        margin: theme.spacing(2, 5, 1),
        display:"flex",
        justifyContent:"center"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold',
    },
}));

interface LoginProps {
    setToken: any;
    onSuccess?: () => void;
    redirectTo?: string;
}

interface IAppDispatchToProps {
    onUserSignIn: (data: any) => void;
}
type ISignInFormFields = {
    email: string;
    password: string;
    isRememberMe: boolean;
};

const initialFormValues: ISignInFormFields = { email: '', password: '', isRememberMe: false };

const SigninSchema = Yup.object({
    email: Yup.string().required(intlService.get('app.validation.emailRequired')),
    password: Yup.string().required(intlService.get('app.validation.passwordRequired')),
    isRememberMe: Yup.boolean(),
});

const Login: React.FC<IAppDispatchToProps & LoginProps> = ({
    setToken,
    onUserSignIn,
    onSuccess = () => {},
    redirectTo = '/',
}): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const [isError, setIsError] = useState<boolean>(false);
    const [isInternalServerError, setIsInternalServerError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const recaptchaSiteKey = environment.recaptchaSiteKey;
    const [isRecaptchaTicked, setIsRecaptchaTicked] = useState<boolean>(false);
    const recaptchaRef=useRef<ReCAPTCHA>(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const handleSubmit = async (values: ISignInFormFields) => {
        setIsFormSubmitted(true);
        if (isRecaptchaTicked) {
            setIsError(false);
            setIsInternalServerError(false);
            try {
                const res = await userService.loginUser({
                    email: values.email,
                    password: values.password,
                });

                afterSignIn(res.data, values.isRememberMe);
            } catch (err: any) {
                // 'Network Error' is generate with an API is not available
                err.message === 'Network Error' ? setIsInternalServerError(true) : setIsError(true);
                recaptchaRef.current?.reset();
                setIsRecaptchaTicked(false);
                setIsFormSubmitted(false);
            }
        }
    };

    const afterSignIn = async (res: any, isRememberMe: boolean) => {
        setToken(res.accessToken);
        UserService.setUserData({ ...res, isAuthenticated: true }, isRememberMe);

        history.push(redirectTo);

        onUserSignIn({
            isAuthenticated: true,
            user: UserService.getUserData(),
        });

        onSuccess();
    };

    return (
        <>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        {intlService.get('app.label.signIn')}
                    </Typography>
                    <Formik initialValues={initialFormValues} onSubmit={handleSubmit} validationSchema={SigninSchema}>
                        {(formikProps: FormikProps<ISignInFormFields>) => (
                            <Form data-testid='signInForm' className={classes.form} noValidate={true}>
                                <Field
                                    name='email'
                                    inputProps={{ 'data-testid': 'email' }}
                                    label={intlService.get('app.label.email')}
                                    margin='normal'
                                    variant='outlined'
                                    component={TextField}
                                    value={formikProps.values.email}
                                    type='email'
                                    required={true}
                                    fullWidth={true}
                                    onChange={(e: React.ChangeEvent) => formikProps.handleChange('email')(e)}
                                    error={
                                        !!(
                                            (formikProps.values.email.length || formikProps.submitCount) &&
                                            !!formikProps.errors.email
                                        )
                                    }
                                    helperText={
                                        formikProps.values.email.length || formikProps.submitCount
                                            ? formikProps.errors.email
                                            : null
                                    }
                                />
                                <Field
                                    name='password'
                                    inputProps={{
                                        'data-testid': 'password',
                                    }}
                                    label={intlService.get('app.label.password')}
                                    margin='normal'
                                    variant='outlined'
                                    component={TextField}
                                    value={formikProps.values.password}
                                    type={showPassword ? 'text' : 'password'}
                                    required={true}
                                    fullWidth={true}
                                    onChange={(e: React.ChangeEvent) => formikProps.handleChange('password')(e)}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'input-notched-outline',
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    tabIndex={-1}
                                                    aria-label={intlService.get(
                                                        'app.ariaLabel.togglePasswordVisibility'
                                                    )}
                                                    onClick={() => setShowPassword((prevState) => !prevState)}
                                                    onMouseDown={(e) => e.preventDefault()}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        !!(
                                            (formikProps.values.password.length || formikProps.submitCount) &&
                                            !!formikProps.errors.password
                                        )
                                    }
                                    helperText={
                                        formikProps.values.password.length || formikProps.submitCount
                                            ? formikProps.errors.password
                                            : null
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name='isRememberMe'
                                            value={formikProps.values.isRememberMe}
                                            onChange={formikProps.handleChange}
                                            color='primary'
                                        />
                                    }
                                    label={intlService.get('app.label.rememberMe')}
                                />
                                {isError && (
                                    <div className={classes.error}>
                                        {intlService.get('app.error.invalidCredentials')}
                                    </div>
                                )}
                                {isInternalServerError && (
                                    <div className={classes.error}>
                                        {intlService.get('app.error.internalServerError')}
                                    </div>
                                )}
                                <Grid container>
                                    <Grid item xs>
                                        <Link to={`${routeMatch.path}?forgotPassword=true`}>
                                            {intlService.get('app.label.forgotPassword')}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <div className={classes.recaptcha}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={recaptchaSiteKey}
                                        onChange={(token) => token ? setIsRecaptchaTicked(true) : setIsRecaptchaTicked(false)}
                                    />
                                </div>
                                {isFormSubmitted && !isRecaptchaTicked && (
                                    <div className={classes.error}>{intlService.get('app.error.verifyRecaptcha')}</div>
                                )}
                                <CustomButton
                                    fullWidth
                                    disabled={formikProps.isSubmitting}
                                    loading={formikProps.isSubmitting}
                                    className={classes.submit}>
                                    {intlService.get('app.button.signIn')}
                                </CustomButton>
                            </Form>
                        )}
                    </Formik>
                </div>
                <Box mt={4}>
                    <Copyright />
                </Box>
                <Route exact path={routeMatch.path} component={ForgotPassword} />
            </Container>
        </>
    );
};

function mapDispatchToProps(dispatch: Dispatch<actions.AuthActions>): any {
    return {
        intl: null,
        openSnackBar: null,
        onUserSignIn: (data: models.IAppReduxAuthenticationState) => dispatch(actions.userSignedInAction(data)),
    };
}

export default connect(null, mapDispatchToProps)(Login);
