import axios from 'axios';
import { MessageSource } from '../app.enum';
import environment from '../environments/environment';
import { UserService } from './user.service';
const baseUrl = environment.appSetting.chatbotUrl;

export class WorkplaceSettingsService {
    /**
     *  common function to set headers of request
     */
    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    async getWhitelistedDomains(messageSource: MessageSource) {
        const url = `${baseUrl}system-config/whitelistedDomains`;
        return await axios.get<string[]>(`${url}/${messageSource}`, {
            headers: this.getHeaders(),
        });
    }

    async updateWhitelistedDomains(domains: string[], messageSource: MessageSource) {
        const url = `${baseUrl}system-config/whitelistedDomains`;
        return await axios.post<boolean>(
            `${url}/`,
            {
                domains,
                messageSource,
            },
            {
                headers: this.getHeaders(),
            }
        );
    }
}
