import { Button, Grid, Switch, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { DataGrid, GridPageChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { SnackBarVariants, StatusEnum, UserRole } from '../../app.enum';
import environment from '../../environments/environment';
import * as models from '../../models';
import { IKeywordList } from '../../models';
import { default as injector, default as Injector } from '../../services/index';
import { IntlService } from '../../services/intl.service';
import { KeywordService } from '../../services/keyword.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { getSearchQuery, removeSearchQuery } from '../../shared/header/SearchInput';
import withSnackBar from '../../shared/message.component';
import CustomDialogBox from './../../shared/dialog.component';
import KeywordForm from './keyword-form';

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
});

const intlService: IntlService = injector.get(IntlService);

interface IAppOwnProps {
    module: string | undefined;
    showMainHeader: boolean;
    onBackButtonClick: (close: boolean) => void;
    toggleSideBar: (close: boolean) => void;
}

const keywordService: KeywordService = Injector.get(KeywordService);
const sharedService: SharedService = Injector.get(SharedService);

class KeywordComponent extends React.Component<models.IKeywordListProps, models.IKeywordComponentState> {
    private keywordIdForAction: string = '';
    private keywordPublishedCountOfSelectedRow: number = 0;
    rowData: any;
    anchorEl: HTMLButtonElement | undefined;
    userAuthData: models.IUserSignInResponse;
    isSuperAdmin = false;
    axiosCancelTokenSource = axios.CancelToken.source();
    constructor(props: models.IKeywordListProps) {
        super(props);
        this.userAuthData = UserService.getUserData();
        this.isSuperAdmin = this.userAuthData.role === UserRole.Super;
        this.state = {
            records: [],
            nextPage: '',
            previousPage: '',
            pageSize:
                environment.uiSettings.pagination.rowsPerPageArray[
                    environment.uiSettings.pagination.defaultIndexOfRowsPerPageArray
                ],
            totalPages: 0,
            totalRecords: 0,
            currentPage: 0,
            isDisplayForm: false,
            isActionOpen: false,
            openDeleteConfirmationDialog: false,
            openChangeStatusConfirmationDialog: false,
            initialFormValues: {
                name: '',
            },
            columns: [
                {
                    field: 'accountName',
                    headerName: intlService.get('app.label.account'),
                    flex: 1.2,
                    disableColumnMenu: true,
                    sortable: false,
                    hide: !this.isSuperAdmin,
                    renderCell: (params: any) => (
                        <Tooltip title={params.row.account?.name}>
                            <span>{params.row.account?.name}</span>
                        </Tooltip>
                    ),
                },
                {
                    field: 'name',
                    headerName: intlService.get('app.label.name'),
                    flex: 1.2,
                    disableColumnMenu: true,
                },
                {
                    field: 'status',
                    headerName: intlService.get('app.label.active'),
                    flex: 1,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params: any) => {
                        return (
                            <React.Fragment>
                                <Switch
                                    checked={params.row.statusID === StatusEnum.Enabled}
                                    onClick={async (e) => {
                                        this.rowData = params.row;
                                        this.keywordIdForAction = params.row.id;
                                        await this.setState({
                                            openChangeStatusConfirmationDialog: true,
                                        });
                                    }}
                                    color='primary'
                                    name='checkedB'
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            </React.Fragment>
                        );
                    },
                },
                {
                    field: 'publishedCount',
                    headerClassName: 'hide-last-separator',
                    // cellClassName : 'action-column',
                    headerName: intlService.get('app.label.publishedCount'),
                    flex: 1,
                    disableColumnMenu: true,
                },
                {
                    field: 'createdDate',
                    headerClassName: 'hide-last-separator',
                    // cellClassName : 'action-column',
                    headerName: intlService.get('app.label.createdDate'),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: (params: any) => (
                        <React.Fragment>{sharedService.formatDate(params.row.createdDate)}</React.Fragment>
                    ),
                },
                {
                    flex: 0.3,
                    headerClassName: 'action-header-column',
                    cellClassName: 'action-column',
                    field: 'action',
                    headerName: intlService.get('app.label.action'),
                    disableColumnMenu: true,
                    sortable: false,
                    disableClickEventBubbling: true,
                    renderCell: (params: any) => (
                        <React.Fragment>
                            <IconButton
                                onClick={async (e) => {
                                    this.keywordIdForAction = params.row.id;
                                    this.keywordPublishedCountOfSelectedRow = params.row.publishedCount;
                                    this.anchorEl = e.currentTarget;
                                    await this.setState({
                                        isActionOpen: true,
                                        columns: [...this.state.columns],
                                    });
                                }}
                                edge='start'
                                color='inherit'
                                aria-label={intlService.get('app.label.action')}>
                                <MoreVertIcon className='create-icon-color' />
                            </IconButton>
                            <Menu
                                id='fade-menu'
                                // elevation is used for box Shadow issue bug WPI-295
                                elevation={1}
                                anchorEl={this.anchorEl}
                                keepMounted
                                open={this.state.isActionOpen}
                                onClose={async () => {
                                    await this.setState({
                                        isActionOpen: false,
                                        columns: [...this.state.columns],
                                    });
                                }}>
                                <MenuItem
                                    disabled={this.keywordPublishedCountOfSelectedRow > 0}
                                    onClick={async () => {
                                        await this.setState({
                                            openDeleteConfirmationDialog: true,
                                            isActionOpen: false,
                                            columns: [...this.state.columns],
                                        });
                                    }}>
                                    {intlService.get('app.button.delete')}
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    ),
                },
            ],
            sortModel: [{ field: 'createdDate', sort: 'desc' }],
        };
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public async componentDidUpdate(prevProps: models.IKeywordListProps) {
        const { searchQuery, isSearchQueryChanged } = getSearchQuery(prevProps, this.props);

        if (isSearchQueryChanged) {
            await this.loadData(searchQuery);
        }
    }

    public componentWillUnmount() {
        this.axiosCancelTokenSource.cancel();
    }

    deleteKeyword = async (id: string) => {
        await keywordService
            .deleteKeyword(id, this.axiosCancelTokenSource.token)
            .then(async (res) => {
                await this.loadData();
                this.openSnackbar(intlService.get('app.message.keywordDeletedSuccessfully'), SnackBarVariants.Success);
            })
            .catch((err: AxiosError) => {
                // check if error is not from axios cancellation
                !axios.isCancel(err) && 
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    private loadData = async (searchQuery?: string) => {
        const queryParam = {
            size: this.state.pageSize,
            page: this.state.currentPage,
            sortBy: this.state.sortModel[0]?.field,
            sortingOrder: this.state.sortModel[0]?.sort,
            accountId: !this.isSuperAdmin ? UserService.getUserData()?.accountId : '',
            searchQuery: searchQuery,
        };
        await keywordService
            .getKeywordList(queryParam, this.axiosCancelTokenSource.token)
            .then(async (res) => {
                await this.setState({
                    records: res?.data?.records,
                    totalRecords: res?.data?.totalRecords,
                });
            })
            .catch((err: AxiosError) => {
                // check if error is not from axios cancellation
                !axios.isCancel(err) &&
                 this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    saveKeyword = async (e: any) => {
        const obj = {
            name: e.name,
            accountId: this.isSuperAdmin ? e.accountId : this.userAuthData.accountId,
        };

        await keywordService
            .createKeyword(obj, this.axiosCancelTokenSource.token)
            .then(async (res) => {
                this.setState({isDisplayForm:false});
                await this.loadData();
                this.openSnackbar(intlService.get('app.message.keywordAddedSuccessfully'), SnackBarVariants.Success);
            })
            .catch((err: any) => {
                // check if error is not from axios cancellation
                if (!axios.isCancel(err)) {
                    const errorMessage = sharedService.parseError(err);
                    this.openSnackbar(errorMessage, SnackBarVariants.Error);
                }
            });
    };

    handlePageChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: params.page,
        });
        await this.loadData();
    };
    handlePageSizeChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: 0,
        });
        await this.loadData();
    };
    handleSortModelChange = async (params: GridSortModelParams) => {
        await this.setState({
            sortModel: params.sortModel,
        });
        const {searchQuery}=getSearchQuery(this.props, this.props);
        if(searchQuery){
            await this.loadData(searchQuery);
        }else{
            await this.loadData();
        }
    };

    displayList = () => {
        return (
            <div className='table-view'>
                <DataGrid
                    rows={this.state.records}
                    columns={this.state.columns}
                    pagination
                    rowsPerPageOptions={environment.uiSettings.pagination.rowsPerPageArray}
                    onPageSizeChange={this.handlePageSizeChange}
                    pageSize={this.state.pageSize}
                    rowCount={this.state.totalRecords}
                    paginationMode='server'
                    onPageChange={this.handlePageChange}
                    sortingMode='server'
                    sortModel={this.state.sortModel}
                    onSortModelChange={this.handleSortModelChange}
                    hideFooterSelectedRowCount={true}
                    disableSelectionOnClick={true}
                    page={this.state.currentPage}
                />
            </div>
        );
    };

    public render() {
        return (
            <>
                <Grid item={true}>
                    <Button
                        data-testid='add-btn'
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            await this.setState({
                                isDisplayForm: true,
                            });
                        }}
                        className='add-button-table primary-color '
                        startIcon={<AddIcon />}>
                        {intlService.get('app.button.add')}
                    </Button>
                </Grid>
                {this.displayList()}
                {this.state.isDisplayForm && (
                    <KeywordForm
                        open={this.state.isDisplayForm}
                        prefilledFormValues={this.state.initialFormValues}
                        onSubmit={(formValues) => this.saveKeyword(formValues)}
                        onClose={() => {
                            this.setState({ isDisplayForm: false });
                        }}
                    />
                )}

                <CustomDialogBox
                    open={this.state.openDeleteConfirmationDialog}
                    dialogTitle={intlService.get('app.dialog.title.deleteKeyword')}
                    dialogDescription={intlService.get('app.message.deleteConfirmation')}
                    closeCustomDialog={this.closeDeleteDialog}
                />

                <CustomDialogBox
                    open={
                        this.state.openChangeStatusConfirmationDialog && !(this.rowData.statusID === StatusEnum.Enabled)
                    }
                    dialogTitle={intlService.get('app.dialog.title.activateKeyword')}
                    dialogDescription={intlService.get('app.message.activateKeywordConfirmation')}
                    closeCustomDialog={this.closeChangeStatusDialog}
                />
                <CustomDialogBox
                    open={this.state.openChangeStatusConfirmationDialog && this.rowData.statusID === StatusEnum.Enabled}
                    dialogTitle={intlService.get('app.dialog.title.deactivateKeyword')}
                    dialogDescription={intlService.get('app.message.deactivateKeywordConfirmation')}
                    closeCustomDialog={this.closeChangeStatusDialog}
                />
            </>
        );
    }

    private closeChangeStatusDialog = async (dialogTitle: string, isCancel: boolean) => {
        if (!isCancel) {
            this.changeStatus(this.keywordIdForAction, {
                statusID: this.rowData.statusID === StatusEnum.Enabled ? StatusEnum.Disabled : StatusEnum.Enabled,
            });
            isCancel = true;
        }
        this.keywordIdForAction = '';
        await this.setState({
            openChangeStatusConfirmationDialog: false,
        });
    };
    private closeDeleteDialog = async (dialogTitle: string, isCancel: boolean) => {
        if (!isCancel) {
            this.deleteKeyword(this.keywordIdForAction);
            isCancel = true;
        }
        this.keywordIdForAction = '';
        await this.setState({
            openDeleteConfirmationDialog: false,
        });
    };

    changeStatus = async (id: string, keywordData: IKeywordList) => {
        await keywordService
            .updateKeywordStatus(id, keywordData, this.axiosCancelTokenSource.token)
            .then(async (res) => {
                removeSearchQuery(this.props);
                await this.loadData();
                if (keywordData.statusID === StatusEnum.Enabled) {
                    this.openSnackbar(
                        intlService.get('app.message.keywordActivatedSuccessfully'),
                        SnackBarVariants.Success
                    );
                } else {
                    this.openSnackbar(
                        intlService.get('app.message.keywordDeactivatedSuccessfully'),
                        SnackBarVariants.Success
                    );
                }
            })
            .catch((err: AxiosError) => {
                // check if error is not from axios cancellation
                !axios.isCancel(err) &&
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };
    private openSnackbar = (message: string | string[], variantName: SnackBarVariants = SnackBarVariants.Success) => {
        this.props.openSnackBar!(message, variantName);
    };
}

export default withSnackBar(withStyles(styles)(withRouter(KeywordComponent)));
