import { AxiosError } from 'axios';
import { isNaN } from 'formik';
import Moment from 'moment';
import { StatusCodes } from '../app.enum';
import { Global } from '../app.global';
import injector from './index';
import { IntlService } from './intl.service';
export class SharedService {
    private readonly intlService: IntlService = injector.get(IntlService);

    formatDate(date: Date) {
        return Moment(date).format('ll');
    }

    /**
     * Parse the provided string return Integer
     * @param: id String to Parse
     */
    public parseInt(id: any) {
        const res = id ? parseInt(id.toString(), 10) : (null as any);
        return res;
    }

    /**
     * Convert enum to object of type { name: string; value: string }[]
     * @param enumVar Enum
     */
    public enumToObjectArray(enumVar: any): { name: string; value: string }[] {
        const objectArray: { name: string; value: string }[] = [];

        for (const n in enumVar) {
            if (enumVar.hasOwnProperty(n) && isNaN(this.parseInt(n))) {
                objectArray.push({
                    name: n,
                    value: <any>enumVar[n],
                });
            }
        }
        return objectArray;
    }

    getEnumKeyByValue(enumObj: any, value: any) {
        const enumArray = this.enumToObjectArray(enumObj);
        const selectedEnum = enumArray.find((item) => JSON.stringify(item.value) === JSON.stringify(value));
        return selectedEnum ? selectedEnum.name : '';
    }

    /** Parse Axios Error and return error message using intl.
     * In case of on error message return internal server error as default.
     * @param err error thrown by axios request
     * @returns error message to show on ui i.e. Snackbar
     */
    parseError(err: AxiosError): string {
        if (err?.response?.data) {
            const data = err.response.data;
            if (data?.message) {
                const errorMessage = this.intlService.getHTML('app.error.' + data.message);
                return !!errorMessage ? errorMessage : this.getErrMsgFromStatusCode(err);
            }
        }
        return this.getErrMsgFromStatusCode(err);
    }

    private getErrMsgFromStatusCode(err: AxiosError<any>) {
        switch (err?.response?.status) {
            case StatusCodes.Status401Unauthorized:
            case StatusCodes.Status403Forbidden:
                return this.intlService.get('app.error.unauthorized');
            case StatusCodes.Status400BadRequest:
                return this.intlService.get('app.error.badRequest');                
        }

        return this.intlService.get('app.error.internalServerError');
    }

    /**
     * Convert Enum to Array of name and value
     * @param value enum to change to array
     * @returns return array with name and value of enum
     */
    enumToArray(value: any) {
        const keys = Object.keys(value);
        return keys
            .filter((key) => !isNaN(parseInt(key)))
            .map((key: string) => ({ name: value[parseInt(key)], value: parseInt(key) }));
    }

    /**
     * Extract domain name from url
     * @param url url from which domain to be extract
     * @returns domain name without any https protocol and www
     */
    static extractDomainName(url: string) {
        var patterns = ['https://www.', 'https://', 'www.'];

        for (var i = 0, len = patterns.length; i < len; i++) {
            var pattern = patterns[i];
            if (url.indexOf(pattern) == 0) {
                return url.substring(pattern.length);
            }
        }
        return url;
    }
}
