import {
    Button,
    createStyles,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Theme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { OperationEnum } from '../../app.enum';
import { regex } from '../../constants';
import environment from '../../environments/environment';
import { ICountry, ICountryState } from '../../models';
import { IAccountFormFields } from '../../models/account.models';
import injector from '../../services';
import { IntlService } from '../../services/intl.service';
import CustomButton from '../../shared/custom-button.component';
import FullScreenDialog from '../../shared/full-screen-dialog/full-screen-dialog';

interface IAccountFormProps {
    open: boolean;
    onSubmit: (formValues: IAccountFormFields) => void;
    onClose: () => void;
    stateList: ICountryState[];
    countryList: ICountry[];
    onCountryChange: (countryCode: string) => void;
    prefilledFormValues?: IAccountFormFields;
    operation?: OperationEnum;
}

export const initialFormValues: IAccountFormFields = {
    name: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    adminEmail: '',
    groupPostWaitWindow: environment.accountSettings.groupPostWaitWindow,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: { backgroundColor: 'white', padding: '0px 4px' },
    })
);

const intlService: IntlService = injector.get(IntlService);

const AccountForm: React.FC<IAccountFormProps> = (props) => {
    const classes = useStyles();

    const formValidationSchema = Yup.object().shape<any>({
        name: Yup.string().required(intlService.get('app.validation.nameRequired')),
        ...(props.operation === OperationEnum.Add
            ? {
                  adminEmail: Yup.string()
                      .required(intlService.get('app.validation.adminEmailRequired'))
                      .matches(regex.email, intlService.get('app.validation.invalidEmail')),
              }
            : {}),
        address1: Yup.string(),
        address2: Yup.string(),
        address3: Yup.string(),

        country: Yup.string(),
        state: Yup.string(),
        city: Yup.string().matches(
            regex.cityName,
            intlService.get('app.validation.mustBeAllAlphabets').replace('{0}', intlService.get('app.label.city'))
        ),
        postalCode: Yup.string(),
        groupPostWaitWindow: Yup.number()
            .label(intlService.get('app.label.groupPostWaitWindowInMinutes'))
            .min(0)
            .required(),
    });

    const formik = useFormik<IAccountFormFields>({
        initialValues: props.prefilledFormValues ?? initialFormValues,
        validationSchema: formValidationSchema,
        onSubmit: async (formValues) => {
            await props.onSubmit(formValues);
        },
    });

    const handleClose = () => {
        formik.setValues(initialFormValues);
        props.onClose();
    };

    return (
        <FullScreenDialog open={props.open} onClose={handleClose} title={intlService.get('app.component.accounts')}>
            <form onSubmit={formik.handleSubmit} className='add-edit-form h-100' noValidate={true}>
                <Grid container={true} direction='row' justify='flex-end' className='h-100 pt-15'>
                    <Grid container={true} justify='flex-start' spacing={2}>
                        <Grid item sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='name'
                                label={intlService.get('app.label.name')}
                                variant='outlined'
                                type='text'
                                fullWidth={true}
                                required={true}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={!!((formik.values.name.length || formik.submitCount) && !!formik.errors.name)}
                                helperText={formik.values.name.length || formik.submitCount ? formik.errors.name : null}
                            />
                        </Grid>
                        {props.operation === OperationEnum.Add ? (
                            <Grid item sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    name='adminEmail'
                                    label={intlService.get('app.label.adminEmail')}
                                    variant='outlined'
                                    type='text'
                                    fullWidth={true}
                                    required={true}
                                    value={formik.values.adminEmail}
                                    onChange={formik.handleChange}
                                    error={
                                        !!(
                                            (formik.values.adminEmail?.length || formik.submitCount) &&
                                            !!formik.errors.adminEmail
                                        )
                                    }
                                    helperText={
                                        formik.values.adminEmail?.length || formik.submitCount
                                            ? formik.errors.adminEmail
                                            : null
                                    }
                                />
                            </Grid>
                        ) : null}

                        <Grid item sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='address1'
                                label={intlService.get('app.label.address1')}
                                variant='outlined'
                                type='text'
                                fullWidth={true}
                                value={formik.values.address1}
                                onChange={formik.handleChange}
                                error={
                                    !!(
                                        (formik.values.address1?.length || formik.submitCount) &&
                                        !!formik.errors.address1
                                    )
                                }
                                helperText={
                                    formik.values.address1?.length || formik.submitCount ? formik.errors.address1 : null
                                }
                            />
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='address2'
                                variant='outlined'
                                label={intlService.get('app.label.address2')}
                                type='text'
                                fullWidth={true}
                                value={formik.values.address2}
                                onChange={formik.handleChange}
                                error={
                                    !!(
                                        (formik.values.address2?.length || formik.submitCount) &&
                                        !!formik.errors.address2
                                    )
                                }
                                helperText={
                                    formik.values.address2?.length || formik.submitCount ? formik.errors.address2 : null
                                }
                            />
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='address3'
                                variant='outlined'
                                label={intlService.get('app.label.address3')}
                                type='text'
                                fullWidth={true}
                                value={formik.values.address3}
                                onChange={formik.handleChange}
                                error={
                                    !!(
                                        (formik.values.address3?.length || formik.submitCount) &&
                                        !!formik.errors.address3
                                    )
                                }
                                helperText={
                                    formik.values.address3?.length || formik.submitCount ? formik.errors.address3 : null
                                }
                            />
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel
                                    shrink
                                    className={classes.inputLabel}
                                    id='country-select-placeholder-label-label'>
                                    {intlService.get('app.label.country')}
                                </InputLabel>
                                <Select
                                    labelId='country-select-placeholder-label-label'
                                    name='country'
                                    label={intlService.get('app.label.country')}
                                    value={formik.values.country ? formik.values.country : ''}
                                    displayEmpty
                                    onChange={(e: React.ChangeEvent<any>) => {
                                        formik.handleChange('country')(e);

                                        props.onCountryChange(e.target.value);
                                        formik.setFieldValue('state', '');
                                    }}
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return props.countryList.length
                                                ? props.countryList.find((x: any) => x.code === selected)?.name
                                                : '';
                                        }
                                        return `${intlService.get('app.message.select')} ${intlService.get(
                                            'app.label.country'
                                        )}`;
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: '550px',
                                            },
                                        },
                                    }}
                                    error={
                                        !!(
                                            (formik.values.country?.length || formik.submitCount) &&
                                            !!formik.errors.country
                                        )
                                    }>
                                    <MenuItem value=''>
                                        {intlService.get('app.message.select')} {intlService.get('app.label.country')}
                                    </MenuItem>
                                    {props.countryList.map((e: any) => {
                                        return (
                                            <MenuItem key={e.code} value={e.code}>
                                                <ListItemText primary={e.name} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {!!(
                                    (formik.values.country?.length || formik.submitCount) &&
                                    !!formik.errors.country
                                ) && <FormHelperText error={true}>{formik.errors.country}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel
                                    shrink
                                    className={classes.inputLabel}
                                    id='state-select-placeholder-label-label'>
                                    {intlService.get('app.label.state')}
                                </InputLabel>
                                <Select
                                    labelId='state-select-placeholder-label-label'
                                    name='state'
                                    label={intlService.get('app.label.state')}
                                    disabled={!formik.values.country}
                                    value={formik.values.state ? formik.values.state : ''}
                                    displayEmpty
                                    onChange={formik.handleChange}
                                    renderValue={(selected) => {
                                        if (selected) {
                                            return props.stateList.length
                                                ? props.stateList.find((x: any) => x.code === selected)?.name
                                                : '';
                                        }
                                        return `${intlService.get('app.message.select')} ${intlService.get(
                                            'app.label.state'
                                        )}`;
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: '550px',
                                            },
                                        },
                                    }}
                                    error={
                                        !!((formik.values.state?.length || formik.submitCount) && !!formik.errors.state)
                                    }>
                                    <MenuItem value=''>
                                        {intlService.get('app.message.select')} {intlService.get('app.label.state')}
                                    </MenuItem>
                                    {props.stateList.map((e: any) => {
                                        return (
                                            <MenuItem key={e.code} value={e.code}>
                                                <ListItemText primary={e.name} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {!!((formik.values.state?.length || formik.submitCount) && !!formik.errors.state) && (
                                    <FormHelperText error={true}>{formik.errors.state}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='city'
                                variant='outlined'
                                label={intlService.get('app.label.city')}
                                type='text'
                                fullWidth={true}
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                error={!!((formik.values.city?.length || formik.submitCount) && !!formik.errors.city)}
                                helperText={
                                    formik.values.city?.length || formik.submitCount ? formik.errors.city : null
                                }
                            />
                        </Grid>
                        <Grid item={true} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='postalCode'
                                variant='outlined'
                                label={intlService.get('app.label.postalCode')}
                                type='text'
                                fullWidth={true}
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                error={
                                    !!(
                                        (formik.values.postalCode?.length || formik.submitCount) &&
                                        !!formik.errors.postalCode
                                    )
                                }
                                helperText={
                                    formik.values.postalCode?.length || formik.submitCount
                                        ? formik.errors.postalCode
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                name='groupPostWaitWindow'
                                label={intlService.get('app.label.groupPostWaitWindowInMinutes')}
                                variant='outlined'
                                type='number'
                                fullWidth={true}
                                required={true}
                                value={formik.values.groupPostWaitWindow}
                                onChange={formik.handleChange}
                                error={
                                    !!(
                                        (formik.values.groupPostWaitWindow || formik.submitCount) &&
                                        !!formik.errors.groupPostWaitWindow
                                    )
                                }
                                helperText={
                                    formik.values.groupPostWaitWindow || formik.submitCount
                                        ? formik.errors.groupPostWaitWindow
                                        : null
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid alignItems='flex-end' container>
                        <Grid container xs={6} justify='flex-start' justify-content='flex-start' spacing={2}>
                            <Grid xs={6} item={true}>
                                <Button
                                    type='button'
                                    disabled={formik.isSubmitting}
                                    onClick={async () => {
                                        handleClose();
                                    }}>
                                    {intlService.get('app.button.back')}
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container xs={true} justify='flex-end' spacing={2}>
                            <Grid item={true}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className='light-red-color'
                                    type='reset'
                                    disabled={formik.isSubmitting}
                                    onClick={() => {
                                        formik.setValues(initialFormValues);
                                    }}>
                                    {intlService.get('app.button.clear')}
                                </Button>
                            </Grid>
                            <Grid item={true}>
                                <CustomButton
                                    disabled={formik.isSubmitting}
                                    loading={formik.isSubmitting}
                                    className='primary-color'>
                                    {intlService.get('app.button.save')}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FullScreenDialog>
    );
};

export default AccountForm;
