import axios, { AxiosResponse } from 'axios';
import environment from '../environments/environment';
import { IDashboardAuthCounterDto, IDashboardCounterDto } from '../models';
import { UserService } from './user.service';

export class DashboardService {
    dashboardAuthUrl = environment.appSetting.baseUrl + 'dashboard/';
    dashboardchatUrl = environment.appSetting.chatbotUrl + 'dashboard/';

    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    async getDashboardCountersFromChat(): Promise<AxiosResponse<IDashboardCounterDto>> {
        const accountUrl = this.dashboardchatUrl + 'counters';
        return axios.get<IDashboardCounterDto>(accountUrl, {
            params: {},
            headers: this.getHeaders(),
        });
    }

    async getDashboardCounterDataFromAuth(): Promise<AxiosResponse<IDashboardAuthCounterDto>> {
        const dashboardUrl = this.dashboardAuthUrl + 'counters';

        return axios.get<IDashboardAuthCounterDto>(dashboardUrl, {
            params: {},
            headers: this.getHeaders(),
        });
    }
}
