import { Button, CardMedia, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AxiosError } from 'axios';
import React from 'react';
import { withRouter } from 'react-router';
import { DashboardButtons, SnackBarVariants, UserRole } from '../app.enum';
import dashboardImage from '../assets/images/dashboard.png';
import { DashboardService } from '../services/dashboard.service';
import Injector from '../services/index';
import { IntlService } from '../services/intl.service';
import { SharedService } from '../services/shared.service';
import { UserService } from '../services/user.service';
import withSnackBar from '../shared/message.component';
import * as models from './../models/index';

const styles = (theme: any) => ({
    root: {},
    numberText: {
        fontSize: 14,
    },
    containerCard: {
        height: 400,
        paddingLeft: '8vw',
        paddingRight: '8vw',
    },
    subContainerCard: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    cardBorderRadius: {
        borderRadius: 11,
    },
    messagesProcessedText: {
        paddingTop: 6,
        display: 'inline-block',
    },
});

const intlService: IntlService = Injector.get(IntlService);
const dashboardService: DashboardService = Injector.get(DashboardService);
const sharedService: SharedService = Injector.get(SharedService);

class DashboardComponent extends React.Component<models.IKeywordListProps, models.IDashboardComponentState> {
    userAuthData = UserService.getUserData();

    constructor(props: models.IKeywordListProps) {
        super(props);

        this.state = {
            userCount: 0,
            accountCount: 0,
            userSentMsgCount: 0,
            chatbotRepliedMsgCount: 0,
            keywordCounter: 0,
            messagesProcessed: 0,
            selectedButton: DashboardButtons.Total,
        };
    }

    public render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container={true} direction='row' justify='center' alignItems='center' className='pt-15 h-100'>
                    <Grid container={true} justify='flex-start' className={classes.containerCard} spacing={2}>
                        <Grid item className={classes.subContainerCard} sm={6} md={6} lg={6} xl={6}>
                            <Grid item>
                                <Card
                                    className={[
                                        classes.root,
                                        'card-with-bg',
                                        'card-without-shadow',
                                        classes.cardBorderRadius,
                                    ].join(' ')}>
                                    <CardContent>
                                        <Typography variant='h5'>{this.state.messagesProcessed}</Typography>
                                        <Typography variant='caption' display='block' gutterBottom>
                                            <span className={classes.messagesProcessedText}>
                                                {intlService.get('app.label.messagesProcessed')}
                                            </span>

                                            <span className={'float-right'}>
                                                <span>
                                                    <Button
                                                        className={
                                                            this.state.selectedButton === DashboardButtons.Total
                                                                ? 'theme-color-button'
                                                                : ''
                                                        }
                                                        size='small'
                                                        onClick={async () => {
                                                            await this.setState({
                                                                messagesProcessed:
                                                                    this.state.userSentMsgCount +
                                                                    this.state.chatbotRepliedMsgCount,
                                                                selectedButton: DashboardButtons.Total,
                                                            });
                                                        }}>
                                                        {' '}
                                                        {intlService.get('app.label.total')}
                                                    </Button>
                                                </span>
                                                <Button
                                                    size='small'
                                                    className={
                                                        this.state.selectedButton === DashboardButtons.Incoming
                                                            ? 'theme-color-button'
                                                            : ''
                                                    }
                                                    onClick={async () => {
                                                        await this.setState({
                                                            messagesProcessed: this.state.userSentMsgCount,
                                                            selectedButton: DashboardButtons.Incoming,
                                                        });
                                                    }}>
                                                    {intlService.get('app.label.incoming')}
                                                </Button>
                                                <Button
                                                    size='small'
                                                    className={
                                                        this.state.selectedButton === DashboardButtons.Outgoing
                                                            ? 'theme-color-button'
                                                            : ''
                                                    }
                                                    onClick={async () => {
                                                        await this.setState({
                                                            messagesProcessed: this.state.chatbotRepliedMsgCount,
                                                            selectedButton: DashboardButtons.Outgoing,
                                                        });
                                                    }}>
                                                    {' '}
                                                    {intlService.get('app.label.outgoing')}
                                                </Button>
                                            </span>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item className='pt-20'>
                                <Grid container={true} direction='row' justify='flex-end'>
                                    <Grid container={true} justify='flex-start' spacing={8}>
                                        {this.userAuthData.role === UserRole.Super ? (
                                            <Grid item sm={12} md={4} lg={4} xl={4}>
                                                <Card
                                                    className={[
                                                        classes.root,
                                                        'card-with-bg',
                                                        'card-without-shadow',
                                                        classes.cardBorderRadius,
                                                    ].join(' ')}>
                                                    <CardContent>
                                                        <Typography variant='h5'>{this.state.accountCount}</Typography>
                                                        <Typography variant='caption' display='block' gutterBottom>
                                                            {intlService.get('app.label.accounts')}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ) : (
                                            ''
                                        )}
                                        <Grid item sm={12} md={4} lg={4} xl={4}>
                                            <Card
                                                className={[
                                                    classes.root,
                                                    'card-with-bg',
                                                    'card-without-shadow',
                                                    classes.cardBorderRadius,
                                                ].join(' ')}>
                                                <CardContent>
                                                    <Typography variant='h5'>{this.state.userCount}</Typography>
                                                    <Typography variant='caption' display='block' gutterBottom>
                                                        {intlService.get('app.label.users')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item sm={12} md={4} lg={4} xl={4}>
                                            <Card
                                                className={[
                                                    classes.root,
                                                    'card-with-bg',
                                                    'card-without-shadow',
                                                    classes.cardBorderRadius,
                                                ].join(' ')}>
                                                <CardContent>
                                                    <Typography variant='h5'>{this.state.keywordCounter}</Typography>
                                                    <Typography variant='caption' display='block' gutterBottom>
                                                        {intlService.get('app.label.keywords')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} xl={6}>
                            <Card className={'card-without-shadow h-100'}>
                                <CardMedia className={'h-100'} image={dashboardImage} />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    public async componentDidMount() {
        await this.loadCounterData();
    }

    private loadCounterData = async () => {
        dashboardService
            .getDashboardCountersFromChat()
            .then(async (res) => {
                await this.setState({
                    keywordCounter: res.data?.keywordCounter || 0,
                });
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });

        dashboardService
            .getDashboardCounterDataFromAuth()
            .then(async (res) => {
                this.setState({
                    userCount: res.data.userCount || 0,
                    accountCount: res.data.accountCount || 0,
                    userSentMsgCount: res.data.userSentMsgCount || 0,
                    chatbotRepliedMsgCount: res.data.chatbotRepliedMsgCount || 0,
                    messagesProcessed: (res.data.userSentMsgCount || 0) + (res.data.chatbotRepliedMsgCount || 0),
                });
            })
            .catch((err: AxiosError) => {
                this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    private openSnackbar = (message: string | string[], variantName: SnackBarVariants = SnackBarVariants.Success) => {
        this.props.openSnackBar!(message, variantName);
    };
}

export default withSnackBar(withStyles(styles)(withRouter(DashboardComponent)));
