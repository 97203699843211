import { Avatar, Container, CssBaseline, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import environment from '../../../environments/environment';
import { useSnackbar } from '../../../providers/snackbar.provider';
import injector from '../../../services';
import { IntlService } from '../../../services/intl.service';
import { SharedService } from '../../../services/shared.service';
import { UserService } from '../../../services/user.service';
import CustomButton from '../../../shared/custom-button.component';
import PasswordFormField from '../../../shared/PasswordFormField/PasswordFormField';

const intlService: IntlService = injector.get(IntlService);
const userService: UserService = injector.get(UserService);
const sharedService: SharedService = injector.get(SharedService);

export interface IResetPasswordFormFields {
    uuid: string;
    email: string;
    newPassword: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    recaptcha: {
        margin: theme.spacing(2, 6, 1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold',
    },
}));

const validationSchema = yup.object({
    newPassword: yup.string().required(intlService.get('app.validation.newPasswordRequired')),
    confirmPassword: yup
        .string()
        .required(intlService.get('app.validation.confirmPasswordRequired'))
        .oneOf([yup.ref('newPassword'), null], intlService.get('app.validation.confirmPasswordShouldMatchNewPassword')),
});

const ResetPassword: React.FC = () => {
    const axiosCancelTokenSource = axios.CancelToken.source();
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();
    const recaptchaSiteKey = environment.recaptchaSiteKey;
    const [isRecaptchaTicked, setIsRecaptchaTicked] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmiited] = useState<boolean>(false);
    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
            uuid: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values: IResetPasswordFormFields) => {
            if (isRecaptchaTicked) {
                await resetPassword(values);
            }
            setIsFormSubmiited(true);
        },
    });

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        if (!params.get('uuid') && !params.get('email')) {
            history.push('/');
        } else {
            formik.setFieldValue('uuid', params.get('uuid'));
            formik.setFieldValue('email', params.get('email'));
        }
    }, [history.location.search]);

    const hasError = (fieldName: string) => {
        const { value, initialValue, touched, error } = formik.getFieldMeta(fieldName);
        return (error && value !== initialValue) || touched || formik.isSubmitting ? true : false;
    };

    const resetPassword = useCallback(async (values: IResetPasswordFormFields) => {
        try {
            await userService.resetPassword(values, axiosCancelTokenSource.token);
            formik.resetForm();
            history.push('/');
            snackbar.showSnackbar(intlService.get('app.message.resetPasswordSuccess'));
        } catch (err) {
            if (!axios.isCancel(err)) {
                const errorMessage = sharedService.parseError(err);
                snackbar.showSnackbar(errorMessage, 'error');
            }
        }
    }, []);

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component='h1' variant='h5' className='pb-10' data-testid='component-title'>
                    {intlService.get('app.component.resetPassword')}
                </Typography>
                <form
                    autoComplete='off'
                    style={{ width: '100%' }}
                    onSubmit={formik.handleSubmit}
                    className={classes.form}
                    aria-label='form'
                    noValidate>
                    <input type='hidden' name='uuid' placeholder='uuid' defaultValue={formik.values.uuid} />
                    <input
                        type='hidden'
                        name='email'
                        placeholder={intlService.get('app.label.email')}
                        defaultValue={formik.values.email}
                    />
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        id='newPassword'
                                        label={intlService.get('app.label.newPassword')}
                                        placeholder={intlService.get('app.label.newPassword')}
                                        value={formik.values.newPassword}
                                        handleChange={formik.handleChange}
                                        hasError={hasError('newPassword')}
                                        errorMessage={formik.errors?.newPassword}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        id='confirmPassword'
                                        label={intlService.get('app.label.confirmPassword')}
                                        placeholder={intlService.get('app.label.confirmPassword')}
                                        value={formik.values.confirmPassword}
                                        handleChange={formik.handleChange}
                                        hasError={hasError('confirmPassword')}
                                        errorMessage={formik.errors?.confirmPassword}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.recaptcha}>
                                        <ReCAPTCHA
                                            sitekey={recaptchaSiteKey}
                                            onChange={(token) => token ? setIsRecaptchaTicked(true) : setIsRecaptchaTicked(false)}
                                        />
                                    </div>
                                    {isFormSubmitted && !isRecaptchaTicked && (
                                        <div className={classes.error}>
                                            {intlService.get('app.error.verifyRecaptcha')}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomButton
                                        fullWidth
                                        disabled={formik.isSubmitting}
                                        loading={formik.isSubmitting}
                                        className='primary-color'>
                                        {intlService.get('app.button.resetPassword')}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default ResetPassword;
