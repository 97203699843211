export const dateFormatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: '2-digit',
};

export const regex = Object.freeze({
    cityName: new RegExp("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"),
    email: /^([ \t]*)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}([ \t]*)$/, //Allow leading and trailing spaces, then trim after taking input
});

export const queryParams = Object.freeze({
    appInstallCode: 'code',
    appInstallStatus: 'app_install_status',
});
