import { Box, IconButton, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import AccountBox from '@material-ui/icons/AccountBox';
import AppsIcon from '@material-ui/icons/Apps';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Home from '@material-ui/icons/Home';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Person from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserRole } from '../../app.enum';
import { Global } from '../../app.global';
import WorkplaceIcon from '../../assets/images/icon_workplace.png';
import pavenLogo from '../../assets/images/logo_paven.svg';
import environment from '../../environments/environment';
import { useModule } from '../../providers/module.provider';
import injector from '../../services';
import { IntlService } from '../../services/intl.service';
import { UserService } from '../../services/user.service';
import { searchQueryParamName } from '../header/SearchInput';
import NestedListItem from '../nested-list-item/NestedListItem';
import * as packageJson from './../../../package.json';

const intlService: IntlService = injector.get(IntlService);

const drawerWidth = Global.cssValues.sidebarWidth;
const isPortalInitiatedAppInstall = environment.isPortalInitiatedAppInstall;

const sideBarTheme = createMuiTheme({
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    color: 'white',
                    '& .MuiSvgIcon-root': {
                        color: 'white !important',
                    },
                    backgroundColor: '#3397ff !important',
                    '&:hover': {
                        backgroundColor: '#3397ff',
                    },
                },
            },
            button: {
                '&:hover': {
                    backgroundColor: '#ebf5ff',
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: '35px',
            }
        },
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        title: {
            flexGrow: 1,
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        sidebarLogoContainer: {
            height: '9vh',
            justifyContent: 'center',
        },
        sidebarLogo: {
            width: '30px',
            marginTop: '10px',
            paddingBottom: '21px',
            paddingRight: '6px',
        },
        logoText: {
            paddingBottom: '-6px',
            paddingTop: '23px',
            fontSize: '11px',
            paddingRight: '6px',
            paddingLeft: '-24px',
        },
        closeSideBarIcon: {
            position: 'absolute',
            textAlign: 'right',
            top: '1px',
            right: '0px',
        },
    })
);

export default function Sidebar(props: any) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const module = useModule();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const authData = UserService.getUserData();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuItemOnClick = (moduleName: string) => {
        const params = new URLSearchParams(history.location.search);
        params.delete(searchQueryParamName);
        history.push(`/${moduleName}`);
    };

    const drawer = (
        <div>
            <List>
                <ListItem className={classes.sidebarLogoContainer}>
                    <img src={pavenLogo} className={classes.sidebarLogo} />
                    <span className={classes.logoText}>{intlService.get('app.label.pavenPortal')}</span>
                    <span className={classes.closeSideBarIcon}>
                        <IconButton
                            className='primary-color-icon'
                            onClick={async () => {
                                props.onClose(false);
                            }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </span>
                </ListItem>

                <Divider />
                <ListItem
                    button
                    component={RouterLink}
                    to='/dashboard'
                    selected={module.moduleName === 'dashboard'}
                    onClick={(event: any) => {
                        handleMenuItemOnClick('dashboard');
                    }}>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                </ListItem>

                {authData.role === UserRole.Super ? (
                    <>
                        <ListItem
                            button
                            component={RouterLink}
                            to='/accounts'
                            selected={module.moduleName === 'accounts'}
                            onClick={(event: any) => {
                                handleMenuItemOnClick('accounts');
                            }}>
                            <ListItemIcon>
                                <AccountBox />
                            </ListItemIcon>
                            <ListItemText primary={intlService.get('app.label.accounts')} />
                        </ListItem>

                        <ListItem
                            button
                            component={RouterLink}
                            to='/super-administrators'
                            selected={module.moduleName === 'super-administrators'}
                            onClick={(event: any) => {
                                handleMenuItemOnClick('super-administrators');
                            }}>
                            <ListItemIcon>
                                <SupervisorAccountIcon />
                            </ListItemIcon>
                            <ListItemText primary={intlService.get('app.label.superAdministrators')} />
                        </ListItem>
                    </>
                ) : null}

                <ListItem
                    button
                    component={RouterLink}
                    to='/users'
                    selected={module.moduleName === 'users'}
                    onClick={(event: any) => {
                        handleMenuItemOnClick('users');
                    }}>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary='Users' />
                </ListItem>
                <NestedListItem
                    item={{
                        icon: <InsertChartIcon />,
                        name: 'Analytics',
                        childItems: [
                            {
                                name: 'Keywords',
                                icon: <LibraryBooksIcon />,
                                moduleName: 'keywords',
                            },
                            {
                                name: 'Reports',
                                icon: <BarChartIcon />,
                                visibleFor: UserRole.Super,
                                onClick: (event: any) => {
                                    // More Info: https://stackoverflow.com/questions/40593632/use-window-open-but-block-use-of-window-opener
                                    window.open(environment.tableauReportUrl, '_blank', 'noopener');
                                }
                            }
                        ],
                    }}
                />
                {authData.role === UserRole.Admin && isPortalInitiatedAppInstall && (
                    <ListItem
                        button
                        component={RouterLink}
                        to='/app-integration'
                        selected={module.moduleName === 'app-integration'}
                        onClick={(event: any) => {
                            handleMenuItemOnClick('app-integration');
                        }}>
                        <ListItemIcon>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary='App' />
                    </ListItem>
                )}
                <NestedListItem
                    item={{
                        icon: <SettingsIcon />,
                        name: 'Settings',
                        childItems: [
                            {
                                name: 'Workplace',
                                icon: <img src={WorkplaceIcon} />,
                                moduleName: 'workplace-settings',
                                visibleFor: UserRole.Super,
                            },
                            {
                                name: 'Dialogflow',
                                icon: <DeviceHubIcon />,
                                moduleName: 'dialogflow-settings',
                                visibleFor: UserRole.Super,
                            },
                            {
                                name: 'Group Post Triggers',
                                icon: <DynamicFeedIcon />,
                                moduleName: 'group-post-triggers',
                            },
                        ],
                    }}
                />
            </List>
        </div>
    );

    const buildVersion = (
        <Typography align='center' gutterBottom variant='caption'>
            {'Version: ' + packageJson.version}
        </Typography>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <ThemeProvider theme={sideBarTheme}>
            <div className={classes.root}>
                <CssBaseline />

                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation='css'>
                        <Drawer
                            container={container}
                            variant='temporary'
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}>
                            <Box display='flex' height='100%' flexDirection='column' justifyContent='space-between'>
                                {drawer}
                                {buildVersion}
                            </Box>
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation='css'>
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant='persistent'
                            open={props.open}>
                            <Box display='flex' height='100%' flexDirection='column' justifyContent='space-between'>
                                {drawer}
                                {buildVersion}
                            </Box>
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        </ThemeProvider>
    );
}
