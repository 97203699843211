import { Button, Grid } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import ChatIcon from '@material-ui/icons/Chat';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import Moment from 'moment';
import React, { useEffect } from 'react';
import { AppStatusEnum, InstallProgressStatusEnum } from '../../app.enum';
import { IAccountList } from '../../models';
import { AccountService } from '../../services/account.service';
import { AppInstallationService } from '../../services/app-installation.service';
import Injector from '../../services/index';
import { IntlService } from '../../services/intl.service';
import { UserService } from '../../services/user.service';

const intlService: IntlService = Injector.get(IntlService);
const accountService: AccountService = Injector.get(AccountService);
let accountId: string = '';
const appIntegrationService: AppInstallationService = Injector.get(AppInstallationService);
const getStatusOfApp = (accountData: IAccountList) => {
    // const appStatus = accountData.appStatus
    const installStatus = accountData?.source?.installStatus;
    if (accountData.appStatus === AppStatusEnum.Uninstalled) {
        return 'Uninstalled';
    } else if (appIntegrationService.isAppSuccessfullyInstalled(accountData)) {
        return 'Installed';
    } else if (
        !installStatus ||
        installStatus?.codeReceiveStatus === InstallProgressStatusEnum.Failed ||
        installStatus?.accessTokenReceiveStatus === InstallProgressStatusEnum.Failed ||
        installStatus?.domainWhitelistStatus === InstallProgressStatusEnum.Failed
    ) {
        return 'Not Installed';
    } else {
        return 'Installation in progress';
    }
};

const defaultAccountData: IAccountList = {
    isActive: false,
    isGroupPostEnabled: false,
    groupPostWaitWindow: 0,
    id: Math.random().toString(),
};

const AppInstallation: React.FC<any & any> = (props): JSX.Element => {
    const [appStatus, setAppStatus] = React.useState<any>();
    const [columns, setColumns] = React.useState<GridColumns>([]);
    const [accountData, setAccountData] = React.useState<IAccountList[]>([defaultAccountData]);

    const getAccountData = (accountId: string) => {
        accountService.getAccountById(accountId).then(
            async (x) => {
                const accountData = [
                    {
                        ...x.data,
                        id: Math.random().toString(), // Data grid -Material-UI: The data grid component requires all rows to have a unique id property.
                    },
                ];
                setAccountData(accountData);
                // if (x.data?.appStatus === AppStatusEnum.Installed) setAppStatus(AppStatusEnum.Installed);
                // else
                await setAppStatus(() => x.data?.appStatus);
                // await setAppStatus(x.data?.appStatus);
            },
            (err) => {}
        );
    };

    useEffect(() => {
        accountId = UserService.getUserData()?.accountId;
        if (accountId) {
            getAccountData(accountId);
        }
    }, []);

    useEffect(() => {
        // putting separate check for undefined and null, so that it should work for 0 value
        if (appStatus !== undefined && appStatus !== null) {
            const updatedColumns = [
                {
                    field: 'communityName',
                    sortable: false,
                    headerName: intlService.get('app.label.communityName'),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: (params: any) => {
                        return <React.Fragment>{params.row?.source?.communityName}</React.Fragment>;
                    },
                },
                {
                    field: 'installAdminEmail',
                    headerName: intlService.get('app.label.installedBy'),
                    sortable: false,
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: (params: any) => {
                        return <React.Fragment>{params.row?.source?.installAdminEmail}</React.Fragment>;
                    },
                },
                {
                    field: 'updatedDate',
                    headerName:
                        appStatus === AppStatusEnum.Uninstalled
                            ? intlService.get('app.label.uninstalledDate')
                            : intlService.get('app.label.installedDate'),
                    flex: 1,
                    disableColumnMenu: true,
                    renderCell: (params: any) => (
                        <React.Fragment>
                            {' '}
                            {params.row.appStatus === AppStatusEnum.Installed ||
                            params.row.appStatus === AppStatusEnum.Uninstalled
                                ? params.row?.updatedDate && Moment(params.row.updatedDate).format('llll')
                                : ''}
                        </React.Fragment>
                    ),
                },
                {
                    field: 'status',
                    headerName: intlService.get('app.label.status'),
                    flex: 1,
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params: any) => {
                        return <React.Fragment>{getStatusOfApp(params.row)}</React.Fragment>;
                    },
                },
            ];

            setColumns(() => updatedColumns);
        }
    }, [appStatus]);

    return (
        <>
            <Grid container={true} direction='row' justify='flex-end' spacing={1}>
                {accountData?.length &&
                appIntegrationService.isAppSuccessfullyInstalled(accountData[0]) &&
                accountData[0].source?.chatbotId ? (
                    <>
                        <Grid item={true}>
                            <Button
                                type='button'
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    appIntegrationService.chatWithPavenLink(accountData[0].source?.chatbotId || '');
                                }}
                                startIcon={<ChatIcon />}
                                className='primary-color'>
                                {intlService.get('app.button.chatWithPaven')}
                            </Button>
                        </Grid>
                    </>
                ) : null}

                <Grid item={true}>
                    {accountData?.length &&
                    appIntegrationService.isAppSuccessfullyInstalled(accountData[0]) &&
                    accountData[0].source?.chatbotId ? (
                        <>
                            <Button
                                type='button'
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    appIntegrationService.goToAppLink();
                                }}
                                startIcon={<ExitToAppIcon />}
                                className='primary-color'>
                                {intlService.get('app.button.goToApp')}
                            </Button>
                        </>
                    ) : (
                        <Button
                            type='button'
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                appIntegrationService.installAppLink();
                            }}
                            startIcon={<GetAppIcon />}
                            className='primary-color'>
                            {intlService.get('app.button.installApp')}
                        </Button>
                    )}
                </Grid>
                <Grid item={true}>
                    <Button
                        type='button'
                        variant='contained'
                        color='primary'
                        className='primary-color'
                        onClick={() => {
                            getAccountData(accountId);
                        }}
                        startIcon={<RefreshIcon />}>
                        {' '}
                        {intlService.get('app.button.refresh')}
                    </Button>
                </Grid>
            </Grid>
            <div className='table-view mt-20'>
                <DataGrid
                    rows={accountData}
                    columns={columns}
                    disableSelectionOnClick={true}
                    hideFooterSelectedRowCount
                    hideFooterRowCount={true}
                    hideFooterPagination={true}
                    hideFooter={true}
                />
            </div>
        </>
    );
};

export default AppInstallation;
