import { Button, Chip, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { OperationEnum, UserRole } from '../../../app.enum';
import { Global } from '../../../app.global';
import { IGroupPostFormField } from '../../../models';
import injector from '../../../services';
import { AccountService } from '../../../services/account.service';
import { IntlService } from '../../../services/intl.service';
import { UserService } from '../../../services/user.service';
import AsyncAutoCompleteField, {
    IAsyncAutoCompleteFieldItem,
} from '../../../shared/async-auto-complete-field/AsyncAutoCompleteField';
import CustomButton from '../../../shared/custom-button.component';
import FullScreenDialog from '../../../shared/full-screen-dialog/full-screen-dialog';

const intlService: IntlService = injector.get(IntlService);
const accountService: AccountService = injector.get(AccountService);
const maxKeywordCombinationAllowed = Global.groupPost.maxKeywordCombination;
interface IGroupPostFormProps {
    open: boolean;
    onSubmit: (formValues: IGroupPostFormField) => void;
    onClose: () => void;
    prefilledFormValues?: IGroupPostFormField;
    operation?: OperationEnum;
}

export const initialFormValues: IGroupPostFormField = {
    triggerKeywords: [],
    accountId: '',
    accountName: '',
};

export const formValidationSchema = Yup.object().shape<any>({
    triggerKeywords: Yup.array()
        .min(1, intlService.get('app.validation.mininumFilterKeyword'))
        .required(intlService.get('app.validation.keywordRequired')),
});

const GroupPostForm: React.FC<IGroupPostFormProps> = (props) => {
    const currentUserData = UserService.getUserData();

    //TODO: Remove undefined by making role required field.
    const [isCurrentUserAdmin] = useState(![UserRole.Super, UserRole.User, undefined].includes(currentUserData?.role));
    const [selectedAccount, setSelectedAccount] = useState<IAsyncAutoCompleteFieldItem | null>(() => {
        if (props.prefilledFormValues?.accountId && props.prefilledFormValues.accountName) {
            return {
                name: props.prefilledFormValues.accountName,
                value: props.prefilledFormValues.accountId,
            };
        }
        return null;
    });
    const [accounts, setAccounts] = useState<IAsyncAutoCompleteFieldItem[]>();
    // triggerInputFieldValue is used as controlled value for inputValue attribute in autoComplete component bug - 351
    const [triggerInputFieldValue, setTriggerInputFieldValue] = useState<string>('');
    // state containing message in case max keyword entered by user is greator then specified value
    const [maxKeywordHelperText, setMaxKeywordHelperText] = useState<string>('');

    const formik = useFormik<IGroupPostFormField>({
        initialValues: props.prefilledFormValues ?? initialFormValues,
        validationSchema: formValidationSchema.concat(
            Yup.object({
                accountId: isCurrentUserAdmin
                    ? Yup.string()
                    : Yup.string().required(intlService.get('app.validation.accountIsRequired')),
            })
        ),
        onSubmit: async (formValues) => {
            await props.onSubmit(formValues);
        },
    });

    useEffect(() => {
        // active boolean is used to disallow state change in chage component is not mounted.
        let active = true;
        fetchAccounts({ input: '' }).then((data) => {
            if (active) {
                if (accounts === undefined) {
                    setAccounts(data);
                }
            }
        });
        return () => {
            active = false;
        };
    }, []);

    const fetchAccounts = useCallback(
        async (request: { input: string }) => {
            if (isCurrentUserAdmin) {
                formik.values.accountId = currentUserData.accountId ?? '';
                return [];
            }

            const accountsResult = await accountService.getAccountList({
                size: 20,
                page: 0,
                searchQuery: request.input,
            });

            const autoCompleteFieldItems = accountsResult.data.records.map((account) => {
                const item: IAsyncAutoCompleteFieldItem = {
                    name: account.name ?? '',
                    value: account.id ?? '',
                };

                return item;
            });

            return autoCompleteFieldItems;
        },
        [accounts]
    );

    const handleClose = () => {
        formik.resetForm();
        setSelectedAccount(null);
        props.onClose();
    };

    return (
        <FullScreenDialog
            open={props.open}
            onClose={handleClose}
            title={intlService.get('app.component.group-post-setting')}>
            <form onSubmit={formik.handleSubmit} className='add-edit-form h-100' noValidate>
                <Grid container={true} direction='row' className='h-100 pt-15'>
                    <Grid item xs={6}>
                        <Grid container={true} justify='flex-start' spacing={2}>
                            {UserService.getUserData()?.role === UserRole.Super && accounts !== undefined && (
                                <Grid item xs={12}>
                                    <AsyncAutoCompleteField
                                        id='accountId'
                                        name='accountId'
                                        label={`${intlService.get('app.message.select')} ${intlService.get(
                                            'app.label.account'
                                        )}`}
                                        value={selectedAccount}
                                        initialData={accounts}
                                        errorMessage={formik.errors.accountId}
                                        showError={Boolean(
                                            (formik.values.accountId?.length || formik.submitCount) &&
                                                !!formik.errors.accountId
                                        )}
                                        disabled={
                                            UserService.getUserData()?.role !== UserRole.Super ||
                                            props.operation === OperationEnum.Edit
                                        }
                                        onValueChange={(value: string) => {
                                            formik.setFieldValue('accountId', value);
                                            setSelectedAccount(() => {
                                                const account = accounts?.find((a) => a.value === value);
                                                if (account) {
                                                    return {
                                                        name: account.name,
                                                        value: value,
                                                    };
                                                }
                                                return null;
                                            });
                                        }}
                                        fetchMethod={fetchAccounts}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id='triggerKeywords'
                                    options={[]}
                                    value={formik.values.triggerKeywords}
                                    freeSolo
                                    onChange={(e, value) => {
                                        if (value.length <= maxKeywordCombinationAllowed) {
                                            formik.setFieldValue('triggerKeywords', value);
                                            setMaxKeywordHelperText('');
                                        }
                                        setTriggerInputFieldValue('');
                                    }}
                                    inputValue={triggerInputFieldValue}
                                    renderTags={(
                                        value: any[],
                                        getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                                    ) =>
                                        value.map((option: any, index: any) => {
                                            return (
                                                <Chip
                                                    key={index}
                                                    variant='outlined'
                                                    color='primary'
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            // In placeholder it will not be shown if maxKeywordCombinations have been reached
                                            placeholder={
                                                formik.values.triggerKeywords.length < maxKeywordCombinationAllowed
                                                    ? intlService.get('app.label.triggerKeywords')
                                                    : ''
                                            }
                                            label={intlService.get('app.label.triggerKeywords')}
                                            variant='outlined'
                                            onChange={(ev) => {
                                                let options = ev.target.value.split(',');
                                                setTriggerInputFieldValue(ev.target.value);
                                                if (
                                                    options.length > 1 &&
                                                    formik.values.triggerKeywords.length < maxKeywordCombinationAllowed
                                                ) {
                                                    if (!formik.values.triggerKeywords.includes(options[0])) {
                                                        formik.setFieldValue(
                                                            'triggerKeywords',
                                                            formik.values.triggerKeywords
                                                                .concat(options)
                                                                .map((x: any) => x.trim())
                                                                .filter((x: any) => x)
                                                        );
                                                        setMaxKeywordHelperText('');
                                                    }
                                                    setTriggerInputFieldValue('');
                                                } else if (
                                                    options.length > 1 &&
                                                    formik.values.triggerKeywords.length >= maxKeywordCombinationAllowed
                                                ) {
                                                    setTriggerInputFieldValue('');
                                                } else if (
                                                    formik.values.triggerKeywords.length >= maxKeywordCombinationAllowed
                                                ) {
                                                    setMaxKeywordHelperText(
                                                        `A maximum of ${maxKeywordCombinationAllowed} keyword combinations are allowed`
                                                    );
                                                }
                                            }}
                                            margin='normal'
                                            name='triggerKeywords'
                                            error={
                                                !!(
                                                    (formik.values.triggerKeywords.length || formik.submitCount) &&
                                                    !!formik.errors.triggerKeywords
                                                )
                                            }
                                            helperText={
                                                (formik.values.triggerKeywords.length || formik.submitCount) &&
                                                formik.errors.triggerKeywords
                                                    ? formik.errors.triggerKeywords
                                                    : maxKeywordHelperText
                                                    ? maxKeywordHelperText
                                                    : null
                                            }
                                            required
                                            fullWidth
                                            autoFocus
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid alignItems='flex-end' container>
                        <Grid item xs={6}>
                            <Grid container justify='flex-start'>
                                <Grid item>
                                    <Button
                                        type='button'
                                        data-testid='back-button'
                                        disabled={formik.isSubmitting}
                                        onClick={async () => {
                                            handleClose();
                                        }}>
                                        {intlService.get('app.button.back')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justify='flex-end' spacing={2}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        data-testid='delete-button'
                                        color='secondary'
                                        className='light-red-color'
                                        type='reset'
                                        disabled={formik.isSubmitting}
                                        onClick={() => {
                                            formik.setValues(initialFormValues);
                                            setMaxKeywordHelperText('');
                                            setTriggerInputFieldValue('');
                                            setSelectedAccount(null);
                                        }}>
                                        {intlService.get('app.button.clear')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <CustomButton
                                        disabled={formik.isSubmitting}
                                        loading={formik.isSubmitting}
                                        className='primary-color'
                                        data-testid='save-button'>
                                        {intlService.get('app.button.save')}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FullScreenDialog>
    );
};

export default GroupPostForm;
