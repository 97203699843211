import intl, { ReactIntlUniversalMessageDescriptor } from 'react-intl-universal';

require('intl/locale-data/jsonp/en.js');

const locales = {
    en: require('../assets/i18n/en.json'),
};

export class IntlService {
    constructor() {
        this.onInit();
    }

    async onInit() {
        await intl.init({
            currentLocale: 'en',
            locales: locales,
        });
    }

    /**
     * Provide React-Intl object
     * @returns React Intl object
     */
    getIntl() {
        return intl;
    }

    /**
     * Get the formatted message by key
     * @param {string} key The string representing key in locale data file
     * @param {Object} variables Variables in message
     * @returns {string} message
     */
    get(key: string, variables?: any) {
        if (variables) {
            return intl.get(key, variables);
        }
        return intl.get(key);
    }

    /**
     * Get the formatted html message by key.
     * @param {string} key The string representing key in locale data file
     * @param {Object} variables Variables in message
     * @returns {React.Element} message
     */
    getHTML(key: string, variables?: any) {
        if (variables) {
            return intl.getHTML(key, variables);
        }
        return intl.getHTML(key);
    }

    /**
     * Provide React-Intl compatibility, same as get(...) API.
     */
    formatMessage(messageDescriptor: ReactIntlUniversalMessageDescriptor, variables?: any) {
        if (variables) {
            return intl.formatMessage(messageDescriptor, variables);
        }
        return intl.formatMessage(messageDescriptor);
    }

    /**
     * Provide React-Intl compatibility, same as getHTML(...) API.
     */
    formatHTMLMessage(messageDescriptor: ReactIntlUniversalMessageDescriptor, variables?: any) {
        if (variables) {
            return intl.formatHTMLMessage(messageDescriptor, variables);
        }
        return intl.formatHTMLMessage(messageDescriptor);
    }
}
