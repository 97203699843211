import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { default as MoreIcon } from '@material-ui/icons/MoreVert';
import React, { useEffect, useState } from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import * as actions from '../../actions/index';
import { useModule } from '../../providers/module.provider';
import injector from '../../services';
import { IntlService } from '../../services/intl.service';
import { UserService } from '../../services/user.service';
import SearchInput from './SearchInput';

interface IAppOwnProps {
    openSideBar: (close: boolean) => void;
    isSidebarOpen: boolean;
}

interface IAppDispatchToProps {
    logOut: () => void;
}

const intlService: IntlService = injector.get(IntlService);

const noSearchbarModules = ['dashboard', 'app-integration', 'workplace-settings', 'dialogflow-settings'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    })
);

const Header: React.FC<IAppDispatchToProps & IAppOwnProps> = (props): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const module = useModule();

    const logOutUser = () => {
        UserService.logoutUser();
        props.logOut();
        history.push('/');
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const classes = useStyles();

    const handleProfile = () => {
        history.push('/profile');
        setAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const pathname = location.pathname.toLowerCase();
        // if root path and pathname contains any of noSearchbarModule then return false
        setShowSearchInput(pathname !== '/' && noSearchbarModules.every((x) => !pathname.includes(x.toLowerCase())));
    }, [location]);

    const isMenuOpen = Boolean(anchorEl);
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id='primary-search-account-menu'
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem onClick={handleProfile}>{intlService.get('app.button.profile')}</MenuItem>
            <MenuItem onClick={logOutUser}> {intlService.get('app.button.logOut')}</MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position='static' className='primary-color'>
                <Toolbar>
                    {!props.isSidebarOpen && (
                        <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label={intlService.get('app.ariaLabel.openDrawer')}
                            onClick={async () => {
                                props.openSideBar(true);
                            }}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography className={classes.title} variant='h6' noWrap>
                        {intlService.get('app.component.' + module.moduleName)}
                    </Typography>
                    {showSearchInput && <SearchInput />}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge='end'
                            aria-label='account of current user'
                            // aria-controls={menuId}
                            aria-haspopup='true'
                            onClick={handleProfileMenuOpen}
                            color='inherit'>
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label='show more'
                            aria-haspopup='true'
                            color='inherit'
                            onClick={handleProfileMenuOpen}>
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </div>
    );
};

const mapDispatchToProps: MapDispatchToProps<IAppDispatchToProps, IAppOwnProps> = (
    dispatch: Dispatch,
    ownProps: IAppOwnProps
): IAppDispatchToProps => ({
    logOut: () => {
        dispatch(actions.userSignedOutAction());
    },
});

export default connect(null, mapDispatchToProps)(Header);
