import { Button, Grid, Switch, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DataGrid, GridColumns, GridPageChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { withRouter } from 'react-router';
import { OperationEnum, SnackBarVariants, StatusEnum, UserRole } from '../../app.enum';
import environment from '../../environments/environment';
import * as models from '../../models';
import { IUser, IUserComponentState, UserQueryParam } from '../../models';
import Injector from '../../services/index';
import { IntlService } from '../../services/intl.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import CustomDialogBox from '../../shared/dialog.component';
import { getSearchQuery, removeSearchQuery } from '../../shared/header/SearchInput';
import withSnackBar from '../../shared/message.component';
import UserForm from './user-form';

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
});

const intlService: IntlService = Injector.get(IntlService);
const userService: UserService = Injector.get(UserService);
const sharedService: SharedService = Injector.get(SharedService);

class UserComponent extends React.Component<models.IUserComponentProps, IUserComponentState> {
    userIdForAction: string = '';
    rowData: any;
    anchorEl: HTMLButtonElement | undefined;
    userAuthData: models.IUserSignInResponse;
    isSuperAdmin = false;
    isShowSuperAdminUI = false;
    axiosCancelTokenSource = axios.CancelToken.source();
    constructor(props: models.IUserComponentProps) {
        super(props);
        this.userAuthData = UserService.getUserData();
        this.isSuperAdmin = this.userAuthData.role === UserRole.Super;

        this.state = {
            records: [],
            nextPage: '',
            previousPage: '',
            pageSize:
                environment.uiSettings.pagination.rowsPerPageArray[
                    environment.uiSettings.pagination.defaultIndexOfRowsPerPageArray
                ],
            totalPages: 0,
            totalRecords: 0,
            currentPage: 0,
            isDisplayForm: false,
            isActionOpen: false,
            openConfirmationDialog: false,
            operation: OperationEnum.Add,
            isShowSuperAdminUI: false,
            columns: [],
            sortModel: [{ field: 'createdDate', sort: 'desc' }],
        };
    }

    /**
     * @returns it returns the grid columns
     */
    getGridColumns(): GridColumns {
        return [
            {
                field: 'accountName',
                sortable: false,
                // hiding accountName for non super admin, because only super admins can see the account name, as they are viewing all accounts
                hide: !this.isSuperAdmin,
                headerName: intlService.get('app.label.account'),
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <Tooltip title={params.row.accountName}><span>{params.row.accountName}</span></Tooltip>;
                },
            },
            {
                field: 'name',
                headerName: intlService.get('app.label.name'),
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <Tooltip title={params.row.name}><span>{params.row.name !== 'null' && params.row.name}</span></Tooltip>;
                },
            },
            {
                field: 'email',
                headerName: intlService.get('app.label.email'),
                flex: 1.5,
                disableColumnMenu: true,
            },
            {
                field: 'roleID',
                headerName: intlService.get('app.label.role'),
                flex: 0.9,
                // hiding roleID because only super admins will be visible when isShowSuperAdminUI is true
                hide: this.isShowSuperAdminUI,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>{sharedService.getEnumKeyByValue(UserRole, params.row.roleID)}</React.Fragment>
                    );
                },
            },
            {
                field: 'status',
                headerName: intlService.get('app.label.active'),
                flex: 1,
                // we are hiding the status column for time being, as we are not using this value anywhere
                hide: true,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return (
                        <React.Fragment>
                            <Switch
                                checked={params.row.statusID === StatusEnum.Enabled}
                                onClick={async (e) => {
                                    this.rowData = params.row;
                                    this.userIdForAction = params.row.id;
                                    await this.setState({
                                        operation: OperationEnum.ChangeStatus,
                                        isActionOpen: false,
                                        columns: [...this.state.columns],
                                    });
                                }}
                                color='primary'
                                name='checkedB'
                                inputProps={{
                                    'aria-label': 'primary checkbox',
                                }}
                            />
                        </React.Fragment>
                    );
                },
            },
            {
                field: 'muted',
                headerName: intlService.get('app.label.muted'),
                flex: 1,
                sortable: false,
                hide: this.isShowSuperAdminUI,
                disableColumnMenu: true,
                renderCell: (params: any) => (
                    <React.Fragment>
                        <Switch
                            checked={params.row.isMuted}
                            onClick={async (e) => {
                                this.rowData = params.row;
                                this.userIdForAction = params.row.id;
                                await this.setState({
                                    operation: OperationEnum.ChangeMuteStatus,
                                    isActionOpen: false,
                                    columns: [...this.state.columns],
                                });
                            }}
                            color='primary'
                            name='checkedB'
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    </React.Fragment>
                ),
            },
            {
                field: 'createdDate',
                headerName: intlService.get('app.label.createdDate'),
                flex: 1,
                disableColumnMenu: true,
                renderCell: (params: any) => (
                    <React.Fragment>{sharedService.formatDate(params.row.createdDate)}</React.Fragment>
                ),
            },
        ];
    }

    calculateIsShowSuperAdminUIValue = async () => {
        if (this.props.location?.pathname === '/super-administrators') {
            this.isShowSuperAdminUI = true;
        } else {
            this.isShowSuperAdminUI = false;
        }
        await this.setState({
            columns: this.getGridColumns(),
        });
    };

    public async componentDidMount() {
        // calculating the isShowSuperAdminUI flag value, whether to show super admin list, or user list
        await this.calculateIsShowSuperAdminUIValue();
        await this.setState({
            columns: this.getGridColumns(),
        });
        await this.loadData();
    }

    componentDidUpdate(prevProps: any) {
        const { searchQuery, isSearchQueryChanged } = getSearchQuery(prevProps, this.props);

        if (isSearchQueryChanged) {
            this.loadData(searchQuery);
        }

        // checking the props, as we switch from super administrator tab to user tab, then component will remain same, only route path changes
        if (prevProps.match?.path !== this.props.match?.path) {
            this.calculateIsShowSuperAdminUIValue();
            this.loadData();
        }
    }
    public componentWillUnmount() {
        this.axiosCancelTokenSource.cancel();
    }

    getSortingColumnName(sortColumn: string) {
        let columnName = sortColumn;

        if (sortColumn === 'name') {
            // change the name so that In API side, when we sort it can exactly match with DB column name
            columnName = 'firstName';
        }
        return columnName;
    }

    private loadData = async (searchQuery?: string) => {
        let queryParam: UserQueryParam = {
            size: this.state.pageSize,
            page: this.state.currentPage,
            sortBy: this.getSortingColumnName(this.state.sortModel[0]?.field),
            sortingOrder: this.state.sortModel[0]?.sort,
            roleID: this.isShowSuperAdminUI ? UserRole.Super : [UserRole.Admin, UserRole.User],
            searchQuery,
        };

        if (!this.isSuperAdmin) {
            queryParam['accountId'] = this.userAuthData.accountId;
        }
        await userService
            .getUserList(queryParam, this.axiosCancelTokenSource.token)
            .then(async (res: any) => {
                await this.setState({
                    records: res?.data?.records || [],
                    totalRecords: res?.data?.totalRecords || 0,
                });
            })
            .catch((err: AxiosError) => {
                !axios.isCancel(err) &&
                 this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    async closeForm() {
        await this.setState({
            isDisplayForm: false,
        });
    }

    handlePageChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: params.page,
        });
        await this.loadData();
    };
    handlePageSizeChange = async (params: GridPageChangeParams) => {
        await this.setState({
            pageSize: params.pageSize,
            currentPage: 0,
        });
        await this.loadData();
    };
    handleSortModelChange = async (params: GridSortModelParams) => {
        await this.setState({
            sortModel: params.sortModel,
        });
        const {searchQuery}=getSearchQuery(this.props, this.props);
        if(searchQuery){
            await this.loadData(searchQuery);
        }else{
            await this.loadData();
        }
    };

    displayList = () => {
        return (
            <div className='table-view'>
                <DataGrid
                    rows={this.state.records}
                    columns={this.state.columns}
                    pagination
                    rowsPerPageOptions={environment.uiSettings.pagination.rowsPerPageArray}
                    onPageSizeChange={this.handlePageSizeChange}
                    pageSize={this.state.pageSize}
                    rowCount={this.state.totalRecords}
                    paginationMode='server'
                    onPageChange={this.handlePageChange}
                    sortingMode='server'
                    sortModel={this.state.sortModel}
                    onSortModelChange={this.handleSortModelChange}
                    hideFooterSelectedRowCount={true}
                    disableSelectionOnClick={true}
                    page={this.state.currentPage}
                />
            </div>
        );
    };

    public render() {
        return (
            <>
                {!this.isShowSuperAdminUI && (
                    <>
                        <Grid item={true}>
                            <Button
                                data-testid='add-btn'
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    this.setState({
                                        isDisplayForm: true,
                                        operation: OperationEnum.Add,
                                    });
                                }}
                                className='add-button-table primary-color '
                                startIcon={<AddIcon />}>
                                {intlService.get('app.button.add')}
                            </Button>
                        </Grid>
                        <UserForm
                            open={this.state.isDisplayForm}
                            onSuccess={(message) => {
                                this.setState({ isDisplayForm: false });
                                this.loadData();
                                this.openSnackbar(message, SnackBarVariants.Success);
                            }}
                            onError={(message) => {this.openSnackbar(message, SnackBarVariants.Error)}}
                            onClose={() => this.setState({ isDisplayForm: false, operation: OperationEnum.Add })}
                        />
                    </>
                )}
                {this.displayList()}
                <CustomDialogBox
                    open={
                        this.state.operation === OperationEnum.ChangeStatus &&
                        !(this.rowData.statusID === StatusEnum.Enabled)
                    }
                    dialogTitle={intlService.get('app.dialog.title.activateUser')}
                    dialogDescription={intlService.get('app.message.activateUserConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
                <CustomDialogBox
                    open={
                        this.state.operation === OperationEnum.ChangeStatus &&
                        this.rowData.statusID === StatusEnum.Enabled
                    }
                    dialogTitle={intlService.get('app.dialog.title.deactivateUser')}
                    dialogDescription={intlService.get('app.message.deactivateUserConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
                <CustomDialogBox
                    open={this.state.operation === OperationEnum.ChangeMuteStatus && !this.rowData.isMuted}
                    dialogTitle={intlService.get('app.dialog.title.muteUser')}
                    dialogDescription={intlService.get('app.message.muteUserConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
                <CustomDialogBox
                    open={this.state.operation === OperationEnum.ChangeMuteStatus && this.rowData.isMuted}
                    dialogTitle={intlService.get('app.dialog.title.unmuteUser')}
                    dialogDescription={intlService.get('app.message.unmuteUserConfirmation')}
                    closeCustomDialog={this.closeDialog}
                />
            </>
        );
    }

    private closeDialog = async (dialogTitle: string, isCancel: boolean) => {
        if (!isCancel) {
            switch (this.state.operation) {
                case OperationEnum.ChangeStatus:
                    await this.changeStatus(this.userIdForAction, {
                        statusID:
                            this.rowData.statusID === StatusEnum.Enabled ? StatusEnum.Disabled : StatusEnum.Enabled,
                    });
                    break;
                case OperationEnum.ChangeMuteStatus:
                    await this.changeStatus(this.userIdForAction, {
                        isMuted: !this.rowData.isMuted,
                    });
                    break;
            }

            isCancel = true;
        }
        this.userIdForAction = '';
        await this.setState({
            //openConfirmationDialog: false,
            operation: OperationEnum.Default,
        });
    };

    changeStatus = async (id: string, userData: IUser) => {
        await userService
            .updateUserStatus(id, userData, this.axiosCancelTokenSource.token)
            .then(async (res) => {
                removeSearchQuery(this.props);
                await this.loadData();
                if (this.state.operation === OperationEnum.ChangeMuteStatus) {
                    if (userData.isMuted) {
                        this.openSnackbar(
                            intlService.get('app.message.userMutedSuccessfully'),
                            SnackBarVariants.Success
                        );
                    } else {
                        this.openSnackbar(
                            intlService.get('app.message.userUnmutedSuccessfully'),
                            SnackBarVariants.Success
                        );
                    }
                } else {
                    if (userData.statusID === StatusEnum.Enabled) {
                        this.openSnackbar(
                            intlService.get('app.message.userActivatedSuccessfully'),
                            SnackBarVariants.Success
                        );
                    } else {
                        this.openSnackbar(
                            intlService.get('app.message.userDeactivatedSuccessfully'),
                            SnackBarVariants.Success
                        );
                    }
                }
            })
            .catch((err: AxiosError) => {
                !axios.isCancel(err) &&
                 this.openSnackbar(sharedService.parseError(err), SnackBarVariants.Error);
            });
    };

    private openSnackbar = (message: string | string[], variantName: SnackBarVariants = SnackBarVariants.Success) => {
        this.props.openSnackBar!(message, variantName);
    };
}

export default withSnackBar(withStyles(styles)(withRouter(UserComponent)));
