import {
    Button,
    createStyles,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Theme
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { OperationEnum, UserRole } from '../../app.enum';
import { regex } from '../../constants';
import { IUserFormFields } from '../../models';
import { } from '../../models/user.models';
import injector from '../../services';
import { AccountService } from '../../services/account.service';
import { IntlService } from '../../services/intl.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import AsyncAutoCompleteField, {
    IAsyncAutoCompleteFieldItem
} from '../../shared/async-auto-complete-field/AsyncAutoCompleteField';
import CustomButton from '../../shared/custom-button.component';
import FullScreenDialog from '../../shared/full-screen-dialog/full-screen-dialog';

interface IUserFormProps {
    open: boolean;
    onSuccess: (successMesage: string) => void;
    onError: (errorMessage: string) => void;
    onClose: () => void;
    prefilledFormValues?: IUserFormFields;
    operation?: OperationEnum;
}

export const initialFormValues: IUserFormFields = {
    accountId: '',
    email: '',
    role: UserRole.Admin,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: { backgroundColor: 'white', padding: '0px 4px' },
    })
);

const intlService: IntlService = injector.get(IntlService);
const accountService: AccountService = injector.get(AccountService);
const userService: UserService = injector.get(UserService);
const sharedService: SharedService = injector.get(SharedService);

const formValidationSchema = Yup.object({
    email: Yup.string().required(intlService.get('app.validation.emailRequired')).matches(regex.email, intlService.get('app.validation.invalidEmail')),
    role: Yup.string().required(intlService.get('app.validation.roleIsRequired')),
});

const UserForm: React.FC<IUserFormProps> = (props) => {
    const classes = useStyles();
    const currentUserData = UserService.getUserData();
    //TODO: Removed undefined by making role required field.
    const [isCurrentUserAdmin] = useState(![UserRole.Super, UserRole.User, undefined].includes(currentUserData?.role));

    const formik = useFormik<IUserFormFields>({
        initialValues: props.prefilledFormValues ?? initialFormValues,
        validationSchema: formValidationSchema.concat(
            Yup.object({
                accountId: isCurrentUserAdmin
                    ? Yup.string()
                    : Yup.string().required(intlService.get('app.validation.accountIsRequired')),
            })
        ),
        onSubmit: async (formValues) => {
            try {
                const isSuccess = await sendInvitation(formValues);

                if (isSuccess) {
                    formik.resetForm();
                    setSelectedAccount(null);
                    props.onSuccess(intlService.get('app.message.userAddedSuccessfully'));
                } else {
                    props.onError(intlService.get('app.error.internalServerError'));
                }

                handleClose();
            } catch (err: any) {
                const errorMessage = sharedService.parseError(err);
                props.onError(errorMessage);
            }
        },
    });

    const [selectedAccount, setSelectedAccount] = useState<IAsyncAutoCompleteFieldItem | null>(null);
    const [accounts, setAccounts] = useState<IAsyncAutoCompleteFieldItem[]>();

    useEffect(() => {
        // active boolean is used to disallow state change in chage component is not mounted.
        let active = true;
        fetchAccounts({ input: '' }).then((data) => {
            if (active) {
                if (accounts === undefined) {
                    setAccounts(data);
                }
            }
        });
        return () => {
            active = false;
        };
    }, []);

    const fetchAccounts = useCallback(
        async (request: { input: string }) => {
            if (isCurrentUserAdmin) {
                formik.values.accountId = currentUserData.accountId ?? '';
                return [];
            }

            const accountsResult = await accountService.getAccountList({
                size: 20,
                page: 0,
                searchQuery: request.input,
            });

            const autoCompleteFieldItems = accountsResult.data.records.map((account) => {
                const item: IAsyncAutoCompleteFieldItem = {
                    name: account.name ?? '',
                    value: account.id ?? '',
                };

                return item;
            });

            return autoCompleteFieldItems;
        },
        [accounts]
    );

    const sendInvitation = useCallback(async (formValues: IUserFormFields): Promise<boolean> => {
        const response = await userService.sendInvitaion(formValues);
        return response.data;
    }, []);

    const handleClose = () => {
        formik.resetForm();
        setSelectedAccount(null);
        props.onClose();
    };

    return (
        <div data-testid='user-form'>
            <FullScreenDialog
                open={props.open}
                onClose={handleClose}
                title={`${intlService.get('app.label.add')} ${intlService.get('app.label.user')}`}>
                <form
                    onSubmit={formik.handleSubmit}
                    className='add-edit-form h-100'
                    noValidate={true}
                    data-testid='form'>
                    <Grid container={true} direction='row' className='h-100 pt-15'>
                        <Grid item xs={6}>
                            <Grid container={true} justify='flex-start' spacing={2}>
                                {UserService.getUserData()?.role === UserRole.Super && (
                                    <Grid item={true} xs={12}>
                                        <AsyncAutoCompleteField
                                            id='accountId'
                                            name='accountId'
                                            label={`${intlService.get('app.message.select')} ${intlService.get(
                                                'app.label.account'
                                            )}`}
                                            value={selectedAccount}
                                            initialData={accounts}
                                            errorMessage={formik.errors.accountId}
                                            showError={Boolean(
                                                (formik.values.accountId?.length || formik.submitCount) &&
                                                    !!formik.errors.accountId
                                            )}
                                            disabled={UserService.getUserData()?.role !== UserRole.Super}
                                            onValueChange={(value: string) => {
                                                formik.setFieldValue('accountId', value);
                                                setSelectedAccount(() => {
                                                    const account = accounts?.find((a) => a.value === value);
                                                    if (account) {
                                                        return {
                                                            name: account.name,
                                                            value: value,
                                                        };
                                                    }
                                                    return null;
                                                });
                                            }}
                                            fetchMethod={fetchAccounts}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        name='email'
                                        label={`${intlService.get('app.label.user')} ${intlService.get(
                                            'app.label.email'
                                        )}`}
                                        placeholder={`${intlService.get('app.label.user')} ${intlService.get(
                                            'app.label.email'
                                        )}`}
                                        variant='outlined'
                                        type='text'
                                        fullWidth={true}
                                        required={true}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={
                                            !!(
                                                (formik.values.email?.length || formik.submitCount) &&
                                                !!formik.errors.email
                                            )
                                        }
                                        helperText={
                                            formik.values.email?.length || formik.submitCount
                                                ? formik.errors.email
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant='outlined' required>
                                        <InputLabel shrink className={classes.inputLabel} id='role-select-label'>
                                            {intlService.get('app.label.role')}
                                        </InputLabel>
                                        <Select
                                            labelId='role-select-label'
                                            name='role'
                                            label={intlService.get('app.label.role')}
                                            placeholder={`${intlService.get('app.message.select')} ${intlService.get(
                                                'app.label.role'
                                            )}`}
                                            required={true}
                                            value={formik.values.role ? formik.values.role : ''}
                                            displayEmpty
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            renderValue={(selected) => {
                                                if (selected) {
                                                    return sharedService
                                                        .enumToArray(UserRole)
                                                        .find((x: any) => x.value === selected)?.name;
                                                }
                                                return `${intlService.get('app.message.select')} ${intlService.get(
                                                    'app.label.role'
                                                )}`;
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '550px',
                                                    },
                                                },
                                            }}>
                                            <MenuItem value=''>{`${intlService.get(
                                                'app.message.select'
                                            )} ${intlService.get('app.label.role')}`}</MenuItem>
                                            {sharedService.enumToArray(UserRole).map((e: any) => {
                                                return (
                                                    <MenuItem key={e.value} value={e.value}>
                                                        <ListItemText primary={e.name} />
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {!!(formik.submitCount && !!formik.errors.role) && (
                                            <FormHelperText error={true}>{formik.errors.role}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid alignItems='flex-end' container>
                            <Grid item xs={6}>
                                <Grid container justify='flex-start'>
                                    <Grid item>
                                        <Button
                                            type='button'
                                            data-testid='back-button'
                                            disabled={formik.isSubmitting}
                                            onClick={async () => {
                                                handleClose();
                                            }}>
                                            {intlService.get('app.button.back')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justify='flex-end' spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            data-testid='delete-button'
                                            color='secondary'
                                            className='light-red-color'
                                            type='reset'
                                            disabled={formik.isSubmitting}
                                            onClick={() => {
                                                formik.resetForm();
                                                setSelectedAccount(null);
                                            }}>
                                            {intlService.get('app.button.clear')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <CustomButton
                                            disabled={formik.isSubmitting}
                                            loading={formik.isSubmitting}
                                            className='primary-color'
                                            data-testid='save-button'>
                                            {intlService.get('app.button.save')}
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </FullScreenDialog>
        </div>
    );
};

export default UserForm;
