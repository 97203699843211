import axios, { AxiosResponse } from 'axios';
import environment from '../environments/environment';
import { DialogflowAgentResponseDto, UpdateDialogflowAgentRequestDto } from '../models/index';
import { UserService } from './user.service';
const baseUrl = environment.appSetting.chatbotUrl;

export class DialogFlowSettingsService {
    /**
     *  common function to set headers of request
     */
    private getHeaders() {
        const userData = UserService.getUserData();
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.accessToken}`,
        };
    }

    /**
     * used to fetch user list based on filters passed
     * @param queryParam Query param/filters
     * @returns User List records and count
     */
    async getAllAgents(): Promise<AxiosResponse<DialogflowAgentResponseDto[]>> {
        const accountUrl = baseUrl + 'GDFAgent-handler/agents';
        return axios.get<DialogflowAgentResponseDto[]>(accountUrl, {
            headers: this.getHeaders(),
        });
    }

    async updateAgent(agent: UpdateDialogflowAgentRequestDto) {
        const accountUrl = baseUrl + 'GDFAgent-handler/agent';
        return axios.patch<string>(accountUrl, agent, {
            headers: this.getHeaders(),
        });
    }
}
