import * as constants from '../constants/index';
import * as models from '../models';

export interface IUserSignedInAction {
    type: typeof constants.USER_SIGNED_IN;
    payload: models.IAppReduxAuthenticationState;
}

export interface IUserSignedOutAction {
    type: typeof constants.USER_SIGNED_OUT;
}

export type AuthActions = IUserSignedInAction | IUserSignedOutAction;
export function userSignedInAction(authentication: models.IAppReduxAuthenticationState): IUserSignedInAction {
    return {
        type: constants.USER_SIGNED_IN,
        payload: authentication,
    };
}

export function userSignedOutAction(): IUserSignedOutAction {
    return {
        type: constants.USER_SIGNED_OUT,
    };
}
