import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonVariantEnum } from '../app.enum';
import { ICustomDialogProps } from '../models/shared.models';
import injector from '../services';
import { IntlService } from '../services/intl.service';
import CustomButton from './custom-button.component';

const intlService: IntlService = injector.get(IntlService);

const defaultProps: ICustomDialogProps = {
    dialogDescription: null,
    dialogNoButton: undefined,
    dialogYesButton: undefined,
    open: false,
    fullWidth: false,
    dialogTitle: '',
    closeCustomDialog: (...args: any) => {},
    intl: null,
    submitButton: {
        isSubmit: false,
        isLoading: false,
        isDisabled: false,
        onSubmit: () => {},
    },
    children: null,
    fullScreen: false,
    maxWidth: 'sm',
    disableEscapeKeyDown: false,
    noButtonVariant: ButtonVariantEnum.Text,
    yesButtonVariant: ButtonVariantEnum.Text,
};

const CustomDialogBox = (props: ICustomDialogProps) => {
    // Separate token must be used in component individually
    // because using HoC for custom dialog producing some issues
    // while cancelling Axios Requests
    const {
        children,
        dialogNoButton,
        dialogYesButton,
        submitButton,
        dialogTitle,
        component,
        closeCustomDialog,
        dialogDescription,
        noButtonVariant,
        yesButtonVariant,
        dialogId,
        loading,
        disabled,
        ...restDialogProps
    } = props;

    const defaultNoButtonText = intlService.get('app.button.cancel');
    const defaultYesButtonText = intlService.get('app.button.confirm');

    const finalDialogNoButton = dialogNoButton || defaultNoButtonText;
    const finalDialogYesButton = dialogYesButton || defaultYesButtonText;
    // isLoading is used for submit button, and loading is used for dialog
    const { isSubmit, onSubmit, isLoading, isDisabled } = { ...submitButton };

    return (
        <Dialog {...restDialogProps} onClose={() => closeCustomDialog(dialogTitle, true)}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {children || <DialogContentText component='span' children={dialogDescription} />}
            </DialogContent>
            <DialogActions>
                {!!component ? (
                    component
                ) : (
                    <>
                        {/* Dialog No Button */}
                        <CustomButton variant={noButtonVariant} onClick={() => closeCustomDialog(dialogId, true)}>
                            {finalDialogNoButton}
                        </CustomButton>
                        {/* Dialog Yes Button */}
                        <CustomButton
                            variant={yesButtonVariant}
                            onClick={() => (isSubmit ? onSubmit!() : closeCustomDialog(dialogId, false))}
                            autoFocus={true}
                            loading={isLoading || loading}
                            disabled={isDisabled || disabled}>
                            {finalDialogYesButton}
                        </CustomButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

CustomDialogBox.defaultProps = defaultProps;

export default CustomDialogBox;
