import { Snackbar, Theme } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import * as React from 'react';
import { ICustomSnackBarProps } from '../models/shared.models';

const defaultProps: ICustomSnackBarProps = {
    open: false,
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
    },
    autoHideDuration: 8000,
    onClose: () => {},
    disableWindowBlurListener: false,
};

const variantIcon = {
    error: ErrorIcon,
    info: InfoIcon,
    success: CheckCircleIcon,
};

const snackBarStyle = (theme: Theme) => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        marginRight: theme.spacing(),
        opacity: 0.9,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    message: {
        alignItems: 'center',
        display: 'flex',
    },
    success: {
        backgroundColor: green[600],
    },
});

const CustomSnackBar = (props: ICustomSnackBarProps) => {
    const {
        classes = {},
        defaultCloseAction = true,
        action = [],
        variant = 'success',
        onIconClick,
        children,
        snackBarMessage = '',
        ...restSnackBarProps
    } = props;
    const Icon = variant ? (variantIcon[variant] as React.ElementType) : variantIcon['success'];

    const message = children || (
        <span className={classNames(classes?.message)}>
            <Icon className={classNames(classes?.icon, classes?.iconVariant)} />
            <React.Fragment>
                {snackBarMessage} <br />
            </React.Fragment>
        </span>
    );
    action.push(
        ...(defaultCloseAction
            ? [
                  <IconButton key='close' aria-label='Close' color='inherit' onClick={onIconClick}>
                      <CloseIcon />
                  </IconButton>,
              ]
            : [])
    );

    return (
        <Snackbar
            ContentProps={{
                className: classNames(classes[variant]),
                message,
                action,
            }}
            {...restSnackBarProps}
        />
    );
};

CustomSnackBar.defaultProps = defaultProps;

export default withStyles(snackBarStyle)(CustomSnackBar);
